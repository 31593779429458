<style>
    a {
        color: #000000;
    }
    .sign{
        color: white;
    }
    .menu2{
        width: 50px;
        height: 50px;
    }
    .nav-link{
        font-size: 14px;
        font-weight: 400;
    }
</style>
<div class="px-2 px-lg-5 py-2 shadow-sm bottom-shadow sticky-top bg-white z-index-50">
    <div class="d-flex justify-content-between align-items-center">
        <a href="https://yebokenny.com" class="d-flex" style="width: 180px;">
            <img src="assets/img/fulllogo.svg" alt="Yebokenny Logo" />
        </a>


        <div class="d-none d-lg-flex">
            <ul class="nav" style="color: #000000;">
                <li class="nav-item"><a href="https://yebokenny.com" class="nav-link">Home</a></li>
                <li class="nav-item"><a href="https://yebokenny.com/about" class="nav-link">About Us</a></li>
                <li class="nav-item"><a href="https://yebokenny.com/pricing" class="nav-link">Pricing</a></li>
                <li class="nav-item"><a href="https://yebokenny.com/contact" class="nav-link">Contact Us</a></li>
                <li class="nav-item"><a href="https://yebokenny.com/faqs" class="nav-link">FAQ</a></li>
            </ul>
        </div>


        <div class="d-lg-none">
            <img src="assets/img/menu.svg" class="menu2 cursor-pointer" (click)="toggleMenu()" />
        </div>


        <div class="d-none d-lg-flex" style="width: 180px;">
            <ul class="nav align-items-center">
                <li class="nav-item"><a href="/login" class="nav-link">Login</a></li>
                <li class="nav-item">
                    <a href="/register" class="btn btn-success rounded-pill px-4 py-2 sign">Sign Up</a>
                </li>
            </ul>
        </div>
    </div>


    <div *ngIf="isMenu" class="d-lg-none">
        <ul class="list-unstyled text-center">
            <li class="mb-1"><a href="/" class="nav-link">Home</a></li>
            <li class="mb-1"><a href="https://yebokenny.com/about" class="nav-link">About Us</a></li>
            <li class="mb-1"><a href="https://yebokenny.com/pricing" class="nav-link">Pricing</a></li>
            <li class="mb-1"><a href="https://yebokenny.com/contact" class="nav-link">Contact Us</a></li>
            <li class="mb-1"><a href="https://yebokenny.com/faqs" class="nav-link">FAQ</a></li>
            <li class="mb-1"><a href="/login" class="nav-link">Login</a></li>
            <li>
                <a href="/register" class="btn btn-success rounded-pill px-4 py-2 sign">Sign Up</a>
            </li>
        </ul>
    </div>
</div>