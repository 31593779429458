<mat-dialog-content *appPermissions="appPermissions.VIEW_RECOMMENDATION_DETAILS;showErrorCard:true">
    <div class="w-100 p-3" style="max-width: 600px;" *ngIf="recommendation$ |async as recommendation; else spinner">
        <div class="container">
            <div class="row">
                <h3>Proof of Intent</h3>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Platform:</h6>
                </div>
                <div class="col-md-6">{{recommendation.platform}}</div>
            </div>
            <div class="row">
                <div class="alert alert-primary" role="alert">
                    Platform the user was using when the survey was filled out
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Fingerprint:</h6>
                </div>
                <div class="col-md-6">{{recommendation.fingerPrint}}</div>
            </div>
            <div class="row">
                <div class="alert alert-primary" role="alert">
                    Unique fingerprint determined to verify authenticity of Survey
                </div>
            </div>
            <div class="row">
                <h3>YeboKenny Scoring</h3>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Max Score Achievable:</h6>
                </div>
                <div class="col-md-6">{{recommendation.recommendationScoreHistory.maxScoreAchievable}}</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Score Factor Used:</h6>
                </div>
                <div class="col-md-6">{{recommendation.recommendationScoreHistory.scoreFactorUsed}}</div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <h6>Score Given:</h6>
                </div>
                <div class="col-md-6">{{recommendation.recommendationScoreHistory.scoreGiven}}</div>
            </div>
            <div class="row">
                <div class="alert alert-primary" role="alert">
                    YeboKenny Scoring is based on an Algorithm dependent on the number of questions, the score given for
                    each question as well as the a score factor. The output is a max score achievable as well as the
                    score given. We increment your score each time you recieve a YeboKenny.
                </div>
            </div>
            <div class="row">
                <h3>Survey Answers</h3>
                <div class="alert alert-primary" role="alert">
                    This is a preview of the survey that was answered.
                </div>
            </div>
            <div class="row">
                <div class="survey-container contentcontainer codecontainer" *ngIf="surveyModel$ | async as model">
                    <survey [model]="model"></survey>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>

<ng-template #spinner>
    <div class="h-100 d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>