import { Component, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-home-header',
  templateUrl: './homeheader.component.html',
  styleUrls: ['./homeheader.component.css']
})
export class HomeHeaderComponent {
  isMenu: boolean = false;
  constructor(private cdRef: ChangeDetectorRef) {}

  toggleMenu() {
    this.isMenu = !this.isMenu;
    this.cdRef.detectChanges();
  }
}

