<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Contrast'] }}</p>
  <p class="item-panel">{{ (this.state?.contrast || 0.0).toFixed(2) }}</p>
</div>
<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.contrast"
    type="range"
    min="0"
    max="5"
    step="0.01"
  />
</div>

<br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Brightness'] }}</p>
  <p class="item-panel">{{ (this.state?.brightness || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.brightness"
    type="range"
    min="0"
    max="5"
    step="0.01"
  />
</div>

<br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Grayscale'] }}</p>
  <p class="item-panel">{{ (this.state?.grayscale || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.grayscale"
    type="range"
    min="0"
    max="1"
    step="0.01"
  />
</div>

<br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Saturate'] }}</p>
  <p class="item-panel">{{ (this.state?.saturate || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.saturate"
    type="range"
    min="0"
    max="10"
    step="0.01"
  />
</div>
<br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Sepia'] }}</p>
  <p class="item-panel">{{ (this.state?.sepia || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.sepia"
    type="range"
    min="0"
    max="1"
    step="0.01"
  />
</div>

<!-- <br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Invert'] }}</p>
  <p class="item-panel">{{ (this.state?.invert || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.invert"
    type="range"
    min="0"
    max="1"
    step="0.01"
  />
</div> -->

<br />
<div style="display: flex; width: 100%; justify-content: space-between">
  <p class="item-panel">{{ labels['Blur'] }}</p>
  <p class="item-panel">{{ (state?.blur || 0.0).toFixed(2) }}</p>
</div>

<div style="flex-direction: row; box-sizing: border-box; display: flex; place-content: flex-start; align-items: flex-start">
  <input
    class="input-range"
    (input)="onChange()"
    [ngStyle]="{ maxWidth: '100%', width: '100%', color: color }"
    [(ngModel)]="state.blur"
    type="range"
    min="0"
    max="20"
    step="1"
  />
</div>
