import { Component } from '@angular/core';
import { MenuService } from '@app/services/menu.service';

@Component({
  selector: 'app-logged-in-layout',
  template: `
  <app-sidebar></app-sidebar>

<div class="main-content d-flex flex-column"  [ngClass]="{'hide-sidemenu-area': menuService.isMenuHidden$ | async}">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <div class="flex-grow-1"></div>
    <app-footer></app-footer>
</div>
`
})
export class AuthenticatedLayoutComponent {
  constructor(public menuService: MenuService) { }
}
