<div class="header">
  <div
    (click)="onActivateTab(index)"
    *ngFor="let tab of this.labels; let index = index"
    class="itemlabel bottomLine"
    [ngStyle]="{ color: tab.active ? color : 'inherit', opacity: tab.active ? 1 : 0.7, fontWeight: tab.active ? 500 : 'inherit' }"
  >
    {{ tab.label }}
    <div *ngIf="tab.active" [style.backgroundColor]="this.color"></div>
  </div>
</div>

<div class="body">
  <ng-content></ng-content>
</div>
