import { Component } from '@angular/core';

@Component({
  selector: 'app-download-mobile',
  template: `
    <div class="card">
        <div class="card-body d-flex flex-column align-items-center">
            <h5 class="card-title">To add products or services, download the mobile app</h5>
            <p class="card-text">Scan the QR code below or visit the link by tapping on the QR Code</p>
            <a href="https://onelink.to/q7d6ez" target="_blank">
                <img src="assets/svg/onelink.svg" class="img-fluid qr-code" alt="QR Code">
            </a>
        </div>
    </div>
  `
})
export class DownloadAppAccessComponent {
}
