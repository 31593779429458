import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ScrollerHandlersService {
	constructor() {}

	isMobile() {
		if (navigator) {
			return !!(
				navigator.userAgent.match(/Android/i) ||
				navigator.userAgent.match(/iOS/i) ||
				navigator.userAgent.match(/iPhone/i) ||
				navigator.userAgent.match(/iPad/i) ||
				navigator.userAgent.match(/Windows Phone/i)
			);
		}
		return false;
	}

	eventPropagationHandler(event: any, initial: number, threshold: number) {
		if (initial - event.clientX >= threshold || initial - event.clientX <= -threshold) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	eventBubblingHandler(event: any) {
		if (event.stopPropagation) event.stopPropagation();
		if (event.preventDefault) event.preventDefault();
		event.cancelBubble = true;
		event.returnValue = false;
	}

	start(event: PointerEvent) {
		(event.target as Element).setPointerCapture(event.pointerId);
		return true;
	}

	end(event: PointerEvent) {
		(event.target as Element).releasePointerCapture(event.pointerId);
		return false;
	}

	isLeft(translation: number) {
		return translation <= 0;
	}
}
