<mat-dialog-content style="max-width: 1000px;">
    <table mat-table [dataSource]="feedback$ | async" class="mat-elevation-z8">

        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef> Comment </th>
          <td mat-cell *matCellDef="let element"> {{element.feedbackValue}} </td>
        </ng-container>      
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> Posted On </th>
          <td mat-cell *matCellDef="let element"> {{element.creationDate | date: 'medium'}} </td>
        </ng-container>      
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element"> 
            <div *ngIf="element.showToCustomer"><button class="btn btn-outline-success" (click)="toggleVisibility(element.id, false)">Hide From Customer</button></div>
            <div *ngIf="!element.showToCustomer"><button class="btn btn-outline-danger" (click)="toggleVisibility(element.id, true)">Show To Customer</button></div>
          </td>
        </ng-container>      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      
      
</mat-dialog-content>