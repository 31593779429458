import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { SupportStateService } from '../support.state';
import { SupportTicketDto } from '@app/models/dtos/support-ticket.dto';
import { CreateSupportCommentRequest } from '@app/models/requests/create-support-comment.request';

import Swal from 'sweetalert2';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-view-ticket',
  templateUrl: './view-ticket.component.html',
  styleUrls: ['./view-ticket.component.scss']
})
export class ViewTicketComponent implements OnInit {
  id: string;

  ticket$: Observable<SupportTicketDto> = this._state.ticket$;
  form: UntypedFormGroup;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link',]                         // link and image, video
    ]
  };

  constructor(
    private _state: SupportStateService,
    private _router: Router,
    private _formBuilder: UntypedFormBuilder,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe((res) => {
      this.id = res['id'].toLowerCase().replace(' ', '').trim();
      this.refresh();
    });

    this._buildForm();
  }

  _buildForm() {
    this.form = this._formBuilder.group({
      issue: [null, Validators.required, vulgarLanguageValidator()],
    });
  }

  private refresh() {
    this._state.supportTicket(this.id);
  }


  submit() {
    if (this.form.invalid) {
      this._state.alertUI('Form is invalid', 'warning');
    } else {
      var request = <CreateSupportCommentRequest>{
        supportTicketId: this.id,
        description: this.form.get('issue').value,
        isCustomerResponse: true,
      };
      this._state.createComment(request, () => {
        this.refresh();
      });
    }
  }

  closeTicket() {
    Swal.fire({
      title: 'Are you sure you would like to close and resolve thi ticket',
      text: 'This cannot be undone',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4f46e5',
      cancelButtonColor: '#dc2626',
      confirmButtonText: 'Yes Close'
    }).then((result) => {
      if (result.isConfirmed) {
        this._state.closeTicket(this.id, () => {
          this._router.navigateByUrl('/support');
        });
      }
    })
  }
}
