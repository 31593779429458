import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';

import { RootStateService } from '../root.state';
import { ChangePasswordRequest, UpdateUserRequest, UserDto } from '@app/models';
import { passwordMatchValidator, passwordValidator } from '@app/components/shared/validators/password-validator';
import { GOOGLE } from '@app/models/constants';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';
import { numbersOnlyValidator } from '@app/components/shared/validators/numbers-only-validator';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  $user: Observable<UserDto> = this._rootstate.user$;
  form: UntypedFormGroup | null;
  passwordForm: UntypedFormGroup;
  providerGoogle = GOOGLE;
  constructor(
    private _rootstate: RootStateService,
    private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this._rootstate.me();
    this._buildForm();
  }

  private _buildForm() {
    this.form = this._formBuilder.group({
      firstName: ['', [Validators.required, vulgarLanguageValidator()]],
      lastName: ['', [Validators.required, vulgarLanguageValidator()]],
      phoneNumber: ['', [Validators.required, numbersOnlyValidator(), Validators.minLength(10), vulgarLanguageValidator(),]],
      userType: ['', [Validators.required]],
      allowEmailNotifications: [null, [Validators.required,]],
    });
    this.passwordForm = this._formBuilder.group({
      password: ['', [Validators.required, vulgarLanguageValidator()]],
      newPassword: ['', [Validators.required, passwordValidator(), vulgarLanguageValidator()]],
      confirmPassword: ['', [Validators.required, vulgarLanguageValidator(), passwordValidator()]],
    });

    this.passwordForm.addValidators(passwordMatchValidator());

    this.$user.subscribe(user => {
      this.form.controls.firstName.setValue(user.firstName);
      this.form.controls.lastName.setValue(user.lastName);
      this.form.controls.phoneNumber.setValue(user.phoneNumber);
      this.form.controls.userType.setValue(user.userType);
      this.form.controls.allowEmailNotifications.setValue(user.allowEmailNotifications);
    });
  }

  async getUserDto(): Promise<UserDto> {
    const userObservable: Observable<UserDto> = this._rootstate.user$;
    const userDto: UserDto = await lastValueFrom(userObservable);
    return userDto;
  }

  submitPassword() {
    if (this.passwordForm.invalid) {
      this._rootstate.alertUI('Form is invalid', 'warning');
      this.passwordForm.markAllAsTouched();
      return;
    } else {
      const request = <ChangePasswordRequest>{
        currentPassword: this.passwordForm.controls.password.value,
        newPassword: this.passwordForm.controls.newPassword.value,
      };
      this._rootstate.changePassword(request);
    }
  }
  updateProfile() {
    if (this.form.invalid) {
      this._rootstate.alertUI('Form is invalid', 'warning');
      this.form.markAllAsTouched();
      return;
    } else {
      const request = <UpdateUserRequest>{
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        phoneNumber: this.form.controls.phoneNumber.value,
        userType: parseInt(this.form.controls.userType.value),
        allowEmailNotifications: this.form.controls.allowEmailNotifications.value,
      };
      this._rootstate.updateUser(request);
    }
  }

}
