import { ConfirmationResponse, LoginResponse, RegisterRequest, TokenDto } from '@app/models';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';

import { AuthUtils } from '@app/helpers/auth.utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    /**
     * Setter & getter for refresh token
     */
    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<ConfirmationResponse> {
        return this._httpClient.post<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/ForgotPassword', {
            email: email
        });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<TokenDto> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(Keys.API_URL + 'api/Authentication/Login', credentials, {
            headers: {
                'Login-Source': '90238',
            }
        }).pipe(
            switchMap((response: LoginResponse) => {

                // Store the access token in the local storage
                this.accessToken = response.data.accessToken;
                this.refreshToken = response.data.refreshToken;
                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.get().subscribe(user => {
                    this._userService.user = user.data;
                })


                // Return a new observable with the response
                return of(response.data);
            })
        );
    }

    signInGoogle(credentials: { tokenId: string }): Observable<LoginResponse> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(Keys.API_URL + 'api/Authentication/Google', credentials).pipe(
            switchMap((response: LoginResponse) => {

                // Store the access token in the local storage
                this.accessToken = response.data.accessToken;
                this.refreshToken = response.data.refreshToken;

                // Store the user on the user service
                this._userService.get().subscribe(user => {
                    this._userService.user = user.data;
                    // Set the authenticated flag to true
                    this._authenticated = true;
                });
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Sign in using the token
        return this._httpClient.post(Keys.API_URL + 'api/Authentication/RefreshToken', {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: LoginResponse) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.data) {
                    this.accessToken = response.data.accessToken;
                    this.refreshToken = response.data.refreshToken;
                }

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.get().subscribe(user => {
                    this._userService.user = user.data;
                })

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: RegisterRequest): Observable<ConfirmationResponse> {
        return this._httpClient.post<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/Register', user);
    }

    /**
     * confirm Email Address
     *
     * @param user
     */
    confirmEmail(token: String, email: String): Observable<ConfirmationResponse> {
        return this._httpClient.get<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/' + token + '/' + email);
    }

    /**
     * confirm Email Address
     *
     * @param user
     */
    confirmForgotPassword(token: String, email: String, newPassword: string): Observable<ConfirmationResponse> {
        return this._httpClient.post<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/ConfirmPassword', {
            token: token,
            email: email,
            newPassword: newPassword,
        });
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    hasPermission(requiredPermission: string): Observable<boolean> {
        const accessToken = this.accessToken;
        if (!accessToken) {
            return of(false); // No access token available, user is not authenticated
        }

        const decodedToken = this.decodeAccessToken(accessToken);
        if (!decodedToken || !decodedToken[requiredPermission]) {
            return of(false); // Access token doesn't contain permissions
        }

        // Check if the user has the single required permission
        return of(decodedToken[requiredPermission].includes(requiredPermission));
    }

    private decodeAccessToken(accessToken: string): any {
        try {
            const base64Url = accessToken.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const tokenData = JSON.parse(window.atob(base64));
            return tokenData;
        } catch (error) {
            console.error('Error decoding access token:', error);
            return null;
        }
    }
}