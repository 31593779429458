import { AuthService, UserService } from '@app/services';
import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { ApplicationPermissions } from '@app/models/constants/permissions';
import { BusinessDetailsDto } from '@app/models';
import { MenuService } from '@app/services/menu.service';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
	appPermissions = ApplicationPermissions;
	businesses$: Observable<BusinessDetailsDto[]>;

	constructor(private _userService: UserService,
		private _authService: AuthService,
		private _router: Router,
		private _socialAuthService: SocialAuthService,
		public menuService: MenuService) { }

	ngOnInit(): void {
		this.businesses$ = this._userService.details()
			.pipe(map(response => response.data));
	}

	signOut() {
		this._socialAuthService.signOut();
		this._authService.signOut().subscribe(x => {
			this._router.navigate(['login']);
		});
	}

	toggleMenu() {
		this.menuService.toggleMenu();
	}

	toggleResponsiveMenu() {
		this.menuService.toggleResponsiveMenu();
	}
}