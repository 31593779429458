<div *ngIf="analytics$ | async as analytics">
    <div *ngIf="user$ |async as user">
        <div class="welcome-area">
            <div class="row m-0 align-items-center">
                <div class="col-lg-5 col-md-12 p-0">
                    <div class="welcome-content">
                        <h1 class="mb-2" *ngIf="user">Hi, Welcome {{user.fullName}}</h1>
                        <p class="mb-0">See what's happening on YeboKenny</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12 p-0">
                    <div class="welcome-img">
                        <img src="assets/img/welcome-img.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="businesses$ | async as businesses">
            <div class="col-lg-12 col-sm-12 col-md-12" *ngIf="businesses.length === 0">
                <div class="alert alert-primary">
                    <p class="fs-5">You currently have no listings. Listings are how you can show your business to the
                        world and start working with YeboKenny.
                        <a class="alert-link" [routerLink]="'/create-listing'">Create a listing</a>
                        <i class="bx bx-right-arrow-alt"></i>
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="stats-card-box">
                    <div class="icon-box">
                        <i class='bx bx-map-alt'></i>
                    </div>
                    <span class="sub-title">Active Listings</span>
                    <h3>{{analytics.numberActiveListings}}</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="stats-card-box">
                    <div class="icon-box">
                        <i class='bx bx-bar-chart-square'></i>
                    </div>
                    <span class="sub-title">Listing Views</span>
                    <h3>{{analytics.listingViews}}</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="stats-card-box">
                    <div class="icon-box">
                        <i class='bx bx-heart'></i>
                    </div>
                    <span class="sub-title">Total YeboKenny Score</span>
                    <h3>{{analytics.yeboKennyScoreTotal}}</h3>
                </div>
            </div>
        </div>

        <div class="card mb-30">
            <div class="card-body">
                <app-web-analytics [dto]="analytics"></app-web-analytics>
            </div>
        </div>

        <div class="card recent-orders-box mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Recent Views</h3>
            </div>

            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Business</th>
                                <th>Date Viewed</th>
                                <th>View</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let a of analytics.pageTracks | slice:0:10; let i = index;">
                                <td>{{i+1}}</td>
                                <td>{{a.businessName}}</td>
                                <td>{{a.viewed | date: 'medium'}}</td>
                                <td><button type="button" (click)="viewListing(a.businessProfileId)">View
                                        Business</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!--         
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Recent Activities</h3>
        
                <div class="dropdown">
                    <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class='bx bx-dots-horizontal-rounded'></i>
                    </button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class='bx bx-show'></i> View
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class='bx bx-edit-alt'></i> Edit
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class='bx bx-trash'></i> Delete
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class='bx bx-printer'></i> Print
                        </a>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class='bx bx-download'></i> Download
                        </a>
                    </div>
                </div>
            </div>
        
            <div class="card-body widget-todo-list">
                <ul>
                    <li>
                        <div class="icon">
                            <i class='bx bx-map-alt'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3>Your listing <a routerLink="/">Andro Smith</a> has been approved!</h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 28 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
        
                    <li>
                        <div class="icon">
                            <i class='bx bx-heart'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3>Someone bookmarked your <a routerLink="/">Louise Home</a> listing!</h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 27 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
        
                    <li>
                        <div class="icon">
                            <i class='bx bx-map-alt'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3>Your listing <a routerLink="/">Louise Home</a> has been approved!</h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 16 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
        
                    <li>
                        <div class="icon">
                            <i class='bx bx-heart'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3>Someone bookmarked your <a routerLink="/">Louise Moonlight</a> listing!</h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 24 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
        
                    <li>
                        <div class="icon">
                            <i class='bx bx-star'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3><a routerLink="/">Alina</a> left a review 5 Star on <a routerLink="/">Louise Home</a></h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 10 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
        
                    <li>
                        <div class="icon">
                            <i class='bx bx-check-double'></i>
                        </div>
        
                        <div class="todo-item-title">
                            <h3>Your listing <a routerLink="/">James Anderson</a> has been approved!</h3>
                        </div>
        
                        <div class="todo-item-action">
                            <p><i class='bx bx-calendar'></i> 09 April 2020</p>
                            <a routerLink="/" class="edit"><i class='bx bx-edit-alt'></i></a>
                            <a routerLink="/" class="delete"><i class='bx bx-trash'></i></a>
                        </div>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
</div>