<div class="container p-5">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-title fs-4"> Author Information</div>
                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-md-12 form-floating">                                
                                <div class="form-text">A photo of yourself</div>
                                <image-picker [_config]="imagePickerConf" ($imageChanged)="onProfileChanged($event)"></image-picker>
                            </div>                      
                        </div>
                        <div class="btn-group" role="group">
                            <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                        </div>
                    </form>              
                </div>
            </div>
        </div>
    </div>
</div>