import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertType } from '@app/components/shared/alert/alert.types';
import { AuthService } from '@app/services';
import { GlobalStateService } from '@app/global.state';
import { RootStateService } from '../root.state';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { fuseAnimations } from '@app/components/shared/animations';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;
  signInForm: UntypedFormGroup;
  alert: { type: AlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _state: GlobalStateService,
    private _authService: AuthService,
    private _rootState: RootStateService,
  ) { }

  ngOnInit(): void {
    // Create the form
    this.signInForm = this._formBuilder.group({
      email: [, [Validators.required, Validators.email, vulgarLanguageValidator()]],
    });
  }

  ngAfterViewInit(): void {
    this._authService.check().subscribe(res => {
      if (res) {
        Swal.fire({
          title: 'Hmmm',
          text: 'You are already logged in. Click Ok to continue to your dashboard',
          icon: 'info',
        }).then(val => {
          setTimeout(() => {
            const redirectUrl = '/dashboard';
            // Navigate to the redirect url
            this._router.navigateByUrl(redirectUrl);
          }, 500);
        })

      }
    })
  }

  /**
  * Sign in
  */
  submit(): void {
    // Return if the form is invalid
    if (this.signInForm.invalid) {
      this._state.alertUI('form is invalid', 'warning');
			this.signInForm.markAllAsTouched();
			return;
      return;
    }
    // Disable the form
    this.signInForm.disable();
    this._authService.forgotPassword(this.signInForm.controls.email.value)
      .subscribe(
        (res) => {
          if (res.data) {
            this._rootState.alertUI(res.data.messsage, 'success');
            this.signInForm.enable();
          } else {
            this._rootState.alertUI(res.message, 'error');

            // Re-enable the form
            this.signInForm.enable();

            // Reset the form
            this.signInNgForm.resetForm();

            // Set the alert
            this.alert = {
              type: 'error',
              message: 'Wrong username/email'
            };

            // Show the alert
            this.showAlert = true;
          }
        }
      );
  }


}
