const API_URL = 'https://api.yebokenny.com/';
const STRAPI_URL = 'https://cms.yebokenny.com/api';
const CHECK_URL = 'api.yebokenny.com';

// const API_URL = 'https://api.developer.yebokenny.com/';
// const STRAPI_URL = 'https://cms.developer.yebokenny.com/api';
// const CHECK_URL = 'api.developer.yebokenny.com';

// const CHECK_URL = 'localhost:7125';
// const API_URL = 'https://localhost:7125/';
// const STRAPI_URL = 'http://cms.paxxio.co.za';

// const CHECK_URL = 'cms.paxxio.co.za';
// const API_URL = 'https://dce5-105-224-193-174.ngrok-free.app/';
// const STRAPI_URL = 'http://cms.paxxio.co.za';

export const Keys = {
    API_URL,
    STRAPI_URL,
    CHECK_URL
}