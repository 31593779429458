<div class="container p-3" *ngIf="transaction$ |async as transaction">
    <div class="row">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">Transactions</li>
              <li class="breadcrumb-item active" aria-current="page">{{transaction.id}}</li>
            </ol>
          </nav>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="fs-5">Amount <span class="fw-bold">ZAR {{transaction.amount / 100}}</span></div>
            <div class="table-responsive">
                <table class="table table-bordered">                
                    <tbody>
                        <tr>
                            <td class="fw-bold">Reference</td>
                            <td>{{transaction.reference}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Channel</td>
                            <td>{{transaction.channel}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Paid At</td>
                            <td>{{transaction.paidAt | date:'medium'}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Status</td>
                            <td>{{transaction.status | titlecase}}</td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>        
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div class="fs-5">Payment</div>
            <div class="table-responsive">
                <table class="table table-bordered">                
                    <tbody>
                        <tr>
                            <td class="fw-bold">Card Type</td>
                            <td>{{transaction.authorization.cardType}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Card Number</td>
                            <td>*** **** **** {{transaction.authorization.last4}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Authorization Code</td>
                            <td>{{transaction.authorization.authorizationCode}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold">Bank and Country</td>
                            <td>{{transaction.authorization.bank | uppercase}} ({{transaction.authorization.countryCode | uppercase}})</td>
                        </tr> 
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12">
            <div class="fs-5">Log</div>
            <div class="table-responsive">
                <table class="table table-bordered">                
                    <tbody>
                        <tr>
                            <td class="fw-bold">Time Spent on Page</td>
                            <td>{{transaction.log.timeSpent}} Seconds</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold text-primary"># Payment Attempts</td>
                            <td>{{transaction.log.attempts}}</td>
                        </tr> 
                        <tr>
                            <td class="fw-bold text-danger"># Errors</td>
                            <td>{{transaction.log.errors}}</td>
                        </tr>                        
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>