import { CategoryDetailsResponse, CategoryListResponse } from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Category';

  getTopLevelCategories(): Observable<CategoryListResponse> {
    return this.http.get<CategoryListResponse>(Keys.API_URL + 'api/Category/GetTopLevel');
  }

  getSubCategories(categoryId: string): Observable<CategoryListResponse> {
    return this.http.get<CategoryListResponse>(this.rootURL + '/Get/' + categoryId);
  }

  details(categoryId: string): Observable<CategoryDetailsResponse> {
    return this.http.get<CategoryDetailsResponse>(this.rootURL + '/Details/' + categoryId);
  }
}