export const createListingSurvey = `
{
 "title": "Create a Business Listing",
 "description": "Don't worry about long forms, answer a few short questions and let us compile your listing for you",
 "logoPosition": "right",
 "completedHtml": "<h3>One Moment while we compile your listing. You will be redirected shortly</h3>",
 "pages": [
  {
   "name": "page1",
   "elements": [
    {
     "type": "text",
     "name": "question1",
     "title": "What is your business / listing name?",
     "isRequired": true
    },
    {
     "type": "text",
     "name": "question4",
     "title": "What is your business / listing email address?",
     "isRequired": true,
     "inputType": "email"
    },
    {
     "type": "text",
     "name": "question5",
     "title": "Please provide us with a phone number",
     "isRequired": true,
     "validators": [
      {
       "type": "regex",
       "text": "Please enter a valid South African cell phone number.",
       "regex": "^(\\\\+27|0)[6-8][0-9]{8}$"
      }
     ],
     "inputType": "tel"
    }
   ],
   "title": "Some Core Questions",
   "description": "First things first"
  },
  {
   "name": "page2",
   "elements": [
    {
     "type": "text",
     "name": "question2",
     "title": "What is your business website?",
     "inputType": "url"
    }
   ],
   "title": "Website?",
   "description": "If you don't have one, leave it blank"
  },
  {
   "name": "page3",
   "elements": [
    {
     "type": "text",
     "name": "question3",
     "title": "What is your physical address"
    }
   ],
   "title": "Physical Address",
   "description": "We know you're on earth, so lets keep the address real."
  },
  {
   "name": "page4",
   "elements": [
    {
     "type": "comment",
     "name": "question6",
     "title": "Tell us about your business",
     "isRequired": true
    }
   ],
   "title": "Tell us about what makes you, you.",
   "description": "Write up something amazing, inspirating and most of all showing the best about what your business/listing is all about"
  },
  {
   "name": "page6",
   "elements": [
    {
     "type": "file",
     "name": "question8",
     "title": "Provide us with a header image for your business",
     "isRequired": true,
     "acceptedTypes": "image/*",
     "sourceType": "file-camera"
    }
   ],
   "title": "A Profile Image",
   "description": "Provide us with a main image and background image. Think of it as LinkedIn, with a bit of flare. The main image is what everyone sees, the header image, well thats to make things pretty."
  },
  {
   "name": "page7",
   "elements": [
    {
     "type": "file",
     "name": "question7",
     "title": "Provide us with a header image for your business",
     "isRequired": true,
     "acceptedTypes": "image/*",
     "sourceType": "file-camera"
    }
   ],
   "title": "A Background Image",
   "description": "Provide us with a main image and background image. Think of it as LinkedIn, with a bit of flare. The main image is what everyone sees, the header image, well thats to make things pretty."
  }
 ]
}
`;


export interface CreateListingResult {
    question1: string;
    question2: string | null;
    question4: string;
    question5: string;
    question3: string;
    question6: string;
    question8: SurveyImageResult[];
    question7: SurveyImageResult[];
}

export interface SurveyImageResult {
    name: string;
    type: string;
    content: string;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toCreateListingResult(json: string): CreateListingResult {
        return JSON.parse(json);
    }

    public static createListingResultToJson(value: CreateListingResult): string {
        return JSON.stringify(value);
    }
}