import {
	AfterViewInit,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	QueryList,
	Renderer2,
	ViewChild,
} from '@angular/core';

import { ScrollerHandlersService } from './scroller-handlers.service';
import { SnapScrollerSlideComponent } from './snap-scroller-slide/snap-scroller-slide.component';

@Component({
	selector: 'snap-scroller',
	templateUrl: './snap-scroller.component.html',
	styleUrls: ['./snap-scroller.component.scss'],
	providers: [ScrollerHandlersService],
})
export class SnapScrollerComponent implements AfterViewInit {
	@Input() __indicatorPositions: (number | null)[] = [];
	@Input() __onLoadDelay = 1000;

	objectDetails = {
		startX: 0,
		endX: 0,
	};

	direction = 0;
	translatedAmount = 0;
	previousBreakpoint = 0;
	currentBreakpoint = 0;
	opacityIntervalCount = 0;
	widthOfItemsInView = 0;
	numberOfBreakPoints = 0;
	percentageTranslated = 0;

	opacityInterval: any;
	transitionTimeout: any;
	autoPlayInterval: any;
	adjustContent: any;
	thresholds: any = [];
	thresholdPercentages: any = [];

	isGpuUtilized = false;
	isTransitionEnabled = false;
	hasChanged = false;
	pending = false;
	isEnd = false;

	@Input() buttonColor = 'raven';
	@Input() indicatorColor = 'raven';

	@Input() showIndicator = true;
	@Input() showOverflow = false;
	@Input() showFade = false;
	@Input() showButtons = false;
	@Input() groupItems = false;
	@Input() absoluteIndicatorPosition = false;

	@Input() autoPlayInMs = 0;
	@Input() buttonPosition = 40;
	@Input() indicatorPosition = 24;
	@Input() itemSpacing = 16;
	@Input() indicatorWidth = 11.25;
	@Input() indicatorActiveWidth = 3.125;

	@Output() emitTranslation: EventEmitter<any> = new EventEmitter();
	@Output() emitEvent: EventEmitter<any> = new EventEmitter();

	@ViewChild('mainContainer', { static: true }) mainContainer?: ElementRef;
	@ViewChild('childContainer', { static: true }) childContainer?: ElementRef;
	@ViewChild('contentContainer', { static: true }) contentContainer?: ElementRef;

	@ContentChildren(SnapScrollerSlideComponent, { read: ElementRef }) contentChildren?: QueryList<ElementRef>;

	constructor(private scrollerHandlersService: ScrollerHandlersService, private elementRef: ElementRef, private renderer: Renderer2) {}

	get sectionWidth() {
		return this.contentChildren?.get(this.currentBreakpoint)?.nativeElement.getBoundingClientRect().width;
	}

	get numberOfItems() {
		return this.contentChildren?.length ?? 0;
	}

	get contentWidth() {
		return this.contentChildren
			?.toArray()
			.map((child: ElementRef) => child.nativeElement.getBoundingClientRect().width + this.itemSpacing)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
	}

	get maxDrag() {
		return this.contentWidth - this.childContainer?.nativeElement.getBoundingClientRect().width;
	}

	get isContentOverflowed() {
		return this.childContainer?.nativeElement.getBoundingClientRect().width < this.contentWidth;
	}

	get numberOfItemsInView() {
		let count = 0;
		let totalWidth = 0;
		for (let i = this.currentBreakpoint; i < this.numberOfItems + 1; i++) {
			if (
				this.contentChildren?.get(i)?.nativeElement.getBoundingClientRect().width + totalWidth <=
				this.childContainer?.nativeElement.getBoundingClientRect().width
			) {
				totalWidth += this.contentChildren?.get(i)?.nativeElement.getBoundingClientRect().width;
				count++;
			} else {
				this.widthOfItemsInView = totalWidth;
				return count;
			}
		}
		return 0;
	}

	get slidesInView() {
		let slidesFound = [];
		for (let i = 0; i < this.numberOfItemsInView; i++) {
			slidesFound.push({ index: i + this.currentBreakpoint });
		}
		return slidesFound;
	}

	ngAfterViewInit() {
		this.resize();
		setTimeout(() => {
			this.resize();
			this.emitScrollerEvent();
		}, this.__onLoadDelay);
		if (this.showFade) {
			this.setOpacityOnOverflow();
		}
	}

	initialize = () => {
		this.setThresholds();
		this.setAutoPlay();
	};

	@HostListener('window:resize')
	resize() {
		this.enableTransition(false);
		clearTimeout(this.adjustContent);
		this.adjustContent = setTimeout(() => {
			this.numberOfItemsInView;
			if (this.thresholds !== 0) {
				this.thresholds = [];
			}

			this.initialize();

			if (this.isEnd) {
				this.currentBreakpoint = this.numberOfBreakPoints;
			} else if (this.currentBreakpoint >= this.thresholds.length) {
				this.currentBreakpoint = this.numberOfBreakPoints;
			}

			const originalPosition = this.thresholds[this.currentBreakpoint].translation;

			if (this.isContentOverflowed) {
				this.objectDetails.endX = originalPosition;
				if (this.contentContainer) {
					this.renderer.setStyle(this.contentContainer?.nativeElement, 'translate', originalPosition + 'px');
				}
			} else {
				this.objectDetails.endX = 0;
				if (this.contentContainer) {
					this.renderer.setStyle(this.contentContainer.nativeElement, 'translate', '0px');
				}
			}
			if (this.showFade) {
				this.setOpacityOnOverflow();
			}
			this.translateIndicator();
			this.enableTransition(true);
		}, 1);
	}

	setThresholds() {
		let translateTotal = 0;
		let outOfBounds = false;

		for (let i = 0; i < this.numberOfItems; i++) {
			if (translateTotal > -this.maxDrag) {
				this.thresholds[i] = { breakpoint: i, translation: translateTotal };
				translateTotal -= !this.groupItems
					? this.contentChildren?.get(i)?.nativeElement.getBoundingClientRect().width + this.itemSpacing
					: this.widthOfItemsInView + this.itemSpacing * this.numberOfItemsInView;
			} else {
				if (!outOfBounds) {
					this.thresholds[i] = { breakpoint: i, translation: -this.maxDrag };
					outOfBounds = true;
				}
			}
		}
		this.numberOfBreakPoints = this.thresholds.length - 1;
		this.thresholdPercentages = this.percentageIntervals(this.numberOfBreakPoints + 1);
	}

	percentageIntervals(numBreakpoints: number) {
		const percentages = [];
		const intervalSize = 100 / (numBreakpoints - 1);

		for (let i = 0; i < numBreakpoints; i++) {
			percentages.push(i * intervalSize);
		}

		return percentages;
	}

	setAutoPlay() {
		clearInterval(this.autoPlayInterval);
		if (this.autoPlayInMs !== 0 && this.isContentOverflowed) {
			this.autoPlayInterval = setInterval(() => {
				if (this.currentBreakpoint >= this.numberOfBreakPoints) {
					this.currentBreakpoint = 0;
				} else {
					this.currentBreakpoint++;
				}

				this.renderer.setStyle(
					this.contentContainer?.nativeElement,
					'translate',
					this.thresholds[this.currentBreakpoint].translation + 'px'
				);
				this.translateIndicator();

				this.objectDetails.endX = this.thresholds[this.currentBreakpoint].translation;
				this.emitScrollerEvent();
				this.emitScrollerDetails();
			}, this.autoPlayInMs);
		}
	}

	eventPropagationHandler(event: any) {
		this.scrollerHandlersService.eventPropagationHandler(event, this.objectDetails.startX, 25);
	}

	startDrag(event: PointerEvent) {
		if (event.target) {
			this.isGpuUtilized = true;
			this.scrollerHandlersService.eventBubblingHandler(event);
			this.pending = this.scrollerHandlersService.start(event);
			this.objectDetails.startX = event.clientX;
			clearInterval(this.autoPlayInterval);
			this.enableTransition(false);
		}
	}

	dragging(event: PointerEvent) {
		if (!this.pending) {
			return;
		}

		requestAnimationFrame(() => {
			if (this.pending && this.isContentOverflowed) {
				if (this.contentContainer && this.isContentOverflowed) {
					this.renderer.setStyle(this.contentContainer.nativeElement, 'cursor', 'grabbing');
				}
				this.direction = this.objectDetails.startX - event.clientX;
				this.translate();
			}
		});

		this.hasChanged = true;
	}

	translate() {
		const difference = this.objectDetails.endX - this.direction;
		const percentageTranslated = (difference / this.maxDrag) * 100;

		if (this.contentContainer) {
			this.renderer.setStyle(this.contentContainer.nativeElement, 'translate', difference + 'px');
		}

		if (percentageTranslated < 0) {
			this.percentageTranslated = percentageTranslated;
		} else {
			this.percentageTranslated = 0;
		}

		if (this.showFade) {
			this.setOpacityOnOverflow();
		}
		this.emitScrollerDetails();
	}

	translateIndicator() {
		const currentPosition = -this.thresholds[this.currentBreakpoint].translation;
		this.percentageTranslated = (currentPosition / this.maxDrag) * 100;
	}

	endDrag(event: PointerEvent) {
		if (event.target) {
			this.pending = this.scrollerHandlersService.end(event);
			this.eventEnd();
			this.setAutoPlay();
		}
	}

	cancelDrag(event: PointerEvent) {
		this.endDrag(event);
	}

	eventEnd() {
		const direction = Math.abs(this.direction);

		if (this.hasChanged && this.isContentOverflowed) {
			if (direction > (!this.groupItems ? this.sectionWidth : this.widthOfItemsInView)) {
				for (let i = 0; i < direction / (!this.groupItems ? this.sectionWidth : this.widthOfItemsInView); i++) {
					this.checkDirection();
				}
			} else if (Math.abs(this.direction) > 25) {
				this.checkDirection();
			}

			for (let i = 0; i < this.thresholds.length; i++) {
				if (this.thresholds[i].breakpoint === this.currentBreakpoint) {
					this.translatedAmount = this.thresholds[i].translation;
				}
			}

			this.setEndX();

			this.direction = 0;
			this.hasChanged = false;
			this.isGpuUtilized = false;

			this.enableTransition(true);

			this.translateIndicator();
			this.emitScrollerDetails();

			if (this.previousBreakpoint !== this.currentBreakpoint) {
				this.emitScrollerEvent();
				this.previousBreakpoint = this.currentBreakpoint;
			}

			if (this.showFade) {
				this.setOpacityInterval();
			}
		}
	}

	checkDirection() {
		if (this.currentBreakpoint <= this.thresholds.length) {
			if (this.scrollerHandlersService.isLeft(this.direction) && this.currentBreakpoint > 0) {
				this.currentBreakpoint -= 1;
			}

			if (!this.scrollerHandlersService.isLeft(this.direction) && this.currentBreakpoint < this.thresholds.length - 1) {
				this.currentBreakpoint += 1;
			}
		}
	}

	setEndX() {
		if (this.currentBreakpoint >= 0 && this.currentBreakpoint <= this.numberOfBreakPoints) {
			this.objectDetails.endX = this.thresholds[this.currentBreakpoint].translation;
			this.isEnd = false;
		} else {
			this.objectDetails.endX = this.thresholds[this.numberOfBreakPoints].translation;
			this.currentBreakpoint = this.numberOfBreakPoints;
			this.isEnd = true;
		}

		requestAnimationFrame(() => {
			if (this.contentContainer) {
				this.renderer.setStyle(this.contentContainer.nativeElement, 'translate', this.objectDetails.endX + 'px');
			}

			if (this.contentContainer && this.isContentOverflowed) {
				this.renderer.setStyle(this.contentContainer.nativeElement, 'cursor', 'grab');
			}
		});
	}

	// Custom detection because intersection observer doesn't work with overflow visible.
	setOpacityOnOverflow() {
		const parent = this.childContainer?.nativeElement.getBoundingClientRect();

		for (let i = 0; i < this.numberOfItems; i++) {
			const childElement = this.contentChildren?.get(i)?.nativeElement;
			const childElementWidth = this.contentChildren?.get(i)?.nativeElement.getBoundingClientRect().width;
			const contentRight = childElement.getBoundingClientRect().right;
			const contentLeft = childElement.getBoundingClientRect().left;

			if (contentRight >= parent.left && contentLeft <= parent.right) {
				if (Math.abs(contentRight - parent.left) <= childElementWidth) {
					const inViewPercentage = (contentRight - parent.left) / childElementWidth;
					this.renderOpacity(childElement, inViewPercentage);
				} else if (Math.abs(contentLeft - parent.right) <= childElementWidth) {
					const inViewPercentage = Math.abs(contentLeft - parent.right) / childElementWidth;
					this.renderOpacity(childElement, inViewPercentage);
				} else {
					this.renderOpacity(childElement, 1);
				}
			} else {
				this.renderOpacity(childElement, 0);
			}
		}
	}

	setOpacityInterval() {
		clearInterval(this.opacityInterval);
		requestAnimationFrame(() => {
			this.opacityInterval = setInterval(() => {
				this.opacityIntervalCount++;
				this.setOpacityOnOverflow();

				if (this.opacityIntervalCount === 30) {
					clearInterval(this.opacityInterval);
					this.opacityIntervalCount = 0;
				}
			}, 10);
		});
	}

	renderOpacity(elementRef: ElementRef, opacity: number) {
		this.renderer.setStyle(elementRef, 'opacity', opacity);
	}

	enableTransition(state: boolean) {
		this.isTransitionEnabled = state;
	}

	nextItem() {
		if (this.currentBreakpoint < this.numberOfBreakPoints) {
			this.setTranslate('next');
		}
	}

	previousItem() {
		if (this.currentBreakpoint > 0) {
			this.setTranslate('previous');
		}
	}

	setTranslate(direction: string) {
		if (direction === 'previous') {
			this.currentBreakpoint -= 1;
		} else {
			this.currentBreakpoint += 1;
		}

		requestAnimationFrame(() => {
			if (this.contentContainer) {
				this.renderer.setStyle(
					this.contentContainer.nativeElement,
					'translate',
					this.thresholds[this.currentBreakpoint].translation + 'px'
				);
			}
		});

		this.objectDetails.endX = this.thresholds[this.currentBreakpoint].translation;

		if (this.showFade) {
			this.setOpacityInterval();
		}

		this.translateIndicator();
		this.emitScrollerEvent();
	}

	emitScrollerEvent() {
		this.emitEvent.emit({
			slidesInView: this.slidesInView,
		});
	}

	emitScrollerDetails() {
		this.emitTranslation.emit({
			percentageTranslated: this.percentageTranslated,
			currentBreakpoint: this.currentBreakpoint,
		});
	}

	indicatorBarHandler(event: any) {
		this.currentBreakpoint = event.breakpoint;

		if (event.snap) {
			this.emitScrollerEvent();
			this.enableTransition(true);
			setTimeout(() => {
				this.objectDetails.endX = this.thresholds[this.currentBreakpoint].translation;
				this.renderer.setStyle(
					this.contentContainer?.nativeElement,
					'translate',
					this.thresholds[this.currentBreakpoint].translation + 'px'
				);
				if (this.showFade) {
					this.setOpacityInterval();
				}
			});
		} else {
			this.enableTransition(false);
			setTimeout(() =>
				this.renderer.setStyle(
					this.contentContainer?.nativeElement,
					'translate',
					-event.percentage * ((this.contentWidth - this.widthOfItemsInView) / this.widthOfItemsInView) + '%'
				)
			);
			if (this.showFade) {
				this.setOpacityOnOverflow();
			}
		}

		this.emitScrollerDetails();
	}
}
