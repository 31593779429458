import { BusinessDetailsDto, CategoryDto, UpdateBusinessDetailsRequest } from "@app/models";
import { BusinessService, CategoryService, StateService } from "@app/services";

import { CreateBusinessRequest } from "@app/models/requests/create-business.request";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

interface CreateListingState {
    categories: CategoryDto[],
    subcategories: CategoryDto[],
    selectedCategory: CategoryDto,
}


const initialState: CreateListingState = {
    categories: [],
    subcategories: [],
    selectedCategory: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class CreateListingStateService extends StateService<CreateListingState>{
      
    categories$: Observable<CategoryDto[]> = this.select((state)=>{
        return state.categories;
    });
    
    subcategories$: Observable<CategoryDto[]> = this.select((state)=>{
        return state.subcategories;
    });
    
    
    selectedCategory$: Observable<CategoryDto> = this.select((state)=>{
        return state.selectedCategory;
    });

    constructor(private businessService: BusinessService, 
        private categoryService: CategoryService){
        super(initialState);        
    }


    
  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------
  
    
  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load(){
    this.categoryService.getTopLevelCategories().subscribe((response) =>{
        this.setState({categories: response.data});      
    })    
  }

  create(request: CreateBusinessRequest){
    this.businessService.create(request).subscribe(response =>{      
    })
  }  
}