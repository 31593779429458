import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';
import { SiteSettingsResponse } from '@app/models/responses/site-settings.response';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Settings';

  siteSettings(): Observable<SiteSettingsResponse> {
    return this.http.get<SiteSettingsResponse>(this.rootURL + '/Site');
  }
}