import { LoadingService, StateService } from "@app/services";

import { FeedbackDto } from "@app/models";
import { FeedbackService } from "@app/services/feedback.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

interface FeedbackState {
    feedback: FeedbackDto[],
}

const initialState: FeedbackState = {
    feedback: [],
}

@Injectable({
    providedIn: 'root',
  })
export class FeedbackStateService extends StateService<FeedbackState>{
      
    feedback$: Observable<FeedbackDto[]> = this.select((state)=>{        
        return state.feedback;
    });    
    
    constructor(private _feedbackService: FeedbackService,private _loadingService: LoadingService){
        super(initialState);
    }
// ------------------------------------------------------------------------------------------
// @ API CALLS
// ------------------------------------------------------------------------------------------

	feedback(businessId: string){
		this._feedbackService.get(businessId).subscribe((response) =>{
			this.setState({feedback: response.data});      
		})   
	}	

    markAsSeen(id: string){
        this._feedbackService.markAsSeen(id);
    }

    toggle(id: string, show: boolean, callback: Function){
        this._loadingService.setLoading(true);
        this._feedbackService.showToCustomer(id, show).subscribe(res => {
            this._loadingService.setLoading(false);
            if(res.data){            
                this.alertUI(res.data.messsage, 'success');
                callback();
            }else{            
                this.alertUI(res.message, 'error');
            }
        });
    }
    
}