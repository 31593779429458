<ng-container *ngIf="ticket$ | async as ticket">
    <div class="breadcrumb-area">
        <button class="btn btn-danger me-2 ms-2" (click)="closeTicket()">
            Close / Mark as Resolved
        </button>
        <h1>Ticket Number: #{{ticket.ticketNumber}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/dashboard">Dashboard</a></li>
            <li class="item"><a routerLink="/support">Support Tickets</a></li>
            <li class="item">#{{ticket.ticketNumber}}
                <span class="badge badge-success" *ngIf="ticket.supportTicketStatus === 2">Closed /
                    Resolved</span>
                <span class="badge badge-primary" *ngIf="ticket.supportTicketStatus === 1">Waiting for Customer
                    Response</span>
                <span class="badge badge-danger" *ngIf="ticket.supportTicketStatus === 0">Open</span>
            </li>
        </ol>
    </div>

    <section class="listing-area mb-30">
        <div class="container">
            <ng-container *ngFor="let item of ticket.supportTicketComments">
                <div class="custom-border m-5 p-5">
                    <div class="row">
                        <div class="col-md-2">
                            <div *ngIf="item.isCustomerResponse">Me</div>
                            <div *ngIf="!item.isCustomerResponse">YeboKenny Support Team</div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12" [innerHTML]="item.description">
                        </div>
                    </div>
                </div>

            </ng-container>
            <ng-container class="mt-5">
                <div class="container custom-border">
                    <div class="row mt-5 p-5">
                        <div class="col-md-12" *ngIf="ticket.supportTicketStatus === 2">
                            <div class="text-secondary text-center">
                                Cannot submit new reponses to a closed ticket
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="ticket.supportTicketStatus !== 2">
                            <div class="text-secondary text-center">
                                Reply to this conversation
                            </div>
                            <form [formGroup]="form">
                                <quill-editor [formControlName]="'issue'" [bounds]="'self'" [modules]="quillModules">
                                </quill-editor>
                                <mat-error *ngIf="form.get('issue').hasError('isProfane') && form.get('issue').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </form>
                        </div>
                        <div class="col-md-12" *ngIf="ticket.supportTicketStatus !== 2">
                            <button mat-button class="px-8" (click)="submit()" [color]="'primary'">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </section>
</ng-container>