<div class="container"*ngIf="authorInfo$ | async as authorInfo; else create">
    <div class="row">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <button class="btn btn-outline-info me-md-2" type="button" (click)="updateInfo(authorInfo.id)">Update Information</button>
            <button class="btn btn-outline-info" type="button" (click)="updateImage(authorInfo.id)">Update Picture</button>
        </div>
    </div>
    <div class="listing-author">
        <div class="author-profile-header"></div>
        <div class="author-profile">
            <div class="author-profile-title">
                <img [src]="authorInfo.image.originalUrl" class="shadow-sm rounded-circle" alt="image">
    
                <div class="author-profile-title-details">
                    <div class="author-profile-details">
                        <h4>{{authorInfo.displayName}}</h4>
                        <span class="d-block">{{authorInfo.jobTitle}}</span>
                        <span class="d-block">{{authorInfo.hobbies}}</span>
                    </div>
                </div>
            </div>
            <div [innerHTML]="authorInfo.description"></div>
        </div>
    </div>
</div>

<ng-template #create>
    <div class="container">
        <div class="row">
            <div class="alert alert-primary" role="alert">
                <h4 class="alert-heading">Setup your Author information</h4>
                <p>Author information is a set of information about you. The author of your listings. Let people get to know the brains behind the operation.</p>
                <hr>
                <button class="btn btn-primary" (click)="createInfo()">Create your Author Profile</button>
            </div>
        </div>
    </div>
</ng-template>