<div class="container p-5">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-title fs-4"> Author Information</div>
                <div class="card-body">
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-md-12 form-floating">
                                <div class="form-text">Your name and surname</div>
                                <input class="form-control" placeholder="my business" id="displayName" matInput
                                    [formControlName]="'displayName'" required>
                                <mat-error
                                    *ngIf="form.get('displayName').hasError('required') && form.get('displayName').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('displayName').hasError('isProfane') && form.get('displayName').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-floating mb-3">
                                <div class="form-text">A list of your qualifications, Titles etc.</div>
                                <input class="form-control" placeholder="eg. Plumber" id="jobTitle" matInput
                                    [formControlName]="'jobTitle'" required>
                                <mat-error
                                    *ngIf="form.get('jobTitle').hasError('required') && form.get('jobTitle').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('jobTitle').hasError('isProfane') && form.get('jobTitle').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-floating mb-3">
                                <div class="form-text">A list of your hobbies, passions etc. seperated by commas</div>
                                <input class="form-control" placeholder="bowling, programming, photography" id="hobbies"
                                    matInput [formControlName]="'hobbies'" required>
                                <mat-error
                                    *ngIf="form.get('hobbies').hasError('required') && form.get('hobbies').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('hobbies').hasError('isProfane') && form.get('hobbies').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 form-floating mb-3">
                                <div class="form-text">Write a bit about yourself. Tell us what makes you, you.</div>
                                <editor [apiKey]="apikey" cloudChannel="6" [disabled]="false" id="description_editor"
                                    [init]="{
                                            plugins: 'lists link help wordcount',
                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                          }" [inline]="false" [formControlName]="'description'" tagName="div">
                                </editor>
                                <mat-error
                                    *ngIf="form.get('description').hasError('required') && form.get('description').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('description').hasError('minlength') && form.get('description').touched">
                                    A minimum length of 20 characters is required for a description.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('description').hasError('isProfane') && form.get('description').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                        <div class="btn-group" role="group">
                            <button class="btn btn-outline-primary" (click)="submit()">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>