<footer class="footer-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>©YeboKenny {{currentYear}}</p>
        </div>

        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <p>❤️Positive Referencing for Business Excellence❤️</p>
        </div>
    </div>
</footer>