<div class="container" >
    <div class="row">
        <div class="col-md-12">
            <div class="card mt-3">
                <div class="card-body">
                    <h3>Total Score: 
                        <span *ngIf="totalScore$ | async as totalScore; else spinner">
                        {{totalScore}}
                        </span>                        
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="recommendations$ | async as recommendations; else spinner">
        <table mat-table [dataSource]="recommendations" class="mat-elevation-z8">
    
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="scoreGiven">
              <th mat-header-cell *matHeaderCellDef> Score Given. </th>
              <td mat-cell *matCellDef="let element"> {{element.scoreGiven}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="maxScoreAchievable">
              <th mat-header-cell *matHeaderCellDef> Max Score Possible </th>
              <td mat-cell *matCellDef="let element"> {{element.maxScoreAchievable}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="scoreFactorUsed">
              <th mat-header-cell *matHeaderCellDef> Factor </th>
              <td mat-cell *matCellDef="let element"> {{element.scoreFactorUsed}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="dateComputed">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.dateComputed | date}} </td>
            </ng-container>
          
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="signature">
              <th mat-header-cell *matHeaderCellDef> Signature </th>
              <td mat-cell *matCellDef="let element"> <img [src]="element.signature" alt="" style="height: 30px; object-fit: contain;"></td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </div>
</div>
  
  <ng-template #spinner>
    <mat-spinner></mat-spinner>
  </ng-template>