import { Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';

import { BusinessDetailsDto } from '@app/models';
import { Router } from '@angular/router';
import { UserService } from '@app/services';

@Component({
	selector: 'app-my-listing',
	templateUrl: './my-listing.component.html',
	styleUrls: ['./my-listing.component.scss']
})
export class MyListingComponent implements OnInit {
	businesses$: Observable<BusinessDetailsDto[]>;

	constructor(private _userService: UserService,
		private _router: Router,) { }

	ngOnInit(): void {
		this._fetchData();
	}


	_fetchData() {
		this.businesses$ = this._userService.details()
			.pipe(map(response => response.data));
	}

	createNewListing() {
		this._router.navigateByUrl('/create-listing');
	}

	/**
	 *   PENDING = 98374,
		ACCEPTED = 203947,
		REJECTED = 309732,
	 */
	getReviewStatus(reviewStatus: number): string {
		switch (reviewStatus) {
			case 98374:
				return '<div class="badge bg-warning">PENDING</div>';

			case 203947:
				return '<div class="badge bg-success">ACCEPTED</div>';

			case 309732:
				return '<div class="badge bg-danger">REJECTED</div>';

			default:
				return '<div class="badge bg-primary">Unknown</div>';
		}
	}
}
