import { BusinessDetailResponse, RecommendationResponse } from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';
import { RecommendationHistoryResponse } from '@app/models/responses/recommendation-history.response';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  constructor(private http: HttpClient) { }

  details(businessId: string): Observable<RecommendationHistoryResponse> {
    return this.http.get<RecommendationHistoryResponse>(Keys.API_URL + 'api/Recommendation/History/' + businessId);
  }

  get(recommendationId: string): Observable<RecommendationResponse> {
    return this.http.get<RecommendationResponse>(Keys.API_URL + 'api/Recommendation/' + recommendationId);
  }
}