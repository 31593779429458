import { DashboardAnalyticsResponse } from '@app/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Analytics';

  analytics(): Observable<DashboardAnalyticsResponse> {
    return this.http.get<DashboardAnalyticsResponse>(this.rootURL + '/DashboardAnalytics/');
  }
}