import {
  AlterBusinessHourRequest,
  AlterMultipleBusinessHourRequest,
  BusinessDetailResponse,
  BusinessHourResponse,
  BusinessImageRequest,
  BusinessResponse,
  ConfirmationResponse,
  CreateBusinessRequest,
  FeedbackResponse,
  UpdateBusinessDetailsRequest
} from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  markAsSeen(id: string): Observable<ConfirmationResponse> {
    return this.http.get<ConfirmationResponse>(Keys.API_URL + 'api/Feedback/MarkAsSeen/' + id);
  }

  showToCustomer(id: string, show: boolean): Observable<ConfirmationResponse> {
    return this.http.get<ConfirmationResponse>(Keys.API_URL + `api/Feedback/ShowToCustomer/${id}?show=${show}`);
  }

  get(businessId: string): Observable<FeedbackResponse> {
    return this.http.get<FeedbackResponse>(Keys.API_URL + 'api/Feedback/Get/' + businessId);
  }
}