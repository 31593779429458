import { Component, OnInit } from '@angular/core';

import { AuthorInfoDto } from '@app/models';
import { AuthorInfoStateService } from './author-information.state';
import { CreateAuthorInformationComponent } from './components/create-author-information/create-author-information.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { UpdateAuthorInformationComponent } from './components/update-author-information/update-author-information.component';
import { UpdateAuthorInformationImageComponent } from './components/update-author-information-image/update-author-information-image.component';

@Component({
  selector: 'app-author-information',
  templateUrl: './author-information.component.html',
  styleUrls: ['./author-information.component.scss']
})
export class AuthorInformationComponent implements OnInit{
  
  authorInfo$: Observable<AuthorInfoDto> = this._state.authorInfo$; 
  constructor(private _state: AuthorInfoStateService,
    public dialog: MatDialog) {
    
  }
  
  ngOnInit(): void {
    this._state.load();
  }

  createInfo(){
    const dialogRef = this.dialog.open(CreateAuthorInformationComponent, {
      panelClass: 'panel-class',      
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this._state.load();
      }
    });
  }

  updateInfo(authorid: string){
    const dialogRef = this.dialog.open(UpdateAuthorInformationComponent, {
      panelClass: 'panel-class',
      data: {authorInformationId: authorid }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this._state.load();
      }
    });
  
  }

  updateImage(authorid: string){
    const dialogRef = this.dialog.open(UpdateAuthorInformationImageComponent, {
      panelClass: 'panel-class',
      data: {authorInformationId: authorid }
    });
    dialogRef.afterClosed().subscribe(result => {
        if(result){
          this._state.load();
        }
    });
  }
}
