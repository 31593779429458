import { HomeHeaderComponent } from './components/shared/homeheader/homeheader.component';
import { BottomSheetCategoryPicker, CreateListingComponent } from './components/pages/create-listing/create-listing.component';
import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonModule, SocialLoginModule } from '@abacritt/angularx-social-login';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { AlertModule } from './components/shared/alert/alert.module';
import { AlterBusinessHoursComponent } from './components/pages/listing-detail/components/alter-business-hours/alter-business-hours.component';
import { AnonymousLayoutComponent } from './components/layouts/anonymous-layout.component';
import { AppCalendarComponent } from './components/pages/app-calendar/app-calendar.component';
import { AppComponent } from './app.component';
import { AppEmailComponent } from './components/pages/app-email/app-email.component';
import { AppEmailComposeComponent } from './components/pages/app-email-compose/app-email-compose.component';
import { AppEmailReadComponent } from './components/pages/app-email-read/app-email-read.component';
import { AppMessageComponent } from './components/pages/app-message/app-message.component';
import { AppRoutingModule } from './app-routing.module';
import { AppTodoComponent } from './components/pages/app-todo/app-todo.component';
import { AuthenticatedLayoutComponent } from './components/layouts/authenticated-layout.component';
import { AuthorInformationComponent } from './components/pages/author-information/author-information.component';
import { BookingsComponent } from './components/pages/bookings/bookings.component';
import { BookmarksComponent } from './components/pages/bookmarks/bookmarks.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { CommonModule } from '@angular/common';
import { ConfirmPasswordComponent } from './components/pages/confirm-password/confirm-password.component';
import { CountryCodeInterceptor } from './helpers/country-code-interceptor';
import { CreateAuthorInformationComponent } from './components/pages/author-information/components/create-author-information/create-author-information.component';
import { CreateTicketComponent } from './components/pages/support/create-ticket/create-ticket.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { DownloadAppAccessComponent } from './components/shared/no-access/download-app.component';
import { DownloadQrComponent } from './components/pages/listing-detail/components/download-qr/download-qr.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { FeedbackComponent } from './components/pages/listing-detail/components/feedback/feedback.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { ImagePickerModule } from './components/shared/image-picker/image-picker.module';
import { InvoiceComponent } from './components/pages/invoice/invoice.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ListingDetailComponent } from './components/pages/listing-detail/listing-detail.component';
import { ListingStatisticsComponent } from './components/pages/listing-detail/components/listing-statistics/listing-statistics.component';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoadingInterceptor } from './helpers/loading.interceptor';
import { LoginComponent } from './components/pages/login/login.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MyListingComponent } from './components/pages/my-listing/my-listing.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimepicker } from '@ng-bootstrap/ng-bootstrap';
import { NoAccessComponent } from './components/shared/no-access/no-access.component';
import { PermissionDirective } from './components/shared/directives/permission.directive';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ProductListingComponent } from './components/pages/product-listing/product-listing.component';
import { QuillModule } from 'ngx-quill';
import { RecommendationComponent } from './components/pages/recommendation/recommendation.component';
import { RecommendationDetailComponent } from './components/pages/recommendation/components/recommendation-detail/recommendation-detail.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { ScrollerModule } from './components/shared/scrollers/scroller.module';
import { SidebarComponent } from './components/layouts/sidebar/sidebar.component';
import { SupportComponent } from './components/pages/support/support.component';
import { SurveyModule } from 'survey-angular-ui';
import { TagInputModule } from 'ngx-chips';
import { TransactionDetailsComponent } from './components/pages/invoice/components/transaction-details/transaction-details.component';
import { UpdateAuthorInformationComponent } from './components/pages/author-information/components/update-author-information/update-author-information.component';
import { UpdateAuthorInformationImageComponent } from './components/pages/author-information/components/update-author-information-image/update-author-information-image.component';
import { ViewTicketComponent } from './components/pages/support/view-ticket/view-ticket.component';
import { WebAnalyticsComponent } from './components/charts/web-analytics/web-analytics.component';

@NgModule({
  declarations: [
    PermissionDirective,
    NoAccessComponent,
    DownloadAppAccessComponent,
    AuthenticatedLayoutComponent,
    AnonymousLayoutComponent,
    ForgotPasswordComponent,
    ConfirmPasswordComponent,
    ComingSoonComponent,
    AppComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    AppMessageComponent,
    AppEmailComponent,
    AppEmailReadComponent,
    AppEmailComposeComponent,
    AppTodoComponent,
    AppCalendarComponent,
    MyProfileComponent,
    SupportComponent,
    InvoiceComponent,
    RecommendationComponent,
    MyListingComponent,
    BookmarksComponent,
    BookingsComponent,
    WebAnalyticsComponent,
    LoginComponent,
    HomeHeaderComponent,
    LogoutComponent,
    RegisterComponent,
    ListingDetailComponent,
    ListingStatisticsComponent,
    RecommendationDetailComponent,
    CreateListingComponent,
    LoaderComponent,
    PricingComponent,
    AlterBusinessHoursComponent,
    AuthorInformationComponent,
    CreateAuthorInformationComponent,
    UpdateAuthorInformationComponent,
    UpdateAuthorInformationImageComponent,
    TransactionDetailsComponent,
    FeedbackComponent,
    DownloadQrComponent,
    CreateTicketComponent,
    ViewTicketComponent,
    BottomSheetCategoryPicker,
    ProductListingComponent,
  ],
  imports: [
    //ANGULAR
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    //CUSTOM MODULES
    AlertModule,
    ImagePickerModule,
    ScrollerModule,
    //ANGULAR MATERIAL
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatSelectModule,
    //NG Bootstrap
    NgbTimepicker,
    //THIRD PARTY
    QuillModule.forRoot(),
    LeafletModule,
    TagInputModule,
    SurveyModule,
    EditorModule,
    NgbModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    NgxGoogleAnalyticsModule.forRoot('G-NSPNE9W961'),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CountryCodeInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('442899596499-b867q2cpbtarloggoro6pr0qosmqrlag.apps.googleusercontent.com')
          },
        ]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
