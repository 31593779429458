import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '@app/services';
import { GlobalStateService } from '@app/global.state';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { numbersOnlyValidator } from '@app/components/shared/validators/numbers-only-validator';
import { passwordValidator } from '@app/components/shared/validators/password-validator';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, AfterViewInit {

	@ViewChild('registerNgForm') registerNgForm: NgForm;

	registerForm: UntypedFormGroup;
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _router: Router,
		private _state: GlobalStateService,
		private _authService: AuthService,
	) {
	}

	ngOnInit(): void {
		this.registerForm = this._formBuilder.group({
			firstName: ['', [Validators.required, vulgarLanguageValidator()]],
			lastName: ['', [Validators.required, vulgarLanguageValidator()]],
			email: ['', [Validators.required, Validators.email, vulgarLanguageValidator()]],
			phoneNumber: ['', [Validators.required, numbersOnlyValidator(), Validators.minLength(10), vulgarLanguageValidator(),]],
			password: ['', [Validators.required, passwordValidator(), vulgarLanguageValidator()]],
			entityType: ['', [Validators.required]],
		});
	}

	ngAfterViewInit(): void {
		this._authService.check().subscribe(res => {
			if (res) {
				Swal.fire({
					title: 'Hmmm',
					text: 'You are already logged in. Click Ok to continue to your dashboard',
					icon: 'info',
				}).then(val => {
					setTimeout(() => {
						const redirectUrl = '/dashboard';
						// Navigate to the redirect url
						this._router.navigateByUrl(redirectUrl);
					}, 500);
				})

			}
		})
	}

	submit() {
		// Return if the form is invalid
		if (this.registerForm.invalid) {
			this._state.alertUI('form is invalid', 'warning');
			this.registerForm.markAllAsTouched();
			return;
		}
		this.registerForm.disable();

		this._authService.signUp({
			firstName: this.registerForm.controls.firstName.value,
			lastName: this.registerForm.controls.lastName.value,
			email: this.registerForm.controls.email.value,
			phoneNumber: this.registerForm.controls.phoneNumber.value,
			password: this.registerForm.controls.password.value,
			isIndividual: this.registerForm.controls.entityType.value === 'individual',
		}).subscribe(
			(result) => {
				// Re-enable the form
				this.registerForm.enable();
				if (result.data === null) {

					Swal.fire({
						title: 'Oh No',
						text: result.message,
						icon: 'error',
					})
				} else {
					// Reset the form
					this.registerNgForm.resetForm();
					Swal.fire({
						title: 'Success',
						text: 'Registration Completed',
						icon: 'success',
					}).then(val => {
						setTimeout(() => {
							const redirectUrl = '/login';
							// Navigate to the redirect url
							this._router.navigateByUrl(redirectUrl);
						}, 500);
					})
				}

			},
			(error) => {
				// Re-enable the form
				this.registerForm.enable();

				// Reset the form
				this.registerNgForm.resetForm();
			}
		)
	}

}
