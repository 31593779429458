import {
  CustomerInfoResponse,
  CustomerTotalsResponse,
  InvoiceResponse,
  TransactionInfoResponse
} from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private http: HttpClient) { }

  invoiceUrl = Keys.API_URL + 'api/Invoice';

  get(): Observable<InvoiceResponse> {
    return this.http.get<InvoiceResponse>(this.invoiceUrl + '/false');
  }

  customerInfo(): Observable<CustomerInfoResponse> {
    return this.http.get<CustomerInfoResponse>(this.invoiceUrl + '/Customer');
  }

  customerTotals(): Observable<CustomerTotalsResponse> {
    return this.http.get<CustomerTotalsResponse>(this.invoiceUrl + '/CustomerTotals');
  }

  transactionInfo(id: number): Observable<TransactionInfoResponse> {
    return this.http.get<TransactionInfoResponse>(this.invoiceUrl + '/Transaction/' + id);
  }


}