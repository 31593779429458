import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	Renderer2,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';

const FontSize = window ? Number(window.getComputedStyle(document.body).getPropertyValue('font-size').match(/\d+/)?.[0]) : 16;
const MAX_PERCENTAGE = 100;

@Component({
	selector: 'bar-indicator',
	templateUrl: './bar-indicator.component.html',
	styleUrls: ['./bar-indicator.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class BarIndicatorComponent implements AfterViewInit {
	indicatorBar = {
		startX: 0,
		endX: 0,
	};

	translatedPercentage = 0;
	currentBreakpoint = 0;
	translatedAmount = 0;
	difference = 0;
	parentWidth = 0;
	childWidth = 0;

	isDragging = false;
	isSnapping = true;
	isSnapToBreakpoint = false;

	progressTimeout: any;
	breakpointPercentages: number[] = [];

	@Input() indicatorWidth = 11.25;
	@Input() activeWidth = 3.125;

	@Input() variant = 'sunshine';

	@Input() enableTransition = true;

	@Input() thresholdList: any = [];

	@Input() set progress(value: number) {
		clearTimeout(this.progressTimeout);

		this.translatedPercentage = Math.min(Math.abs(value), MAX_PERCENTAGE);

		this.setTranslate(this.translatedPercentage);

		// Because if the breakpoints need to be set when the progress is updated, having a timeout will smoothen the animation.
		this.progressTimeout = setTimeout(() => {
			if (this.isSnapToBreakpoint) {
				this.setBreakpoint(this.translatedPercentage);
				this.translatedAmount = this.parentWidth * (this.thresholdList[this.currentBreakpoint] / 100);
			} else {
				this.translatedAmount = (this.parentWidth * this.translatedPercentage) / 100;
			}
		}, 300);
	}

	@ViewChild('parent', { static: true }) parentContainer?: ElementRef;
	@ViewChild('child', { static: true }) childContainer?: ElementRef;

	@Output() progressDetails = new EventEmitter<any>();

	constructor(private renderer: Renderer2) {}

	get ratio() {
		this.parentWidth = this.indicatorWidth * FontSize;
		this.childWidth = this.activeWidth * FontSize;
		return (this.parentWidth - this.childWidth) / this.childWidth;
	}

	ngAfterViewInit() {
		this.translatedAmount =
			this.childContainer?.nativeElement.getBoundingClientRect().left -
			this.parentContainer?.nativeElement.getBoundingClientRect().left;

		// Because generating a threshold list can vary in execution time, this timeout helps with race conditions.
		setTimeout(() => {
			this.calculateBreakpoints();
		}, 50);
	}

	@HostListener('window:resize')
	calculateBreakpoints() {
		this.isSnapToBreakpoint = this.thresholdList.length > 1;

		if (this.isSnapToBreakpoint) {
			this.breakpointPercentages = this.percentageIntervals(this.thresholdList.length + 1);
		}
	}

	percentageIntervals(numBreakpoints: number) {
		const percentages = [];
		const intervalSize = 100 / (numBreakpoints - 1);

		for (let i = 0; i < numBreakpoints; i++) {
			percentages.push(i * intervalSize);
		}

		return percentages;
	}

	startDrag(event: PointerEvent) {
		(event.target as Element).setPointerCapture(event.pointerId);
		this.indicatorBar.startX = event.clientX;
		this.isDragging = true;
		this.enableTransition = false;
	}

	dragging(event: PointerEvent) {
		if (!this.isDragging) {
			return;
		}

		this.isSnapping = false;
		this.difference = event.clientX - this.indicatorBar.startX;
		this.translatedPercentage = ((this.translatedAmount + this.difference) / this.parentWidth) * 100;

		if (this.translatedPercentage < 0) {
			this.translatedPercentage = 0;
			this.setTranslate(this.translatedPercentage);
		} else if (this.translatedPercentage > MAX_PERCENTAGE) {
			this.translatedPercentage = MAX_PERCENTAGE;
			this.setTranslate(this.translatedPercentage);
		} else {
			this.setTranslate(this.translatedPercentage);
		}

		this.emitProgress();
	}

	endDrag(event: PointerEvent) {
		(event.target as Element).releasePointerCapture(event.pointerId);
		this.isDragging = false;
		this.enableTransition = true;

		if (!this.isSnapToBreakpoint) {
			const currentDragDistance = this.translatedAmount + this.difference;

			if (currentDragDistance > this.parentWidth) {
				this.translatedAmount = this.parentWidth;
			} else if (currentDragDistance < 0) {
				this.translatedAmount = 0;
			} else {
				this.translatedAmount = currentDragDistance;
			}
		}

		this.isSnapping = true;
		this.setEndValues();
		this.difference = 0;
	}

	cancelDrag(event: PointerEvent) {
		this.endDrag(event);
		event.preventDefault();
	}

	setTranslate(value: number) {
		requestAnimationFrame(() => {
			// Because of animations, the translation needs to be in the next reflow cycle.
			setTimeout(() => this.renderer.setStyle(this.childContainer?.nativeElement, 'translate', value * this.ratio + '%'));
		});
	}

	translateOnClick(event: any) {
		if (event.target.classList.contains('vivid-bar-indicator')) {
			this.enableTransition = true;

			this.translatedAmount = event.clientX - event.target.getBoundingClientRect().left;
			this.translatedPercentage = (this.translatedAmount / this.parentWidth) * 100;

			this.setEndValues();
		}
	}

	setBreakpoint(percentage: number) {
		const setValue = (index: number) => {
			this.currentBreakpoint = index;
			this.translatedPercentage = this.thresholdList[index];
		};

		for (let i = 0; i < this.breakpointPercentages.length; i++) {
			if (percentage >= this.breakpointPercentages[i] && percentage < this.breakpointPercentages[i + 1]) {
				setValue(i);
				break;
			} else if (percentage === MAX_PERCENTAGE) {
				setValue(this.thresholdList.length - 1);
				break;
			}
		}
	}

	setSnapValues() {
		this.translatedAmount = this.parentWidth * (this.thresholdList[this.currentBreakpoint] / 100);
		this.setTranslate(this.thresholdList[this.currentBreakpoint]);
	}

	emitProgress() {
		this.progressDetails.emit({
			percentage: this.translatedPercentage,
			breakpoint: this.currentBreakpoint,
			snap: this.isSnapping,
		});
	}

	setEndValues() {
		if (this.isSnapToBreakpoint) {
			this.setBreakpoint(this.translatedPercentage);
			this.setSnapValues();
		} else {
			this.setTranslate(this.translatedPercentage);
		}

		this.emitProgress();
	}
}
