import { BusinessDetailsDto, CategoryDto } from '@app/models';
import { BusinessService, LoadingService, UserService } from '@app/services';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Convert, createListingSurvey } from '@app/models/constants/create-listing.survey';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MapOptions, circle, latLng, tileLayer } from 'leaflet';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Observable, debounceTime, map, switchMap } from 'rxjs';

import { ApplicationPermissions } from '@app/models/constants/permissions';
import { CreateListingStateService } from './create-listing.state';
import { HttpClient } from '@angular/common/http';
import { ImagePickerConf } from '@app/components/shared/image-picker/models/index.models';
import { Model } from 'survey-core';
import { ResponsiveService } from '@app/services/responsive.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { numbersOnlyValidator } from '@app/components/shared/validators/numbers-only-validator';
import { osmAddressValidator } from '@app/components/shared/validators/open-street-validator';
import { urlValidator } from '@app/components/shared/validators/url-validator';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
	selector: 'app-create-listing',
	templateUrl: './create-listing.component.html',
	styleUrls: ['./create-listing.component.scss']
})
export class CreateListingComponent implements OnInit {
	appPermissions = ApplicationPermissions;
	firstFormGroup: UntypedFormGroup;
	secondFormGroup: UntypedFormGroup;
	thirdFormGroup: UntypedFormGroup;
	categories$: Observable<CategoryDto[]> = this._state.categories$;
	businesses$: BusinessDetailsDto[] = [];
	apikey = 'uguxeiycnpetg4pywx3u0a8u7xo2uunlw60jhyewpau929s7';
	wasValidated = false;
	imagePickerConf: ImagePickerConf = {
		borderRadius: '8px',
		language: 'en',
		width: '300px',
		objectFit: 'contain',
		aspectRatio: 4 / 3,
		compressInitial: null,
	};

	latitude: number = 0;
	longitude: number = 0;

	options: MapOptions;

	isMobile: boolean;
	surveyModel: Model;

	constructor(private _formBuilder: FormBuilder,
		private _businessService: BusinessService,
		private _loadingService: LoadingService,
		private _userService: UserService,
		private _router: Router,
		private _state: CreateListingStateService,
		private _cdRef: ChangeDetectorRef,
		private _responsiveService: ResponsiveService,
		private _bottomSheet: MatBottomSheet,
		private _http: HttpClient) { }



	ngOnInit(): void {
		//change until tinymce is fixed
		this.isMobile = true;
		const survey = new Model(createListingSurvey);
		survey.onComplete.add(this.completeSurvey.bind(this));
		this.surveyModel = survey;
		// this._responsiveService.isMobile().subscribe((isMobile) => {
		// 	this.isMobile = isMobile;
		// 	const survey = new Model(createListingSurvey);
		// 	survey.onComplete.add(this.completeSurvey.bind(this));
		// 	this.surveyModel = survey;
		// 	this._cdRef.detectChanges();
		// });
		this._state.load();
		this._userService.details()
			.pipe(map(response => {
				response.data.forEach(x => {
					this.businesses$.push(x);
				})
			}));

		this.firstFormGroup = this._formBuilder.group({
			name: ['', [Validators.required, Validators.minLength(5), vulgarLanguageValidator()]],
			email: ['', [Validators.required, Validators.email, vulgarLanguageValidator()]],
			phoneNumber: ['', [Validators.required, Validators.minLength(10), numbersOnlyValidator(), vulgarLanguageValidator()]],
			selectedCategory: ['Choose a category', [Validators.required]],
			website: ['', [urlValidator(), vulgarLanguageValidator()]],
			physicalAddress: ['', [Validators.required, vulgarLanguageValidator()], [osmAddressValidator(this._http)]],
		});
		this.secondFormGroup = this._formBuilder.group({
			description: [null, [Validators.required]],
		});
		this.thirdFormGroup = this._formBuilder.group({
			header: ['', []],
			profile: ['', []],
			headersrc: [null, []],
			profilesrc: [null, []],
		});

		this.firstFormGroup.get('physicalAddress').valueChanges
			.pipe(
				debounceTime(300), // Adjust debounce time as needed
				switchMap((address: string) => this.fetchGeolocation(address))
			)
			.subscribe((geolocation: any) => {
				if (geolocation && geolocation.length > 0) {
					// Geolocation data is available, use it
					this.latitude = geolocation[0].lat;
					this.longitude = geolocation[0].lon;
					// Do something with latitude and longitude
					this.options = {
						layers: [
							tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
								maxZoom: 18,
								attribution: 'Open Street Map'
							}),
							circle([this.latitude, this.longitude], { radius: 100 }).bindPopup(this.firstFormGroup.get('physicalAddress').value)
						],
						zoom: 16,
						center: latLng(this.latitude, this.longitude),
					};
					this._cdRef.detectChanges();
				} else {
					// Handle the case when geolocation data is not available
				}
			});
	}

	completeSurvey(sender: any) {
		const result = Convert.toCreateListingResult(JSON.stringify(sender.data));
		if (result) {
			if (result.question7.length === 0 || result.question8.length === 0) {
				this._state.alertUI('Missing information, please refresh and try again', 'error');
				return;
			} else {
				var ref = this._bottomSheet.open(BottomSheetCategoryPicker, {
					disableClose: true,
				});
				ref.afterDismissed().subscribe(res => {
					this._businessService.create({
						name: result.question1,
						description: result.question6,
						categoryId: res.categoryId,
						phoneNumber: result.question5,
						emailAddress: result.question4,
						website: result.question2,
						physicalAddress: result.question3,
					}).subscribe(response => {
						if (response.data) {
							const businessId = response.data.id;
							this._businessService.profileImage({
								businessProfileId: businessId,
								file: new File([this.dataURItoBlob(result.question7[0].content)], result.question7[0].name, { type: result.question7[0].type }),
							}).subscribe((profileResponse) => {
								if (profileResponse.data) {
									this._businessService.headerImage({
										businessProfileId: businessId,
										file: new File([this.dataURItoBlob(result.question8[0].content)], result.question8[0].name, { type: result.question8[0].type }),
									}).subscribe(headerResponse => {
										this._loadingService.setLoading(false);
										if (headerResponse.data) {
											Swal.fire({
												title: 'Created',
												text: 'Successfully created your listing.',
												icon: 'success',
												confirmButtonText: 'Go Now',
											}).then((result) => {
												if (result.isConfirmed) {
													this._router.navigateByUrl('/my-listing');
												}
											});
										} else {
											this.surveyModel.render();
											this.surveyModel.clear();
											Swal.fire({
												title: 'Oh no please try again',
												text: response.message,
												icon: 'error',
											});
										}
									})
								} else {
									this._loadingService.setLoading(false);
									this.surveyModel.render();
									this.surveyModel.clear();
									Swal.fire({
										title: 'Oh no please try again',
										text: response.message,
										icon: 'error',
									});
								}
							});
						} else {
							this._loadingService.setLoading(false);
							this.surveyModel.render();
							this.surveyModel.clear();
							Swal.fire({
								title: 'Oh no please try again',
								text: response.message,
								icon: 'error',
							});
						}
					});
				});
			}

		}
	}

	fetchGeolocation(address: string) {
		const encodedAddress = encodeURIComponent(address);
		const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`;

		return this._http.get(apiUrl);
	}

	onProfileChanged(event: any) {

		this.thirdFormGroup.controls.profilesrc.setValue(event);
		this.thirdFormGroup.controls.profilesrc.updateValueAndValidity();

		const imageBlob = this.dataURItoBlob(event);
		const file = new File([imageBlob], 'profile-picture.png', { type: 'image/png' });

		this.thirdFormGroup.controls.profile.setValue(file);
		this.thirdFormGroup.controls.profile.updateValueAndValidity();

	}

	onHeaderChanged(event: any) {
		this.thirdFormGroup.controls.headersrc.setValue(event);
		this.thirdFormGroup.controls.headersrc.updateValueAndValidity();
		const imageBlob = this.dataURItoBlob(event);
		const file = new File([imageBlob], 'header-picture.png', { type: 'image/png' });
		this.thirdFormGroup.controls.header.setValue(file);
		this.thirdFormGroup.controls.header.updateValueAndValidity();

	}

	isThirdFormGroupOk() {
		return this.thirdFormGroup.controls.header.value && this.thirdFormGroup.controls.profile.value
	}

	submit() {
		if (!this.firstFormGroup.valid || !this.secondFormGroup.valid) {
			this.wasValidated = true;
			Swal.fire({
				title: 'You have an error',
				text: 'Check all your entries and make sure everything is valid.',
				icon: 'error',
			});
			return;
		}
		if (!this.thirdFormGroup.controls.header.value) {
			Swal.fire({
				title: 'You need a header image',
				text: 'Upload a header image and continue your listing submission',
				icon: 'error',
			});
			return;
		}
		if (!this.thirdFormGroup.controls.profile.value) {
			Swal.fire({
				title: 'You need a profile image',
				text: 'Upload a profile image and continue your listing submission',
				icon: 'error',
			});
			return;
		}
		if (!(this.firstFormGroup.controls.selectedCategory.value)) {
			Swal.fire({
				title: 'Select a Category',
				text: 'You need to pick a category. Be mindful you cannot change this later',
				icon: 'warning',
			});
			return;
		}
		this._loadingService.setLoading(true);
		this._businessService.create({
			name: this.firstFormGroup.controls.name.value,
			description: this.secondFormGroup.controls.description.value,
			categoryId: this.firstFormGroup.controls.selectedCategory.value,
			phoneNumber: this.firstFormGroup.controls.phoneNumber.value,
			emailAddress: this.firstFormGroup.controls.email.value,
			website: this.firstFormGroup.controls.website.value,
			physicalAddress: this.firstFormGroup.controls.physicalAddress.value,
			latitude: this.latitude,
			longitude: this.longitude,
		}).subscribe(response => {
			if (response.data) {
				const businessId = response.data.id;
				this._businessService.profileImage({
					businessProfileId: businessId,
					file: this.thirdFormGroup.controls.profile.value,
				}).subscribe((profileResponse) => {
					if (profileResponse.data) {
						this._businessService.headerImage({
							businessProfileId: businessId,
							file: this.thirdFormGroup.controls.header.value,
						}).subscribe(headerResponse => {
							this._loadingService.setLoading(false);
							if (headerResponse.data) {
								Swal.fire({
									title: 'Created',
									text: 'Successfully created your listing.',
									icon: 'success',
									confirmButtonText: 'Go Now',
								}).then((result) => {
									if (result.isConfirmed) {
										this._router.navigateByUrl('/my-listing');
									}
								});
							} else {
								Swal.fire({
									title: 'Oh no',
									text: headerResponse.message,
									icon: 'error',
								});
							}
						})
					} else {
						this._loadingService.setLoading(false);
						Swal.fire({
							title: 'Oh no',
							text: profileResponse.message,
							icon: 'error',
						});
					}
				});
			} else {
				this._loadingService.setLoading(false);
				Swal.fire({
					title: 'Oh no',
					text: response.message,
					icon: 'error',
				});
			}
		});
	}

	dataURItoBlob(dataURI: string) {
		const byteString = window.atob(dataURI.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', ''));
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/png' });
		return blob;
	}
}



@Component({
	selector: 'bottom-sheet-category-picker',
	template: `
  <div class="container">
	<!-- Top-right cancel button -->
	<div class="row">
	  <button mat-icon-button class="top-right-button" (click)="cancel()">
		<mat-icon color="warn">cancel</mat-icon>
	  </button>
	</div>
  
	<form [formGroup]="form" class="mt-3 pt-3 mb-1 pb-1">    
	<div class="row m2">
	  <h3>One more thing and we are ready to go</h3>
	</div>
	  <div class="row">
		<mat-form-field class="col-md-12">
		  <mat-label>Category</mat-label>
		  <mat-select [formControlName]="'category'">
			<ng-container *ngFor="let c of categories$ | async">
				<mat-option [value]="c.id">{{c.categoryName}}</mat-option>
			</ng-container>
		  </mat-select>
		  <mat-hint>Choose the category that your listing will fall under</mat-hint>
		  <mat-error
			  *ngIf="form.get('category').hasError('required') && form.get('category').touched">
			  This field is required.
		  </mat-error>		
		</mat-form-field>    		
	  </div>
	</form>
	<div class="row mb-4 pb-4">    
	  <!-- Bottom-center submit button -->
	  <div class="col-md-12 text-center">
		<button mat-raised-button color="primary" (click)="submit()">Submit</button>
	  </div>
	</div>
  </div>
  
	`,
	styles: [`
	.top-right-button {
	  position: absolute;
	  top: 0;
	  right: 0;
	  margin: 10px; /* Adjust the margin as needed */
	}
	`]
})
export class BottomSheetCategoryPicker implements OnInit {

	form: UntypedFormGroup;
	categories$: Observable<CategoryDto[]> = this._state.categories$;

	constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetCategoryPicker>,
		private _formBuilder: UntypedFormBuilder,
		private _state: CreateListingStateService,
		private _router: Router,
	) { }

	ngOnInit(): void {
		this._state.load();
		this.form = this._formBuilder.group({
			category: [null, [Validators.required]],
		});
	}

	submit() {
		if (this.form.invalid) {
			Swal.fire({
				title: 'Invalid Form',
				text: `Please ensure the form fields are correctly captured`,
				icon: 'error'
			});
			this.form.markAllAsTouched();
			return;
		} else {
			this._bottomSheetRef.dismiss({
				categoryId: this.form.controls.category.value,
			});
		}
	}
	cancel() {
		Swal.fire({
			title: 'Are you sure?',
			text: `If you cancel you cannot complete your listing setup. Are you sure you want to cancel`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, cancel.'
		}).then(result => {
			if (result.isConfirmed) {
				Swal.fire(
					'Cancelled',
					'Listing was not processed.',
					'info'
				);
				this._bottomSheetRef.dismiss();
				this._router.navigate(['']);
			}
		});

	}
}