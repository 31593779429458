<div #mainContainer class="snap-scroller">
	<div class="snap-scroller-container">

		<!--Outside the container to achieve design.-->
		<button *ngIf="showButtons && isContentOverflowed && currentBreakpoint !== 0"
				[ngClass]="{
					'snap-scroller-container__button-pearl': buttonColor === 'pearl',
					'snap-scroller-container__button-raven': buttonColor === 'raven',
					'snap-scroller-container__button-sunshine': buttonColor === 'sunshine'
				}">
			<svg [ngStyle]="{ left: -buttonPosition + 'px' }"
				 (click)="previousItem()"
				 width="32"
				 height="32"
				 viewBox="0 0 24 24"
				 xmlns="http://www.w3.org/2000/svg">
				<path d="M15.2929 20.7071C15.6834 21.0976 16.3166 21.0976 16.7071 20.7071C17.0976 20.3166 17.0976 19.6834 16.7071 19.2929L15.2929 20.7071ZM8 12L7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071L8 12ZM16.7071 4.70711C17.0976 4.31658 17.0976 3.68342 16.7071 3.29289C16.3166 2.90237 15.6834 2.90237 15.2929 3.29289L16.7071 4.70711ZM16.7071 19.2929L8.70711 11.2929L7.29289 12.7071L15.2929 20.7071L16.7071 19.2929ZM8.70711 12.7071L16.7071 4.70711L15.2929 3.29289L7.29289 11.2929L8.70711 12.7071Z"/>
			</svg>
		</button>

		<div class="snap-scroller-container__content">
			<div class="snap-scroller-container__content-wrapper">
				<div #childContainer
					 class="snap-scroller-container__content-wrapper-inner"
					 [ngStyle]="{ overflow: showOverflow ? 'visible' : 'hidden' }"
					 (pointerdown)="startDrag($event)"
					 (pointermove)="dragging($event)"
					 (pointercancel)="cancelDrag($event)"
					 (pointerup)="endDrag($event)"
					 (lostpointercapture)="endDrag($event)">
					<div #contentContainer
						class="snap-scroller-container__content-wrapper-inner-content"
						[ngClass]="{ 'snap-scroller-container__content-wrapper-inner-will-change': isGpuUtilized }"
						[ngStyle]="{
							transition: isTransitionEnabled ? 'translate ' + '300ms' : 'translate 0ms',
							gap: itemSpacing !== 16 ? itemSpacing + 'px' : '16px'
						}"
						(click)="eventPropagationHandler($event)">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>

		<!--Outside the container to achieve design.-->
		<button *ngIf="showButtons && isContentOverflowed && currentBreakpoint !== thresholds.length - 1"
				[ngClass]="{
					'snap-scroller-container__button-pearl': buttonColor === 'pearl',
					'snap-scroller-container__button-raven': buttonColor === 'raven',
					'snap-scroller-container__button-sunshine': buttonColor === 'sunshine'
				}">
			<svg [ngStyle]="{ right: -buttonPosition + 'px' }"
				 (click)="nextItem()"
				 width="32"
				 height="32"
				 viewBox="0 0 24 24"
				 xmlns="http://www.w3.org/2000/svg">
				<path d="M7.29289 19.2929C6.90237 19.6834 6.90237 20.3166 7.29289 20.7071C7.68342 21.0976 8.31658 21.0976 8.70711 20.7071L7.29289 19.2929ZM16 12L16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929L16 12ZM8.70711 3.29289C8.31658 2.90237 7.68342 2.90237 7.29289 3.29289C6.90237 3.68342 6.90237 4.31658 7.29289 4.70711L8.70711 3.29289ZM8.70711 20.7071L16.7071 12.7071L15.2929 11.2929L7.29289 19.2929L8.70711 20.7071ZM16.7071 11.2929L8.70711 3.29289L7.29289 4.70711L15.2929 12.7071L16.7071 11.2929Z"/>
			</svg>
		</button>
	</div>

	<div *ngIf="isContentOverflowed && showIndicator"
		 class="snap-scroller-indicator"
		 [ngStyle]="{
					'position': absoluteIndicatorPosition ? 'absolute' : 'relative',
					'padding-top': indicatorPosition + 'px',
					'top': __indicatorPositions[0] + 'px',
					'right': __indicatorPositions[1] + 'px',
					'bottom': __indicatorPositions[2] + 'px',
					'left': __indicatorPositions[3] + 'px'
				}">
		<bar-indicator
			[activeWidth]="indicatorActiveWidth"
			[indicatorWidth]="indicatorWidth"
			[variant]="indicatorColor"
			[enableTransition]="isTransitionEnabled"
			[progress]="percentageTranslated"
			[thresholdList]="thresholdPercentages"
			(progressDetails)="indicatorBarHandler($event)">
		</bar-indicator>
	</div>
</div>
