import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BasicFiltersComponent } from './components/basic-filters/basic-filters.component';
import { CommonModule } from '@angular/common';
import { CropperWrapperComponent } from './components/cropper-wrapper/cropper-wrapper.component';
import { EditImageComponent } from './components/edit-image/edit-image.component';
import { ImagePickerComponent } from './image-picker.component';
import { NgModule } from '@angular/core';
import { TabsComponent } from './components/tabs/tabs.component';

@NgModule({
  declarations: [
    ImagePickerComponent,
    TabsComponent,
    EditImageComponent,
    BasicFiltersComponent,
    CropperWrapperComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule],
  exports: [ImagePickerComponent],
})
export class ImagePickerModule { }
