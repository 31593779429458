<ng-container *ngIf="$siteSettings| async as siteSettings">
    <ng-container *ngIf="siteSettings.maintenanceMode; else router">
        <app-coming-soon [date]="siteSettings.maintenanceModeEnd"></app-coming-soon>
    </ng-container>
    <ng-template #router>
        <router-outlet></router-outlet>
    </ng-template>
</ng-container>
<ng-container *ngIf="loadingService.isLoading$ | async">
    <app-loader></app-loader>
</ng-container>