<div>
    <div *ngIf="business$ | async as business;">
        <div class="breadcrumb-area">
            <h1>{{business.profile.name}}</h1>
            <ol class="breadcrumb">
                <li class="item"><a routerLink="/">Dashboard</a></li>
                <li class="item"><a routerLink="/my-listing">My Listing</a></li>
                <li class="item">{{business.profile.name}}</li>
            </ol>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-md-12">
                <div class="btn-group d-flex justify-content-center" role="group" aria-label="Basic example">
                    <div class="btn btn-outline-success" (click)="createImage(business)"><i
                            class='bx bx-time bx-tada me-2'></i>Create Business QR Code Advert</div>
                    <div class="btn btn-outline-success" (click)="openFeedbackDialog(business.profile.id)"><i
                            class='bx bx-time bx-tada me-2'></i>Business Feedback</div>
                    <div class="btn btn-outline-success" (click)="openAlterBusinessHourDialog(business.profile.id)"><i
                            class='bx bx-time bx-tada me-2'></i>Alter Business Hours</div>
                    <div class="btn btn-outline-success" (click)="openListingStatsDialog(business.profile.id)"><i
                            class='bx bx-line-chart bx-tada me-2'></i>View Review Statistics</div>
                    <!-- <div class="btn btn-danger" (click)="deleteListing()"><i class='bx bxs-error bx-flashing me-2'></i>Delete Listing</div> -->
                </div>
            </div>
        </div>
        <div class="listing-detail-area-box">
            <form [formGroup]="form">
                <div class="listing-detail-box mb-30">
                    <h3>Basic Information</h3>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Listing Title:</label>
                                <input type="text" class="form-control" placeholder="Name of your business"
                                    [formControlName]="'name'">
                                <mat-error *ngIf="form.get('name').hasError('required') && form.get('name').touched">
                                    Listing name is required.
                                </mat-error>
                                <mat-error *ngIf="form.get('name').hasError('minlength') && form.get('name').touched">
                                    Listing name must be at least 5 letters long.
                                </mat-error>
                                <mat-error *ngIf="form.get('name').hasError('isProfane') && form.get('name').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Listing
                                    Description:</label>
                                <editor [apiKey]="apikey" cloudChannel="6" [disabled]="false" id="description_editor"
                                    [init]="{
                                            plugins: 'lists link help wordcount',
                                            toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
                                          }" [inline]="false" [formControlName]="'description'" tagName="div">
                                </editor>
                                <mat-error
                                    *ngIf="form.get('description').hasError('required') && form.get('description').touched">
                                    This field is required.
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group" style="width: 100%;">
                                <label>Category:</label>
                                <select style="width: 100%;" class="nice-select"
                                    *ngIf="categories$ | async as categories" [formControlName]="'selectedCategory'">
                                    <option *ngFor="let category of categories" [value]="category.id"
                                        [attr.selected]="category.id === business.category.id ? 'selected' : null">
                                        {{category.categoryName}}
                                    </option>
                                </select>
                                <mat-error
                                    *ngIf="form.get('selectedCategory').hasError('required') && form.get('selectedCategory').touched">
                                    This field is required.
                                </mat-error>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-md-3" *ngIf="subcategories$ | async as subcategories">
                            <div class="form-group">
                                <label><i class='bx bx-duplicate'></i> Sub Category:</label>
                                <select class="nice-select">
                                    <option 
                                    *ngFor="let subcategory of subcategories" 
                                    [value]="subcategory.id">
                                    {{subcategory.categoryName}}
                                    </option>
                                </select>
                            </div>  
                        </div> -->
                    </div>
                    <h3>Contact Information</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Business Phone:</label>
                                <input type="tel" class="form-control" placeholder="Phone Number"
                                    [formControlName]="'phoneNumber'">
                                <mat-error
                                    *ngIf="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('phoneNumber').hasError('minlength') && form.get('phoneNumber').touched">
                                    Phone number must have a minimum length of 10 digits.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('phoneNumber').hasError('isProfane') && form.get('phoneNumber').touched">
                                    Please refrane from profanity.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('phoneNumber').hasError('numbersOnly') && form.get('phoneNumber').touched">
                                    Phone number can only have numbers.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                                <label class="form-label">Business Email:</label>
                                <input type="email" class="form-control" placeholder="Phone Number"
                                    [formControlName]="'emailAddress'">
                                <mat-error
                                    *ngIf="form.get('emailAddress').hasError('required') && form.get('emailAddress').touched">
                                    This field is required.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('emailAddress').hasError('email') && form.get('emailAddress').touched">
                                    Please provide a valid email address.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('emailAddress').hasError('isProfane') && form.get('emailAddress').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Business Website:</label>
                                <input type="url" class="form-control" placeholder="Website"
                                    [formControlName]="'website'">
                                <mat-error
                                    *ngIf="form.get('website').hasError('invalidUrl') && form.get('website').touched">
                                    Please enter a valid url. Valid urls begin with 'https://' or contain a 'www' phrase
                                    at the
                                    beginning.
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('website').hasError('isProfane') && form.get('website').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Physical Address:</label>
                                <input type="text" class="form-control" placeholder="123 Wonderland Street"
                                    [formControlName]="'physicalAddress'">
                                <mat-error
                                    *ngIf="form.get('physicalAddress').hasError('invalidAddress') && form.get('physicalAddress').touched">
                                    Please enter a valid address. if you cannot find your address try searching <a
                                        [href]="'https://nominatim.openstreetmap.org/ui/search.html?q='+form.get('physicalAddress').value"
                                        target="_blank">here for the
                                        nearest one.</a>
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('physicalAddress').hasError('isProfane') && form.get('physicalAddress').touched">
                                    Please refrane from profanity.
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 p-5" *ngIf="options">
                            <div style="height: 400px;" leaflet [leafletOptions]="options">
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-warning" (click)="submit()">Update</button>
                </div>
            </form>
            <form [formGroup]="amenitiesForm">
                <div class="listing-detail-box mb-30">
                    <h3>Amenities</h3>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label class="form-label">Amenities:</label>
                                <tag-input [formControlName]="'amenities'" placeholder="+ Amenity"
                                    secondaryPlaceholder="Enter a new amenity" [identifyBy]="'id'" [theme]="'bootstrap'"
                                    [addOnPaste]="true" [pasteSplitPattern]="','" (onAdd)="onAdded($event)"
                                    (onRemove)="onRemoved($event, business.profile.amenities)" [identifyBy]="'id'"
                                    [displayBy]="'amenity'">
                                </tag-input>
                                <mat-hint>Type an amenity of your business. When youre done click enter and it will be
                                    populated. To remove one, simply press the 'x'</mat-hint>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="listing-detail-box mb-30">
                <h3>Images</h3>
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="form-label"> Profile Image:</label>
                            <image-picker [_imageSrc]="business.profile.profileImage?.originalUrl"
                                [_config]="imagePickerConf" ($imageChanged)="onProfileChanged($event)"></image-picker>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="form-label">Header Image:</label>
                            <image-picker [_imageSrc]="business.profile.headerImage?.originalUrl"
                                [_config]="imagePickerConf" ($imageChanged)="onHeaderChanged($event)"></image-picker>
                        </div>
                    </div>
                </div>
            </div>
            <div class="listing-detail-box mb-30">
                <h3>Gallery</h3>
                <div class="row" *appPermissions="appPermissions.ADD_BUSINESS_GALLERY;showErrorCard:true">
                    <div class="col-lg-4 col-md-4" *ngFor="let g of business.profile.gallery; let i = index">
                        <div class="form-group">
                            <label class="form-label"> Gallery Image :{{i+1}}</label>
                            <image-picker [imageId]="g.id" [_imageSrc]="g?.originalUrl"
                                [_config]="galleryimagePickerConf"
                                ($imageRemoved)="onGalleryImageRemoved($event)"></image-picker>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <label class="form-label">Add new Gallery Image:</label>
                            <image-picker #addGallery [_config]="galleryAddPickerConfig"
                                ($imageChanged)="onAddGalleryImageChange($event)"></image-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>