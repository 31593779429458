import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, finalize, takeUntil, takeWhile, tap, timer } from 'rxjs';

import { AuthService } from '@app/services';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { fuseAnimations } from '@app/components/shared/animations';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LogoutComponent implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  countdown: number = 5;
  countdownMapping: any = {
    '=1': '# second',
    'other': '# seconds'
  };

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
  ) { }

  ngOnInit(): void {
    this.authService.signOut();
    this.socialAuthService.signOut();
    timer(1000, 1000)
      .pipe(
        finalize(() => {
          this._router.navigate(['login']);
        }),
        takeWhile(() => this.countdown > 0),
        takeUntil(this._unsubscribeAll),
        tap(() => this.countdown--)
      )
      .subscribe();
  }

  /**
     * On destroy
     */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
