import { Component, OnInit } from '@angular/core';
import { LoadingService, SubscribeService } from '@app/services';
import { PlanDto, UserDto } from '@app/models';

import { Observable } from 'rxjs';
import { RootStateService } from '../root.state';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
	plans$: Observable<PlanDto[]> = this._rootState.availablePlans$;
	user$: Observable<UserDto> = this._rootState.user$;
	 
	constructor(private _rootState: RootStateService,
		private _loadingService: LoadingService,
		private _subscribeService: SubscribeService){
		
	}
	
	ngOnInit(): void {
		this._rootState.plans();
	}
	

	getPeriodType(arg0: number): string {
		switch (arg0) {
			case 0:
				return 'Once off';
			case 1:
				return 'Daily';
			case 2:
				return 'Weekly';				
			case 3:
				return 'Monthly';
			case 4:
				return 'Semi Annually';
			case 5:
				return 'Yearly';
			default:
				return '';
		}
	}

	activated() {
		Swal.fire({
			title:'You already have this plan',
			text: 'Keep on YeboKennnying!',
			icon: 'success',
		});
	}

	subscribe(id: string){
		this._loadingService.setLoading(true);
		this._subscribeService.subscribe(id).subscribe(result =>{
			this._loadingService.setLoading(false);
			if(result.data){
				Swal.fire({
					title:'You are about to be redirected for Payment',
					text: 'Do not worry. Upon payment success you should be redirected back here :)',
					icon: 'info',
					showCancelButton: true,
					confirmButtonText:'Lets Go'
				}).then(res =>{
					if(res.isConfirmed){
						window.location.href = result.data.authorizationUrl;
					}
				});
			}else{
				Swal.fire({
					title:'Something went wrong',
					text: result.message,
					icon: 'error',
				});
			}
		});
	}
}
