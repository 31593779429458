import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthorInfoStateService } from '../../author-information.state';
import { CreateAuthorInfoRequest } from '@app/models';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { MatDialogRef } from '@angular/material/dialog';
import { ImagePickerConf } from '@app/components/shared/image-picker/models/index.models';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-create-author-information',
  templateUrl: './create-author-information.component.html',
  styleUrls: ['./create-author-information.component.scss']
})
export class CreateAuthorInformationComponent implements OnInit {

  form: UntypedFormGroup;
  apikey = 'uguxeiycnpetg4pywx3u0a8u7xo2uunlw60jhyewpau929s7';

  imagePickerConf: ImagePickerConf = {
    borderRadius: '8px',
    language: 'en',
    width: '300px',
    objectFit: 'contain',
    aspectRatio: 4 / 3,
    compressInitial: null,
  };

  constructor(private _formBuilder: UntypedFormBuilder,
    private _state: AuthorInfoStateService,
    private _dialogRef: MatDialogRef<CreateAuthorInformationComponent>,) {

  }


  ngOnInit(): void {
    this._buildForm();
  }

  _buildForm() {
    this.form = this._formBuilder.group({
      displayName: ['', [Validators.required, vulgarLanguageValidator()]],
      jobTitle: ['', [Validators.required, , vulgarLanguageValidator()]],
      hobbies: ['', [Validators.required, , vulgarLanguageValidator()]],
      description: ['', [Validators.required, , vulgarLanguageValidator(), Validators.minLength(20),]],
      profile: ['', []],
      profilesrc: [null, []],
    });
  }

  submit() {
    if (this.form.invalid) {
      Swal.fire({
        title: 'You have an error',
        text: 'Check all your entries and make sure everything is valid.',
        icon: 'error',
      });
      this.form.markAllAsTouched();
      return;
    } else {
      this._state.create(this._buildRequest(), (response) => {
        Swal.fire({
          title: 'Created',
          text: response.messsage,
          icon: 'success',
        }).then((res: any) => {
          this._dialogRef.close(true);
        });
      }, (err) => {
        Swal.fire({
          title: 'Something went wrong',
          text: err,
          icon: 'error',
        });
      })
    }
  }

  onProfileChanged(event: any) {

    this.form.controls.profilesrc.setValue(event);
    this.form.controls.profilesrc.updateValueAndValidity();

    const imageBlob = this.dataURItoBlob(event);
    const file = new File([imageBlob], 'profile-picture.png', { type: 'image/png' });

    this.form.controls.profile.setValue(file);
    this.form.controls.profile.updateValueAndValidity();

  }

  private _buildRequest(): CreateAuthorInfoRequest {
    return <CreateAuthorInfoRequest>{
      displayName: this.form.controls.displayName.value,
      jobTitle: this.form.controls.jobTitle.value,
      hobbies: this.form.controls.hobbies.value,
      description: this.form.controls.description.value,
      file: this.form.controls.profile.value,
    }
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
