import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          switch (error.status) {
            case 400:
              errorMessage = 'Bad Request';
              break;
            case 401:
              errorMessage = 'Unauthorized';
              break;
            case 403:
              errorMessage = 'Forbidden';
              break;
            case 404:
              errorMessage = 'Not Found';
              break;
            case 500:
              errorMessage = 'Something has gone wrong';
            case 504:
              errorMessage = 'A connection to our server failed. Come back later';
              break;
            default:
              errorMessage = `HTTP Error ${error.status}: If this keeps happening, please email support`;
              break;
          }
        }
        if (errorMessage === 'Unauthorized') {
          this.router.navigate(['/login']); // Replace '/login' with the actual login page route
          return throwError(error);
        }else{
          Swal.fire({
            position: 'top-end',
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            icon: 'error',
            title: 'Something went wrong',
            text: errorMessage,
            showConfirmButton: false,
            timer: 3000,
            backdrop: `
                rgba(20, 20, 20,.8)
                left top
                no-repeat
            `
          })
        return null; 
        }
      })
    );
  }
}
