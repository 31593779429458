<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Dashboard</a></li>
        <li class="item">Recommendations Per Business</li>
    </ol>
</div>

<div class="card visitor-reviews-box mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Recommendations</h3>
    </div>
    <div class="row">
        <snap-scroller [showButtons]="true" [showOverflow]="false" *ngIf="businesses$ | async as businesses">
            <vivid-snap-scroller-slide *ngFor="let business of businesses">
                <div (click)="loadRecommendations(business.profile.id)" class="btn btn-primary">
                    {{business.profile.name}}</div>
            </vivid-snap-scroller-slide>
        </snap-scroller>
    </div>
    <ng-container>
        <div class="card-body" *ngIf="recommendations$ | async as recommendations; else spinner">
            <div *ngIf="recommendations.length === 0">
                <div class="h-100 d-flex align-items-center justify-content-center mt-5">
                    <h5>No Recommendations yet</h5>
                </div>
            </div>
            <div class="user-review" *ngFor="let r of recommendations">
                <div class="btn-box">
                    <button *appPermissions="appPermissions.VIEW_RECOMMENDATION_DETAILS;showErrorCard:false"
                        type="button" class="btn btn-outline-success" (click)="openDialog(r.recommendationId)">View
                        Detail</button>
                </div>

                <img [src]="r.signature" alt="image" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Signature of authenticity">

                <span class="d-block sub-comment" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Date the recommendation was logged">On: {{r.dateComputed | date}}</span>
                <span class="d-inline-block" data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Score given over max score achievable">Score Given: {{r.scoreGiven}} /
                    {{r.maxScoreAchievable}}</span>

                <p data-bs-toggle="tooltip" data-bs-placement="top"
                    title="Score Factor used in YeboKenny score calculation">
                    Score Factor Used: {{r.scoreFactorUsed}}
                </p>
            </div>
        </div>
    </ng-container>

</div>


<ng-template #spinner>
    <div class="h-100 d-flex align-items-center justify-content-center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>