import { ApplicationPermissions } from '@app/models/constants/permissions';
import { Component } from '@angular/core';

@Component({
	selector: 'app-product-listing',
	templateUrl: './product-listing.component.html',
	styleUrls: ['./product-listing.component.scss']
})
export class ProductListingComponent {
	appPermissions = ApplicationPermissions;
}
