import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceStateService } from '../../invoice.state';
import { Observable } from 'rxjs';
import { TransactionInfoDto } from '@app/models';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {

  transaction$: Observable<TransactionInfoDto> = this._state.transaction$;
  constructor(
    private _state: InvoiceStateService,
    private _dialogRef: MatDialogRef<TransactionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: {transactionId: number}){      
    }

  ngOnInit(): void {
    this._state.transaction(this._data.transactionId);
  }
}
