import { Component, OnInit } from '@angular/core';
import { CustomerInfoDto, CustomerTotalsDto, InvoiceDto, UserDto } from '@app/models';

import { InvoiceStateService } from './invoice.state';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RootStateService } from '../root.state';
import { TransactionDetailsComponent } from './components/transaction-details/transaction-details.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  invoices$: Observable<InvoiceDto[]> = this._state.invoices$;
  customer$: Observable<CustomerInfoDto> = this._state.customer$;
  totals$: Observable<CustomerTotalsDto> = this._state.totals$;
  user$: Observable<UserDto> = this._rootState.user$;
  
  constructor(private _state: InvoiceStateService,
    private _rootState: RootStateService,
    private _dialog: MatDialog) { }

  ngOnInit(): void {
    this._state.load();
  }

  getRandTotal(cents: number): number{
    return cents/100;
  }

  generateLink(code: string){
    this._state.generatelink(code);
  }

  tableLineClicked(invoice: InvoiceDto){
    const dialogRef = this._dialog.open(TransactionDetailsComponent, {
      data: {transactionId: invoice.transactionId},
    });

    dialogRef.afterClosed().subscribe(result => {
      this._state.reset();    
    });
  }
}
