<div class="sidemenu-area" [ngClass]="{
    'toggle-sidemenu-area': menuService.isMenuToggled$ | async,
    'active-sidemenu-area':menuService.isResponsiveMenuActive$ | async
}">
    <div class="sidemenu-header">
        <a href="/" class="navbar-brand d-flex align-items-center">
            <img src="assets/img/fulllogo.svg" alt="image">
        </a>

        <div class="burger-menu d-none d-lg-block" (click)="toggleMenu()">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>

        <div class="responsive-burger-menu d-block d-lg-none" (click)="toggleResponsiveMenu()" [ngClass]="{        
            'active':menuService.isResponsiveMenuActive$ | async
        }">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
    </div>

    <div class="sidemenu-body">
        <ul class="sidemenu-nav h-100" id="sidemenu-nav" data-simplebar>
            <li class="nav-item-title">
                Main
            </li>

            <li class="nav-item">
                <a routerLink="/dashboard" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-home-circle'></i></span>
                    <span class="menu-title">Dashboard</span>
                </a>
            </li>

            <li class="nav-item-title">
                Author
            </li>
        
            <li class="nav-item">
                <a routerLink="/author-information" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-book-bookmark'></i></span>
                    <span class="menu-title">Author Information</span>
                </a>
            </li>
            <li class="nav-item-title">
                Listings
            </li>

            <li class="nav-item">
                <a routerLink="/my-listing" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-map-alt'></i></span>
                    <span class="menu-title">My Listings</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/product-listings" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-barcode'></i></span>
                    <span class="menu-title">Product Listing</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/recommendations" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-star'></i></span>
                    <span class="menu-title">Recommendations</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/invoice" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-paper-plane'></i></span>
                    <span class="menu-title">Invoice and Billing</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/pricing" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-dollar'></i></span>
                    <span class="menu-title">Pricing and Deals</span>
                </a>
            </li>

            <li class="nav-item" *ngIf="businesses$ | async as businesses">
                <ng-container *ngIf="businesses.length > 0; else create_business">
                    <a *appPermissions="appPermissions.ADD_MULTIPLE_BUSINESSES" [routerLink]="'/create-listing'"
                        class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <span class="icon"><i class='bx bx-location-plus'></i></span>
                        <span class="menu-title">Add Listing</span>
                    </a>
                </ng-container>
                <ng-template #create_business>
                    <a class="nav-link" routerLinkActive="active" [routerLink]="'/create-listing'"
                        [routerLinkActiveOptions]="{exact: true}">
                        <span class="icon"><i class='bx bx-location-plus'></i></span>
                        <span class="menu-title">Add Listing</span>
                    </a>
                </ng-template>
            </li>

            <li class="nav-item">
                <a routerLink="/support" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-support'></i></span>
                    <span class="menu-title">Support</span>
                </a>
            </li>
            <!-- <li class="nav-item-title">
                Apps
            </li>

            <li class="nav-item">
                <a routerLink="/app-email" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-envelope'></i></span>
                    <span class="menu-title">Email</span>
                    <span class="badge">3</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/app-message" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-message'></i></span>
                    <span class="menu-title">Message</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/app-todo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-badge-check'></i></span>
                    <span class="menu-title">Todo</span>
                </a>
            </li>

            <li class="nav-item">
                <a routerLink="/app-calendar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-calendar'></i></span>
                    <span class="menu-title">Calendar</span>
                </a>
            </li> -->

            <li class="nav-item-title">
                Account
            </li>

            <li class="nav-item">
                <a routerLink="/my-profile" class="nav-link" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}">
                    <span class="icon"><i class='bx bx-user-pin'></i></span>
                    <span class="menu-title">My Profile</span>
                </a>
            </li>

            <li class="nav-item" (click)="signOut()">
                <div class="nav-link hover">
                    <span class="icon"><i class='bx bx-log-out'></i></span>
                    <span class="menu-title">Logout</span>
                </div>
            </li>
        </ul>
    </div>
</div>