<app-home-header></app-home-header>
<section class="login-area d-flex align-items-center justify-content-center mx-auto" *ngIf="$settings | async as settings">
    <div class="login-form-container bg-white rounded py-2 px-3">
        <div class="logo text-center mb-4">
            <img src="assets/img/logo.svg" alt="Yebokenny Logo" class="logo-img">
        </div> 


        <h2 class="text-center mb-2">Log in to your account</h2>
        <p class="text-center mb-4">Welcome back! Please enter your details.</p>


        <form [formGroup]="signInForm" #signInNgForm="ngForm" class="login-form">

            <div class="form-group mb-3">
                <label>Email</label>
                <input placeholder="Your Username / Email Address" class="form-control input"
                    id="username" matInput [formControlName]="'username'">
                <mat-error
                *ngIf="signInForm.get('username').hasError('required') && signInForm.get('username').touched">
                username is required
                </mat-error>
                <mat-error
                    *ngIf="signInForm.get('username').hasError('username') && signInForm.get('username').touched">
                    Please enter a valid username
                </mat-error>
                <mat-error
                    *ngIf="signInForm.get('username').hasError('isProfane') && signInForm.get('username').touched">
                    Please refrane from profanity
                </mat-error>
            </div>
            <div class="form-group mb-3">
                <label>Password</label>
                <input 
                    type="password" 
                    placeholder="Enter your password" 
                    class="input" 
                    [formControlName]="'password'"
                    id="password"
                    #passwordField
                />
                <mat-error
                *ngIf="signInForm.get('password').hasError('required') && signInForm.get('password').touched">
                Please enter your password
                </mat-error>
                <mat-error
                    *ngIf="signInForm.get('password').hasError('isProfane') && signInForm.get('password').touched">
                    Please refrane from profanity
                </mat-error>
            </div>


            <div class="form-options d-flex justify-content-between align-items-center mb-4">
                <div class="">
                    <input type="checkbox" class="mr-2" id="rememberMe" formControlName="rememberMe" />
                    <label for="rememberMe">Remember for 30 days</label>
                </div>
                <a routerLink="/forgot-password" style="text-decoration: none; color: #008B39;" class="forgot-password">Forgot password?</a>
            </div>


            <button 
                type="button" 
                class="btn btn-primary w-100 mb-3" 
                style="background:#008B39; outline:none!important;height:50px;border:none"
                [disabled]="signInForm.disabled" 
                (click)="signIn()"
            >
                Sign in
            </button>
            

            <div class="connect-with-social mt-2">
                <asl-google-signin-button type="" size="large" shape="pill"
                    text="signin_with" theme="outline" style="align-items: center;">
                </asl-google-signin-button>
            </div>
        </form>

        <p class="text-center mt-2">Don’t have an account? <a routerLink="/register" style="text-decoration: none; color: #008B39;">Sign up</a></p>
    </div>
</section>
<style>
    .login-area{
        min-height: 100vh;
        background-color: #F9F9F9;
    }

    @media (min-width: 992px) {
        .login-form-container {
            width: 35%;
        }
        }

        @media (max-width: 991px) and (min-width: 768px) {
        .login-form-container {
            width: 70%;
        }
        }

        @media (max-width: 767px) {
        .login-form-container{
            width: 90%;
        }
        }
    input.input{
        border: 1px solid #D0D5DD!important;
        background-color: white!important;
        box-shadow:none!important;
        outline: none!important; 
        height: 50px;
        width: 100%;
        border-radius: 8px;
        padding-inline: 10px;
    }
</style>