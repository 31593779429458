import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { AuthService } from './services';
import { GeoLocationService } from './services/geolocation.service';
import { GlobalStateService } from './global.state';
import { LoadingService } from './services/loading.service';
import { Observable } from 'rxjs';
import { SiteSettingsDto } from './models/dtos/site-settings.dto';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { filter } from 'rxjs/operators';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    $siteSettings: Observable<SiteSettingsDto> = this._state.siteSettings$;
    userCountryCode: string = "ZA";
    constructor(
        private _router: Router,
        private _state: GlobalStateService,
        private _activatedRoute: ActivatedRoute,
        private _geoLocationService: GeoLocationService,
        public loadingService: LoadingService,) {
    }

    ngOnInit() {
        this.recallJsFuntions();
        const storedCountryCode = localStorage.getItem('userCountryCode');

        if (storedCountryCode) {
            this.userCountryCode = storedCountryCode;
        } else {
            // If not in local storage, fetch it and store it
            // this._geoLocationService.getUserCountryCode().subscribe((data: any) => {
            //     this.userCountryCode = data.country;
            //     // Store the user's country code in local storage
            //     localStorage.setItem('userCountryCode', this.userCountryCode);
            // });
            localStorage.setItem('userCountryCode', this.userCountryCode);
        }
    }

    recallJsFuntions() {
        this.routerSubscription = this._router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/custom.js');
                this.location = this._router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}