import { ChangePasswordRequest, FeatureDto, MeResponse, PlanDto, UpdateUserRequest, UserDto } from "@app/models";
import { StateService, UserService } from "@app/services";

import { FeatureService } from "@app/services/feature.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

interface RootState {
    availablePlans: PlanDto[],
    user: UserDto,
}

const initialState: RootState = {
    availablePlans: [],
    user: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class RootStateService extends StateService<RootState>{    
      
    availablePlans$: Observable<PlanDto[]> = this.select((state)=>{
        if(state.availablePlans === undefined){
            this.plans();
        }
        return state.availablePlans;
    });
      
    user$: Observable<UserDto> = this.select((state)=>{
        if(state.user === undefined){
            this.me();
        }
        return state.user;
    });
    
    constructor(private _featureService: FeatureService, private _userService: UserService,){
        super(initialState);
    }
// ------------------------------------------------------------------------------------------
// @ API CALLS
// ------------------------------------------------------------------------------------------
	load(){
		this.plans();
        this.me();
	}

	
	plans(){
		this._featureService.getPlans().subscribe((response)=>{
			this.setState({availablePlans: response.data});
		});
	}

    me(){
        this._userService.get()
        .subscribe((user: MeResponse) => {
            this._userService.user = user.data;
            this.setState({user: user.data});
        });
    }

    updateUser(request: UpdateUserRequest){
        this._userService.updateUser(request).subscribe(res => {
            if(res.data){            
                this.alertUI(res.data.messsage, 'success');
            }else{            
                this.alertUI(res.message, 'error');
            }
        });
    }

    changePassword(request: ChangePasswordRequest){
        this._userService.changePassword(request).subscribe(res => {
            if(res.data){            
                this.alertUI(res.data.messsage, 'success');
            }else{            
                this.alertUI(res.message, 'error');
            }
        });
    }
    
}