<div class="breadcrumb-area" *ngIf="businesses$ | async as businesses">
    <button class="btn btn-danger me-2 ms-2" (click)="createNewListing()">
        Create New Listing
    </button>
    <h1>My Business Listings</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="item">My Listing</li>
    </ol>
</div>

<section class="listing-area mb-30">
    <div class="row" *ngIf="businesses$ | async as businesses">
        <div class="col-lg-4 col-sm-12 col-md-6" *ngFor="let business of businesses">
            <div class="single-listing-item">
                <div class="listing-image">
                    <a [routerLink]="'/listing-details/' + business.profile.id" class="d-block ">
                        <img [src]="business.profile.profileImage?.originalUrl ?? 'assets/img/listing/img5.jpg'"
                            alt="image">
                    </a>

                    <div class="listing-tag">
                        <div class="d-block">{{business.category.categoryName}}</div>
                    </div>

                    <div class="listing-rating">
                        <div class="review-stars-rated">
                            <div [innerHTML]="getReviewStatus(business.reviewStatus)"></div>
                        </div>

                    </div>
                </div>

                <div class="listing-content">
                    <div class="listing-author d-flex align-items-center">
                        <img [src]="business.profile.profileImage?.thumbnailUrl ?? 'assets/img/listing/img5.jpg'"
                            class="rounded-circle mr-2" alt="image">
                        <span>{{business.user.fullName}}</span>
                    </div>

                    <h3>
                        <a [routerLink]="'/listing-details/' + business.profile.id" class="d-inline-block">
                            {{business.profile.name}}
                        </a>
                    </h3>

                    <span class="location"><i class="bx bx-phone"></i>{{business.profile.phoneNumber}}</span>
                    <span class="location"><i class="bx bx-envelope"></i>{{business.profile.emailAddress}}</span>
                    <span *ngIf="business.reviewComments" class="location"><i class='bx bxs-pen'></i>Comments:
                        {{business.reviewComments}}</span>
                </div>

                <div class="listing-edit-delete-option">
                    <div class="d-flex gap-3 align-items-center">
                        <a [routerLink]="'/listing-details/' + business.profile.id" class="btn btn-md btn-primary">
                            Edit Listing
                        </a>
                        <a [routerLink]="'/business-items/' + business.profile.id" class="btn btn-md btn-secondary">
                            Add Products / Services
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="listing-area mb-30">
    <div class="row" *ngIf="businesses$ | async as businesses">
        <div class="col-lg-12 col-sm-12 col-md-12" *ngIf="businesses.length === 0">
            <div class="alert alert-primary">
                <p class="fs-5">You currently have no listings.
                    <a class="alert-link" [routerLink]="'/create-listing'">Create a listing</a>
                    <i class="bx bx-right-arrow-alt"></i>
                </p>
            </div>
        </div>
    </div>
</section>