import { RecommendationService, StateService } from "@app/services";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RecommendationHistoryDto } from "@app/models";

interface ListingStatisticsState {
    recommendations: RecommendationHistoryDto[],
    totalScore: number,
}


const initialState: ListingStatisticsState = {    
    recommendations: [],
    totalScore: 0,
}

@Injectable({
    providedIn: 'root',
  })
export class ListingStatisticsStateService extends StateService<ListingStatisticsState>{    
    recommendations$: Observable<RecommendationHistoryDto[]> = this.select((state)=>{
        return state.recommendations;
    });
    
    totalScore$: Observable<number> = this.select((state)=>{
        return state.totalScore;
    });

    constructor(private recommendationService: RecommendationService){
        super(initialState);        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UTILITY METHODS
    // -----------------------------------------------------------------------------------------------------
    total(){
        this.select(x => x.recommendations).subscribe((response)=>{
            let value = response.reduce((partialSum, a) => partialSum + a.scoreGiven, 0);
            this.setState({totalScore: value});
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ API CALLS
    // -----------------------------------------------------------------------------------------------------
    load(id: string){    
        this.recommendationService.details(id).subscribe((response) =>{
            this.setState({recommendations: response.data});
            this.total();
        });
    }
}