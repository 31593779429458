<div class="coming-soon-area">
    <div class="d-table d-flex justify-content-center align-items-center vh-100">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo">
                    <img src="assets/img/black-logo.png" alt="image">
                </a>
                <h2>YeboKenny is Currently in Maintenance</h2>
                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>Days</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>Hours</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>Minutes</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>Seconds</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>