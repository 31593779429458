import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecommendationDto } from '@app/models';
import { Observable } from 'rxjs';
import { Model } from 'survey-core';
import { RecommendationStateService } from '../../recommendation.state';
import { ApplicationPermissions } from '@app/models/constants/permissions';

@Component({
  selector: 'app-recommendation-detail',
  templateUrl: './recommendation-detail.component.html',
  styleUrls: ['./recommendation-detail.component.scss']
})
export class RecommendationDetailComponent implements OnInit {
  appPermissions = ApplicationPermissions;
  recommendation$: Observable<RecommendationDto> = this._state.recommendation$;
  surveyModel$: Observable<Model> = this._state.surveyModel$;

  constructor(
    private _state: RecommendationStateService,
    public dialogRef: MatDialogRef<RecommendationDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { recommendationId: string }) {
  }

  ngOnInit(): void {
    this._state.recommendation(this.data.recommendationId);
  }
}
