<div class="breadcrumb-area">
    <h1>Invoice</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Dashboard</a></li>

        <li class="item">Invoice</li>
    </ol>
</div>

<div *ngIf="user$ | async as user">
    <div *ngIf="!user.isFreePlan; else free_plan">
        <div class="container" *ngIf="customer$ |async as customer">
            <div class="row mb-2">
                <div class="col-md-12">
                    <div class="fs-4">{{customer.firstName}} {{customer.lastName}}</div>
                    <div class="text-muted"><span class="fw-bold">Customer Email: </span>{{customer.email}} (<span
                            class="fw-bold">Customer
                            Code: </span>{{customer.customerCode}})</div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="card">
                    <div class="card-header">Subscriptions</div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Amount</th>
                                        <th>Next Payment Date</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let subscription of customer.subscriptions">
                                        <td>{{subscription.subscriptionCode | uppercase}}</td>
                                        <td>R {{getRandTotal(subscription.amount)}}</td>
                                        <td>{{subscription.nextPaymentDate |date: 'medium'}}</td>
                                        <td><span class="badge badge-primary">{{subscription.status | titlecase}}</span>
                                        </td>
                                        <td><button type="button" class="btn btn-outline-info"
                                                (click)="generateLink(subscription.subscriptionCode)">Manage
                                                Subscription</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="card recent-orders-box mb-30" *ngIf="invoices$ | async as invoices">
    <div class="card-header d-flex justify-content-between align-items-center">
        <h3>Your Invoices</h3>
    </div>

    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Reference</th>
                        <th>Customer</th>
                        <th>Purchase On</th>
                        <th>Amount</th>
                        <th>Interval</th>
                        <th>Paid By</th>
                        <th>Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let invoice of invoices;" (click)="tableLineClicked(invoice)">
                        <td>{{invoice.reference | uppercase}}</td>
                        <td class="name">{{invoice.customerName}}</td>
                        <td>{{invoice.paidAt |date: 'medium'}}</td>
                        <td>R {{getRandTotal(invoice.requestedAmount)}}</td>
                        <td>{{invoice.planInterval}}</td>
                        <td>{{invoice.channel}}</td>
                        <td><span class="badge badge-primary">{{invoice.gatewayResponse}}</span></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<ng-template #free_plan>
    <div class="container">
        <div class="row">
            <div class="alert alert-primary" role="alert">
                <h4 class="alert-heading">You are currently on the free plan.</h4>
                <p>Invoices and billing section will become available again when you have subscribed to a YeboKenny Plan
                </p>
                <hr>
                <a [routerLink]="'/pricing'" class="btn btn-primary">Get started now!</a>
            </div>
        </div>
    </div>
</ng-template>