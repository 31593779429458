<div class="breadcrumb-area">
    <h1>Product Listings</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="item">Product Listings</li>
    </ol>
</div>

<section class="listing-area mb-30">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12"
            *appPermissions="appPermissions.ADD_BUSINESS_ITEM; showErrorCard:true">
            <app-download-mobile></app-download-mobile>
        </div>
    </div>
</section>