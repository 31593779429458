import { Component, Inject, OnInit } from '@angular/core';

import { FeedbackDto } from '@app/models';
import { FeedbackStateService } from './feedback.state';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  displayedColumns: string[] = ['comment', 'date', 'actions'];

  feedback$: Observable<FeedbackDto[]> = this._state.feedback$;

  constructor(public dialogRef: MatDialogRef<FeedbackComponent>,
     private _state: FeedbackStateService,
     @Inject(MAT_DIALOG_DATA) public data: {businessProfileId: string},){}
  
  ngOnInit(): void {
    this._state.feedback(this.data.businessProfileId);
  }

  toggleVisibility(feedbackId: string, show: boolean){
    this._state.toggle(feedbackId, show, ()=>{
      this._state.feedback(this.data.businessProfileId);
    });
  }
}
