import { Component, Input, OnInit } from '@angular/core';

import ApexCharts from 'apexcharts';
import { DashboardDto } from '@app/models';

@Component({
  selector: 'app-web-analytics',
  templateUrl: './web-analytics.component.html',
  styleUrls: ['./web-analytics.component.scss']
})
export class WebAnalyticsComponent implements OnInit {

    @Input() dto: DashboardDto; 
    constructor() {}

    ngOnInit() {
        const groupedRecommendations = this.dto.recommendations.reduce((groups, recommendation) => {
            const businessProfileId = recommendation.businessProfileId;
            if (!groups.hasOwnProperty(businessProfileId)) {
              groups[businessProfileId] = [];
            }
            groups[businessProfileId].push(recommendation);
            return groups;
          }, {});
          
        // Get the current month and year
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 since months are zero-based
        const currentYear = currentDate.getFullYear();

        // Filter the page tracks and recommendations for the current month
        const filteredPageTracks = this.dto.pageTracks.filter(pageTrack => {
        const trackDate = new Date(pageTrack.viewed);
        return trackDate.getMonth() + 1 === currentMonth && trackDate.getFullYear() === currentYear;
        });

        const filteredRecommendations = this.dto.recommendations.filter(recommendation => {
        const recommendationDate = new Date(recommendation.creationDate);
        return recommendationDate.getMonth() + 1 === currentMonth && recommendationDate.getFullYear() === currentYear;
        });

        // Grouping the filtered page tracks by date
        const groupedPageTracks = filteredPageTracks.reduce((groups, pageTrack) => {
        const date = new Date(pageTrack.viewed).toLocaleDateString();
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(pageTrack);
        return groups;
        }, {});

        // Generating the chart data
        const dates = Object.keys(groupedPageTracks);
        const viewsData = dates.map(date => groupedPageTracks[date].length);
        const recommendationsData = dates.map(date => {
        const group = groupedRecommendations[date];
        return group ? group.length : 0;
        });

        const options = {
        chart: {
            height: 500,
            type: 'area',
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#008FFB', '#18D2B7'],
        stroke: {
            curve: 'smooth'
        },
        series: [{
            name: 'Listings Views',
            data: viewsData
        }, {
            name: 'Recommendations',
            data: recommendationsData
        }],
        xaxis: {
            categories: dates,
        },
        tooltip: {
            x: {
            format: 'dd/MM/yy'
            },
        },
        title: {
            text: `${currentDate.toLocaleString('default', { month: 'long' })} ${currentYear}`,
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#333'
            }
        },
        };

        const chart = new ApexCharts(
        document.querySelector("#web-analytics-chart"),
        options
        );
        chart.render();
          
    }

}