import { BusinessDetailsDto, RecommendationDto, RecommendationHistoryDto } from "@app/models";
import { RecommendationService, StateService, UserService } from "@app/services";

import { Injectable } from "@angular/core";
import { Model } from "survey-core";
import { Observable } from "rxjs";

interface RecommendationState {
    recommendations: RecommendationHistoryDto[],
    businesses: BusinessDetailsDto[],
    totalScore: number,
    recommendation: RecommendationDto,
    surveyModel: Model,
}


const initialState: RecommendationState = {    
    recommendations: undefined,
    businesses: [],
    totalScore: 0,
    recommendation: undefined,
    surveyModel: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class RecommendationStateService extends StateService<RecommendationState>{    
    recommendations$: Observable<RecommendationHistoryDto[]> = this.select((state)=>{
        return state.recommendations;
    });
    recommendation$: Observable<RecommendationDto> = this.select((state)=>{
        return state.recommendation;
    });
    
    surveyModel$: Observable<Model> = this.select((state)=>{
        return state.surveyModel;
    });
    
    businesses$: Observable<BusinessDetailsDto[]> = this.select((state)=>{
        return state.businesses;
    });
    
    totalScore$: Observable<number> = this.select((state)=>{
        return state.totalScore;
    });

    constructor(private recommendationService: RecommendationService, 
        private userService: UserService){
        super(initialState);        
    }

    // -----------------------------------------------------------------------------------------------------
    // @ UTILITY METHODS
    // -----------------------------------------------------------------------------------------------------
    total(){
        this.select(x => x.recommendations).subscribe((response)=>{
            let value = response.reduce((partialSum, a) => partialSum + a.scoreGiven, 0);
            this.setState({totalScore: value});
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ API CALLS
    // -----------------------------------------------------------------------------------------------------
    recommendations(businessId: string){
        this.setState({recommendations: undefined});
        this.recommendationService.details(businessId).subscribe((response) =>{
            if(response.data){
                this.setState({recommendations: response.data});
                this.total();
            }
        });
    }

    businesses(){
        this.userService.details().subscribe(response =>{
            this.setState({businesses: response.data});
            if(this.state.businesses[0]){
                this.recommendations(this.state.businesses[0].profile.id);
            }        
        });
    }

    recommendation(recommendationId: string){
        this.recommendationService.get(recommendationId).subscribe(response =>{
            const surveyModel = new Model(response.data.surveyData.survey.json);
            surveyModel.data = JSON.parse(response.data.surveyData.data);
            surveyModel.mode = 'display';
            this.setState({recommendation: response.data, surveyModel: surveyModel}); 
        });
    }
}