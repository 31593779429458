import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function numbersOnlyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value: string = control.value;

        if (!value) {
            return null; // If the control value is empty, consider it valid
        }

        // Use a regular expression to check if the value contains only numbers
        if (!/^[0-9]+$/.test(value)) {
            return { numbersOnly: true }; // Validation failed
        }

        return null; // Validation passed
    };
}
