import {
  AlterBusinessHourRequest,
  AlterMultipleBusinessHourRequest,
  ApiResponse,
  BusinessDetailResponse,
  BusinessHourResponse,
  BusinessImageRequest,
  BusinessItemDto,
  BusinessResponse,
  ConfirmationResponse,
  CreateAmenityRequest,
  CreateBusinessRequest,
  DeleteAmenityRequest,
  UpdateBusinessDetailsRequest
} from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  create(request: CreateBusinessRequest): Observable<BusinessResponse> {
    return this.http.post<BusinessResponse>(Keys.API_URL + 'api/Business/Create', request);
  }

  profileImage(request: BusinessImageRequest): Observable<BusinessResponse> {
    var formData = new FormData();
    formData.append('businessProfileId', request.businessProfileId);
    formData.append('file', request.file);
    return this.http.patch<BusinessResponse>(Keys.API_URL + 'api/Business/ProfileImage', formData);
  }

  headerImage(request: BusinessImageRequest): Observable<BusinessResponse> {
    var formData = new FormData();
    formData.append('businessProfileId', request.businessProfileId);
    formData.append('file', request.file);
    return this.http.patch<BusinessResponse>(Keys.API_URL + 'api/Business/HeaderImage', formData);
  }

  galleryImage(request: BusinessImageRequest): Observable<BusinessResponse> {
    var formData = new FormData();
    formData.append('businessProfileId', request.businessProfileId);
    formData.append('file', request.file);
    return this.http.post<BusinessResponse>(Keys.API_URL + 'api/Business/Gallery', formData);
  }

  removeGalleryImage(request: {
    businessId: string,
    imageId: string,
  }): Observable<ConfirmationResponse> {
    return this.http.get<ConfirmationResponse>(Keys.API_URL + `api/Business/RemoveGalleryItem/${request.businessId}/${request.imageId}`);
  }

  details(businessId: string): Observable<BusinessDetailResponse> {
    return this.http.get<BusinessDetailResponse>(Keys.API_URL + 'api/Business/Details/' + businessId);
  }

  update(request: UpdateBusinessDetailsRequest): Observable<ConfirmationResponse> {
    return this.http.patch<ConfirmationResponse>(Keys.API_URL + 'api/Business/Update', request);
  }

  alterBusinessHours(request: AlterBusinessHourRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(Keys.API_URL + 'api/BusinessHour/', request);
  }

  alterMultipleBusinessHours(request: AlterMultipleBusinessHourRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(Keys.API_URL + 'api/BusinessHour/Multiple', request);
  }

  getBusinessHours(businessId: string): Observable<BusinessHourResponse> {
    return this.http.get<BusinessHourResponse>(Keys.API_URL + 'api/BusinessHour/' + businessId);
  }

  deleteAllBusinessHours(businessId: string): Observable<ConfirmationResponse> {
    return this.http.delete<ConfirmationResponse>(Keys.API_URL + 'api/BusinessHour/' + businessId);
  }

  createAmenity(request: CreateAmenityRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(Keys.API_URL + 'api/Business/CreateAmenity', request);
  }

  deleteAmenity(request: DeleteAmenityRequest): Observable<ConfirmationResponse> {
    return this.http.patch<ConfirmationResponse>(Keys.API_URL + 'api/Business/DeleteAmenity', request);
  }

  //items 
  businessItems(businessId: string): Observable<ApiResponse<BusinessItemDto[]>> {
    return this.http.get<ApiResponse<BusinessItemDto[]>>(Keys.API_URL + 'api/BusinessItem/List?businessId=' + businessId);
  }
}