<section class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0 d-none d-lg-block">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image">
            </div>
        </div>

        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content d-flex align-items-center justify-content-center">
                <div class="d-table custom-card">
                    <div class="d-table-cell">
                        <div class="login-form">
                            <div class="logo">
                                <img src="assets/img/logo.png" alt="image" style="height: 250px;">
                            </div>
                            <!-- Title -->
                            <div class="mt-5 text-4xl font-extrabold tracking-tight new-to-yk text-center">You have been
                                signed
                                out.
                            </div>
                            <div class="d-flex justify-content-center mt-0.5 font-medium new-to-yk">
                                <!-- Redirect countdown -->
                                <ng-container *ngIf="countdown > 0">
                                    Redirecting in <span class="fw-bold ps-1">{{countdown | i18nPlural: countdownMapping
                                        }}</span>
                                </ng-container>

                                <!-- Redirect message -->
                                <ng-container *ngIf="countdown === 0" class="fw-bold mt-2">
                                    You are now being redirected!
                                </ng-container>
                            </div>

                            <!-- Form footer -->
                            <div class="mt-3 text-md font-medium new-to-yk text-center">
                                <span class="me-1">Or</span>
                                <a href="/login" class="ml-1 text-primary-500 text-decoration-none">Sign in</a>
                                <span class="ms-1">Now</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>