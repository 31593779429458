import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

export function osmAddressValidator(http: HttpClient): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
        const address = control.value;
        const encodedAddress = encodeURIComponent(address);

        const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`;

        return timer(500) // Debounce time of 300ms
            .pipe(
                switchMap(() =>
                    http.get(apiUrl).pipe(
                        switchMap((response: any[]) => {
                            if (response.length > 0) {
                                // Address found, validation succeeds
                                return of(null);
                            } else {
                                // Address not found, validation fails
                                return of({ 'invalidAddress': true });
                            }
                        }),
                        catchError(() => of(null)) // Handle HTTP errors gracefully
                    )
                )
            );
    };
}
