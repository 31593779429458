<app-home-header></app-home-header>
<section class="login-area d-flex align-items-center justify-content-center mx-auto">
    <div class="login-form-container bg-white rounded px-3 py-3">

        <h2 class="text-center mb-2">Set new password</h2>
        <p class="text-center mb-1">We are happy to have you back. Please input your new password</p>


        <form [formGroup]="signInForm" #signInNgForm="ngForm" class="login-form">

            <div class="form-group mb-3">
                <label>New password</label>
                <input id="password" type="password"
                    [formControlName]="'newPassword'" placeholder="Your Password"
                    class="form-control input" #passwordField>
            </div>

            <div class="form-group mb-3">
                <label>Confirm password</label>
                <input type="password" id="conpassword"
                    [formControlName]="'connewPassword'" placeholder="Confirm new password"
                    class="form-control input" #conpasswordField>
            </div>

            <button 
                type="button" 
                class="btn btn-primary w-100 mb-3" 
                style="background:#008B39; outline:none!important;height:50px;border-radius:32px;border:none"
                [disabled]="signInForm.disabled" 
                (click)="submit()"
            >Reset password
            <mat-progress-spinner *ngIf="signInForm.disabled" [diameter]="24"
                [mode]="'indeterminate'">
            </mat-progress-spinner>
            </button>

        </form>
    </div>
</section>
<style>
    .login-area{
        min-height: 100vh;
        background-color: #F9F9F9;
    }

    @media (min-width: 992px) {
        .login-form-container {
            width: 35%;
        }
        }

        @media (max-width: 991px) and (min-width: 768px) {
        .login-form-container {
            width: 70%;
        }
        }

        @media (max-width: 767px) {
        .login-form-container{
            width: 90%;
        }
        }
    input.input{
        border: 1px solid #D0D5DD!important;
        background-color: white!important;
        box-shadow:none!important;
        outline: none!important; 
        height: 50px;
        width: 100%;
        border-radius: 8px;
        padding-inline: 10px;
    }
</style>