import { AfterViewInit, Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessDetailsDto } from '@app/models';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-download-qr',
  templateUrl: './download-qr.component.html',
  styleUrls: ['./download-qr.component.scss']
})
export class DownloadQrComponent implements AfterViewInit {

  /**
   *
   */
  constructor(
    private _elementRef: ElementRef,
    public dialogRef: MatDialogRef<DownloadQrComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      {
        qrCode: string,
        businessName: string,
      }) {
  }
  ngAfterViewInit(): void {
    this.createImage();
  }



  createImage() {
    const imageTemplate = this._elementRef.nativeElement.querySelector('.qr-code');
    const tempContainer = document.createElement('div');
    tempContainer.appendChild(imageTemplate.cloneNode(true));

    document.body.appendChild(tempContainer); // Append to the document body

    const scale = 2; // Adjust the scale factor as needed
    const width = tempContainer.offsetWidth * scale;
    const height = tempContainer.offsetHeight * scale;

    const containerStyles = `
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${height}px; // Adjust the height to fit the desired space
      margin: 0;
      background: linear-gradient(to bottom, #e6f1ff, #b3d1ff);
      font-family: Arial, sans-serif;
    `;

    const qrCodeStyles = `
      width: 100%; // Ensure the QR code takes up the full width of the container
    `;

    const businessNameStyles = `
      width: 100%; // Ensure the business name takes up the full width of the container
    `;

    const recommendationStyles = `
      width: 100%; // Ensure the recommendation text takes up the full width of the container
    `;

    tempContainer.setAttribute('style', containerStyles);
    tempContainer.querySelector('.qr-code img').setAttribute('style', qrCodeStyles);
    tempContainer.querySelector('.business-name').setAttribute('style', businessNameStyles);
    tempContainer.querySelector('.recommendation').setAttribute('style', recommendationStyles);

    html2canvas(tempContainer, { width: width / 2, height: height }).then((canvas) => {
      document.body.removeChild(tempContainer); // Remove from the document body

      const imageData = canvas.toDataURL('image/png');
      this.downloadImage(imageData, 'image_template.png');
    });
  }

  downloadImage(imageData: string, fileName: string) {
    const link = document.createElement('a');
    link.href = imageData;
    link.download = fileName;
    link.click();
  }
}
