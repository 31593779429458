import { RouterModule, Routes } from '@angular/router';

import { AnonymousLayoutComponent } from './components/layouts/anonymous-layout.component';
import { AppCalendarComponent } from './components/pages/app-calendar/app-calendar.component';
import { AppEmailComponent } from './components/pages/app-email/app-email.component';
import { AppEmailComposeComponent } from './components/pages/app-email-compose/app-email-compose.component';
import { AppEmailReadComponent } from './components/pages/app-email-read/app-email-read.component';
import { AppMessageComponent } from './components/pages/app-message/app-message.component';
import { AppTodoComponent } from './components/pages/app-todo/app-todo.component';
import { AuthGuard } from './helpers/auth.guard';
import { AuthenticatedLayoutComponent } from './components/layouts/authenticated-layout.component';
import { AuthorInformationComponent } from './components/pages/author-information/author-information.component';
import { BookingsComponent } from './components/pages/bookings/bookings.component';
import { BookmarksComponent } from './components/pages/bookmarks/bookmarks.component';
import { ConfirmPasswordComponent } from './components/pages/confirm-password/confirm-password.component';
import { CreateListingComponent } from './components/pages/create-listing/create-listing.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { InitialDataResolver } from './app.resolvers';
import { InvoiceComponent } from './components/pages/invoice/invoice.component';
import { ListingDetailComponent } from './components/pages/listing-detail/listing-detail.component';
import { LoginComponent } from './components/pages/login/login.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { MyListingComponent } from './components/pages/my-listing/my-listing.component';
import { MyProfileComponent } from './components/pages/my-profile/my-profile.component';
import { NgModule } from '@angular/core';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ProductListingComponent } from './components/pages/product-listing/product-listing.component';
import { RecommendationComponent } from './components/pages/recommendation/recommendation.component';
import { RegisterComponent } from './components/pages/register/register.component';
import { SupportComponent } from './components/pages/support/support.component';
import { ViewTicketComponent } from './components/pages/support/view-ticket/view-ticket.component';
import { httpToHttpsRedirectGuard } from './guards/http-to-https-redirect.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [httpToHttpsRedirectGuard, AuthGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      { path: 'app-message', component: AppMessageComponent },
      { path: 'app-email', component: AppEmailComponent },
      { path: 'app-email-read', component: AppEmailReadComponent },
      { path: 'app-email-compose', component: AppEmailComposeComponent },
      { path: 'app-todo', component: AppTodoComponent },
      { path: 'app-calendar', component: AppCalendarComponent },
      { path: 'my-profile', component: MyProfileComponent },
      { path: 'invoice', component: InvoiceComponent },
      { path: 'recommendations', component: RecommendationComponent },
      { path: 'my-listing', component: MyListingComponent },
      { path: 'product-listings', component: ProductListingComponent },
      { path: 'support', component: SupportComponent },
      { path: 'support/:id', component: ViewTicketComponent },
      { path: 'listing-details/:id', component: ListingDetailComponent },
      { path: 'bookmarks', component: BookmarksComponent },
      { path: 'bookings', component: BookingsComponent },
      { path: 'create-listing', component: CreateListingComponent },
      { path: 'pricing', component: PricingComponent },
      { path: 'author-information', component: AuthorInformationComponent },
    ]
  },
  {
    path: '',
    component: AnonymousLayoutComponent,
    canActivate: [httpToHttpsRedirectGuard],
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'sign-out', component: LogoutComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'confirm-password', component: ConfirmPasswordComponent },
      { path: 'register', component: RegisterComponent },
    ]
  },
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  providers: [InitialDataResolver],
  exports: [RouterModule]
})
export class AppRoutingModule { }