import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoadingService } from '@app/services/loading.service';
import { finalize } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if(request.method === 'POST'){
        this.totalRequests++;
        this.loadingService.setLoading(true);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if(request.method === 'POST'){
            this.totalRequests--;
            if (this.totalRequests === 0) {
                this.loadingService.setLoading(false);
            }
        }
      })
    );
  }
}