import { AuthService, UserService } from '@app/services';
import { Component, OnInit } from '@angular/core';

import { MenuService } from '@app/services/menu.service';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { UserDto } from '@app/models';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    user: UserDto;
    constructor(private _authService: AuthService,
        private _userService: UserService,
        private socialAuthService: SocialAuthService,
        public menuService: MenuService,
        private _router: Router) { }

    ngOnInit(): void {
        this._userService.user$.subscribe(user => {
            this.user = user;
        })
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

    classApplied4 = false;
    toggleClass4() {
        this.classApplied4 = !this.classApplied4;
    }

    classApplied5 = false;
    toggleClass5() {
        this.classApplied5 = !this.classApplied5;
    }

    signout() {
        this.socialAuthService.signOut();
        this._authService.signOut().subscribe(x => {
            this._router.navigate(['login']);
        });
    }

    toggleResponsiveMenu() {
        this.menuService.toggleResponsiveMenu();
    }
}