import { Amenity, BusinessDetailsDto, CategoryDto, CreateAmenityRequest, DeleteAmenityRequest, UpdateBusinessDetailsRequest } from "@app/models";
import { BusinessService, CategoryService, LoadingService, StateService } from "@app/services";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Swal from 'sweetalert2/dist/sweetalert2.js'

interface ListingDetailState {
  listing: BusinessDetailsDto,
  categories: CategoryDto[],
  subcategories: CategoryDto[],
  selectedCategory: CategoryDto,
  id: string,
}


const initialState: ListingDetailState = {
  listing: undefined,
  categories: [],
  subcategories: [],
  selectedCategory: undefined,
  id: '',
}

@Injectable({
  providedIn: 'root',
})
export class ListingDetailStateService extends StateService<ListingDetailState>{

  listing$: Observable<BusinessDetailsDto> = this.select((state) => {
    return state.listing;
  });


  categories$: Observable<CategoryDto[]> = this.select((state) => {
    return state.categories;
  });

  subcategories$: Observable<CategoryDto[]> = this.select((state) => {
    return state.subcategories;
  });


  selectedCategory$: Observable<CategoryDto> = this.select((state) => {
    return state.selectedCategory;
  });

  constructor(private businessService: BusinessService,
    private _loadingService: LoadingService,
    private categoryService: CategoryService) {
    super(initialState);
  }



  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------


  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load(id: string) {
    this.setState({ id: id });
    this.categoryService.getTopLevelCategories().subscribe((response) => {
      this.setState({ categories: response.data });
      this.businessService.details(id).subscribe((listing) => {
        this.setState({ listing: listing.data, selectedCategory: response.data.filter(x => listing.data.category.id === x.id)[0] });
        this.subCategories();
      });
    })
  }

  update(request: UpdateBusinessDetailsRequest) {
    this.businessService.update(request).subscribe(response => {
      if (response.data.isSuccess) {
        Swal.fire({
          title: 'Listing Updated',
          text: `Your listing has been successfully updated`,
          icon: 'success',
        })
        this.businessService.details(request.id).subscribe((listing) => {
          this.setState({ listing: listing.data, });
        });
      }
    })
  }

  createAmenity(amenity: string) {
    this.businessService.createAmenity(<CreateAmenityRequest>{
      amenity: amenity,
      businessProfileId: this.state.id,
    }).subscribe(
      () => { this.load(this.state.id); }
    );
  }

  removeAmenity(amenity: Amenity) {
    this.businessService.deleteAmenity(<DeleteAmenityRequest>{
      businessAmenityId: amenity.id,
      businessProfileId: this.state.id,
    }).subscribe();
  }

  delete(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you want to delete this listing. All data in and around this business will be removed.`,
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Yes Delete'
    }).then(value => {
      if (value.isConfirmed) {
        Swal.fire({
          title: 'Listing Deleted',
          text: `Your listing has been successfully deleted`,
          icon: 'success',
        });
      }
    });
  }

  deleteGalleryImage(imageId: string) {
    this._loadingService.setLoading(true);
    this.businessService.removeGalleryImage({
      imageId: imageId,
      businessId: this.state.id,
    }).subscribe(res => {
      this._loadingService.setLoading(false);
      if (res.data) {
        this.alertUI(res.data.messsage, 'success');
        this.load(this.state.id);
      } else {
        this.alertUI(res.message, 'error');
      }
    });
  }

  subCategories() {
    this.select(state => state.selectedCategory).subscribe(response => {
      this.categoryService.getSubCategories(response.id).subscribe((response) => {
        this.setState({ subcategories: response.data });
      })
    });
  }
}