import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { SupportStateService } from '../support.state';
import { CreateSupportTicketRequest } from '@app/models/requests/create-support-ticket.request';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss']
})
export class CreateTicketComponent implements OnInit {
  form: UntypedFormGroup;

  quillModules: any = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean'],                                         // remove formatting button

      ['link',]                         // link and image, video
    ]
  };

  constructor(
    private _dialogRef: MatDialogRef<CreateTicketComponent>,
    private _formBuilder: UntypedFormBuilder,
    private _state: SupportStateService,
  ) {
  }

  ngOnInit(): void {
    this._buildForm();
  }

  _buildForm() {
    this.form = this._formBuilder.group({
      issue: [null, [Validators.required, vulgarLanguageValidator()]],
    });
  }

  submit() {
    if (this.form.invalid) {
      this._state.alertUI('Form is invalid', 'warning');
    } else {
      var request = <CreateSupportTicketRequest>{
        issueDescription: this.form.get('issue').value,
      };
      this._state.createTicket(request, () => {
        this._dialogRef.close();
      });
    }
  }
}
