import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private isMenuHiddenSubject = new BehaviorSubject<boolean>(false);
    private isMenuToggledSubject = new BehaviorSubject<boolean>(false);
    private isResponsiveMenuActiveSubject = new BehaviorSubject<boolean>(false);

    isMenuHidden$: Observable<boolean> = this.isMenuHiddenSubject.asObservable();
    isMenuToggled$: Observable<boolean> = this.isMenuToggledSubject.asObservable();
    isResponsiveMenuActive$: Observable<boolean> = this.isResponsiveMenuActiveSubject.asObservable();

    toggleMenu() {
        const isHidden = !this.isMenuHiddenSubject.value;
        const isToggled = !this.isMenuToggledSubject.value;
        this.isMenuHiddenSubject.next(isHidden);
        this.isMenuToggledSubject.next(isToggled);
    }

    toggleResponsiveMenu() {
        const isActive = !this.isResponsiveMenuActiveSubject.value;
        const isToggled = !this.isMenuToggledSubject.value;
        this.isResponsiveMenuActiveSubject.next(isActive);
        this.isMenuToggledSubject.next(isToggled);
    }
}
