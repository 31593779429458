import {
  FeaturesResponse,
  HasFeatureResponse,
  PlanResponse
} from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  planURL = Keys.API_URL + 'api/Plan';
  featureURL = Keys.API_URL + 'api/Feature';

  getPlans(): Observable<PlanResponse> {
    return this.http.get<PlanResponse>(this.planURL + '/Plans');
  }

  getFeatures(): Observable<FeaturesResponse> {
    return this.http.get<FeaturesResponse>(this.featureURL + '/Features');
  }

  hasFeature(id: string): Observable<HasFeatureResponse> {
    return this.http.get<HasFeatureResponse>(this.featureURL + '/HasFeature/' + id);
  }
}