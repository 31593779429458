import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateAuthorInfoRequest, UpdateAuthorInfoRequest } from '@app/models';
import Swal from 'sweetalert2';
import { AuthorInfoStateService } from '../../author-information.state';
import { CreateAuthorInformationComponent } from '../create-author-information/create-author-information.component';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-update-author-information',
  templateUrl: './update-author-information.component.html',
  styleUrls: ['./update-author-information.component.scss']
})
export class UpdateAuthorInformationComponent {

  form: UntypedFormGroup;
  apikey = 'uguxeiycnpetg4pywx3u0a8u7xo2uunlw60jhyewpau929s7';

  constructor(private _formBuilder: UntypedFormBuilder,
    private _state: AuthorInfoStateService,
    private _dialogRef: MatDialogRef<UpdateAuthorInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { authorInformationId: string }) {

  }


  ngOnInit(): void {
    this._buildForm();
  }

  _buildForm() {
    this._state.authorInfo$.subscribe(author => {
      this.form = this._formBuilder.group({
        displayName: [author.displayName, [Validators.required, vulgarLanguageValidator()]],
        jobTitle: [author.jobTitle, [Validators.required, vulgarLanguageValidator()]],
        hobbies: [author.hobbies, [Validators.required, vulgarLanguageValidator()]],
        description: [author.description, [Validators.required, vulgarLanguageValidator(), Validators.minLength(20)]],
      });
    })
  }

  submit() {
    if (this.form.invalid) {
      Swal.fire({
        title: 'You have an error',
        text: 'Check all your entries and make sure everything is valid.',
        icon: 'error',
      });
      this.form.markAllAsTouched();
      return;
    } else {
      this._state.updateInfo(this._buildRequest(), (response) => {
        Swal.fire({
          title: 'Updated',
          text: response.messsage,
          icon: 'success',
        }).then((res: any) => {
          this._dialogRef.close(true);
        });
      }, (err) => {
        Swal.fire({
          title: 'Something went wrong',
          text: err,
          icon: 'error',
        });
      })
    }
  }

  private _buildRequest(): UpdateAuthorInfoRequest {
    return <UpdateAuthorInfoRequest>{
      authorInformationId: this.data.authorInformationId,
      displayName: this.form.controls.displayName.value,
      jobTitle: this.form.controls.jobTitle.value,
      hobbies: this.form.controls.hobbies.value,
      description: this.form.controls.description.value,
    }
  }
}
