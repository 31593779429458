import { AnalyticsService } from "@app/services/analytics.service";
import { DashboardDto } from "@app/models";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "@app/services";

interface DashboardState {
    analytics: DashboardDto,
}

const initialState: DashboardState = {
    analytics: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class DashboardStateService extends StateService<DashboardState>{
    analytics$: Observable<DashboardDto> = this.select((state)=>{    
        return state.analytics;
    });
    constructor(private _analyticsService: AnalyticsService){
        super(initialState);
    }
// ------------------------------------------------------------------------------------------
// @ API CALLS
// ------------------------------------------------------------------------------------------
	load(){
        this.analytics();
	}

	analytics(){
		this._analyticsService.analytics().subscribe((response) =>{
			this.setState({analytics: response.data});      
		})   
	}
}