import { CustomerInfoDto, CustomerTotalsDto, InvoiceDto, TransactionInfoDto } from "@app/models";
import { LoadingService, StateService, SubscribeService, } from "@app/services";

import { Injectable } from "@angular/core";
import { InvoiceService } from "@app/services/invoice.service";
import { Observable } from "rxjs";
import Swal from 'sweetalert2/dist/sweetalert2.js';

interface InvoiceState {
    invoices: InvoiceDto[],
    customer: CustomerInfoDto,
    totals: CustomerTotalsDto,
    transaction: TransactionInfoDto,
}

const initialState: InvoiceState = {
    invoices: [],
    customer: undefined,
    totals: undefined,
    transaction: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class InvoiceStateService extends StateService<InvoiceState>{
      
    invoices$: Observable<InvoiceDto[]> = this.select((state)=>{
        return state.invoices;
    });

    customer$: Observable<CustomerInfoDto> = this.select((state) =>{
        return state.customer;
    });

    totals$: Observable<CustomerTotalsDto> = this.select((state) =>{
        return state.totals;
    });

    transaction$: Observable<TransactionInfoDto> = this.select((state) =>{
        return state.transaction;
    });
      
    
    constructor(
        private _invoiceService: InvoiceService,
        private _subscriptionService: SubscribeService,
        private _loadingService: LoadingService){
        super(initialState);
    }
// ------------------------------------------------------------------------------------------
// @ API CALLS
// ------------------------------------------------------------------------------------------
	load(){
		this.invoices();
	}

	invoices(){
        this._loadingService.setLoading(true);
		this._invoiceService.get().subscribe((response) =>{
            this._loadingService.setLoading(false);
			this.setState({invoices: response.data});
            if(this.state.invoices.length > 0){
                this.customer();
                // this.totals();
            }
		})   
	}

    customer(){
        this._invoiceService.customerInfo().subscribe((response)=>{
            this.setState({customer: response.data});
            this._loadingService.setLoading(false);
        });
    }

    totals(){
        this._invoiceService.customerTotals().subscribe((response)=>{
            this.setState({totals: response.data});
        });
    }

    transaction(id: number){
        this._invoiceService.transactionInfo(id).subscribe((response)=>{
            this.setState({transaction: response.data});
        });
    }

    reset(){
        this.setState({transaction: undefined});
    }

    generatelink(code: string){
        this._loadingService.setLoading(true);
        this._subscriptionService.generateLink(code).subscribe(response =>{
            this._loadingService.setLoading(false);
            if(!response.data){
                Swal.fire({
                    title: 'Something went wrong',
                    text: response.message,
                    icon: 'error',
                  });
            }else{
                Swal.fire({
					title:'You are about to be redirected',
					text: 'This will allow you to manage your subscription',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText:'Lets Go'
				}).then(res =>{
					if(res.isConfirmed){
						window.open(response.data.link, '_blank');
					}
				});
            }
        });
    }
}