import { Amenity, BusinessDetailsDto, CategoryDto } from '@app/models';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MapOptions, circle, latLng, tileLayer } from 'leaflet';
import { Observable, debounceTime, switchMap } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { AlterBusinessHoursComponent } from './components/alter-business-hours/alter-business-hours.component';
import { ApplicationPermissions } from '@app/models/constants/permissions';
import { BusinessService } from '@app/services';
import { DownloadQrComponent } from './components/download-qr/download-qr.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { HttpClient } from '@angular/common/http';
import { ImagePickerConf } from '@app/components/shared/image-picker/models/index.models';
import { ListingDetailStateService } from './listing-detail.state';
import { ListingStatisticsComponent } from './components/listing-statistics/listing-statistics.component';
import { MatDialog } from '@angular/material/dialog';
import { numbersOnlyValidator } from '@app/components/shared/validators/numbers-only-validator';
import { osmAddressValidator } from '@app/components/shared/validators/open-street-validator';
import { urlValidator } from '@app/components/shared/validators/url-validator';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './listing-detail.component.html',
  styleUrls: ['./listing-detail.component.scss']
})
export class ListingDetailComponent implements OnInit {
  appPermissions = ApplicationPermissions;
  form: UntypedFormGroup;
  amenitiesForm: UntypedFormGroup;
  imageFormGroup: UntypedFormGroup;
  private id: string = '';
  apikey = 'uguxeiycnpetg4pywx3u0a8u7xo2uunlw60jhyewpau929s7';
  business$: Observable<BusinessDetailsDto> = this.state.listing$;
  categories$: Observable<CategoryDto[]> = this.state.categories$;
  subcategories$: Observable<CategoryDto[]> = this.state.subcategories$;
  selectedCategory$: Observable<CategoryDto> = this.state.selectedCategory$;
  imagePickerConf: ImagePickerConf = {
    borderRadius: '8px',
    language: 'en',
    width: '300px',
    objectFit: 'contain',
    aspectRatio: 4 / 3,
    compressInitial: null,
    hideAddBtn: false,
    hideDownloadBtn: true,
    hideDeleteBtn: true,
  };

  galleryAddPickerConfig: ImagePickerConf = {
    borderRadius: '8px',
    language: 'en',
    width: '300px',
    objectFit: 'contain',
    aspectRatio: 4 / 3,
    compressInitial: null,
    hideAddBtn: false,
    hideDownloadBtn: true,
    hideDeleteBtn: true,
    clearImageOnAdd: true,
  };

  galleryimagePickerConf: ImagePickerConf = {
    borderRadius: '8px',
    language: 'en',
    width: '300px',
    objectFit: 'contain',
    aspectRatio: 4 / 3,
    hideAddBtn: true,
    hideDownloadBtn: true,
    hideEditBtn: true,
    compressInitial: null,
  };

  latitude: number;
  longitude: number;

  options: MapOptions;

  constructor(
    private activeRoute: ActivatedRoute,
    private state: ListingDetailStateService,
    private dialog: MatDialog,
    private _formBuilder: UntypedFormBuilder,
    private _businessService: BusinessService,
    private _elementRef: ElementRef,
    private _cdRef: ChangeDetectorRef,
    private _http: HttpClient,
  ) { }

  ngOnInit(): void {
    this._loadState();
    this._buildForm();
  }

  _loadState() {
    this.activeRoute.params.subscribe((res) => {
      this.id = res['id'].toLowerCase().replace(' ', '').trim();
      this.state.load(this.id);
    });
  }

  _buildForm() {
    this.amenitiesForm = this._formBuilder.group({
      amenities: [],
    });
    this.form = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(5), vulgarLanguageValidator()]],
      description: ['', [Validators.required]],
      selectedCategory: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), numbersOnlyValidator(), vulgarLanguageValidator()]],
      emailAddress: ['', [Validators.required, Validators.email, vulgarLanguageValidator()]],
      website: ['', [urlValidator(), vulgarLanguageValidator()]],
      physicalAddress: ['', [Validators.required, vulgarLanguageValidator()], [osmAddressValidator(this._http)]],
    });
    this.business$.subscribe(business => {
      if (business) {
        this.amenitiesForm.controls.amenities.setValue(business.profile.amenities);
        this.form.controls.name.setValue(business.profile.name);
        this.form.controls.description.setValue(business.profile.description);
        this.form.controls.selectedCategory.setValue(business.profile.categoryId);
        this.form.controls.phoneNumber.setValue(business.profile.phoneNumber);
        this.form.controls.emailAddress.setValue(business.profile.emailAddress);
        this.form.controls.website.setValue(business.profile.website);
        this.form.controls.physicalAddress.setValue(business.profile.physicalAddress);
        this.latitude = business.profile.latitude;
        this.longitude = business.profile.longitude;
        this.options = {
          layers: [
            tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
              maxZoom: 18,
              attribution: 'Open Street Map'
            }),
            circle([this.latitude, this.longitude], { radius: 100 }).bindPopup(business.profile.name)
          ],
          zoom: 16,
          center: latLng(this.latitude, this.longitude),
        };
      }
    });

    this.form.get('physicalAddress').valueChanges
      .pipe(
        debounceTime(300), // Adjust debounce time as needed
        switchMap((address: string) => this.fetchGeolocation(address))
      )
      .subscribe((geolocation: any) => {
        if (geolocation && geolocation.length > 0) {
          // Geolocation data is available, use it
          this.latitude = geolocation[0].lat;
          this.longitude = geolocation[0].lon;
          // Do something with latitude and longitude
          this.options = {
            layers: [
              tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: 'Open Street Map'
              }),
              circle([this.latitude, this.longitude], { radius: 100 })
            ],
            zoom: 16,
            center: latLng(this.latitude, this.longitude),
          };
          this._cdRef.detectChanges();
        } else {
          // Handle the case when geolocation data is not available
        }
      });

  }

  fetchGeolocation(address: string) {
    const encodedAddress = encodeURIComponent(address);
    const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodedAddress}&format=json`;

    return this._http.get(apiUrl);
  }
  submit() {
    this.state.update({
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      categoryId: this.form.get('selectedCategory').value,
      phoneNumber: this.form.get('phoneNumber').value,
      emailAddress: this.form.get('emailAddress').value,
      website: this.form.get('website').value,
      physicalAddress: this.form.get('physicalAddress').value,
      id: this.id,
      longitude: this.longitude,
      latitude: this.latitude,
    });
  }


  onAdded(event: any) {
    this.state.createAmenity(event.amenity);
  }

  onRemoved(event: any, amenities: Amenity[]) {
    const firstMatchingAmenity = amenities.find(x => x.id === event.id);
    if (firstMatchingAmenity) {
      this.state.removeAmenity(firstMatchingAmenity);
    }
  }


  onAddGalleryImageChange(event: any) {
    const imageBlob = this.dataURItoBlob(event);
    const file = new File([imageBlob], 'profile-picture.png', { type: 'image/png' });

    this._businessService.galleryImage({
      businessProfileId: this.id,
      file: file,
    }).subscribe(res => {
      this.state.load(this.id);
      if (res.data) {
        this.state.alertUI('Updated Image', 'success');
      } else {
        this.state.alertUI(res.message, 'error');
      }
    })
  }

  onGalleryImageRemoved(imageId: string) {
    this.state.deleteGalleryImage(imageId);
  }

  onProfileChanged(event: any) {
    const imageBlob = this.dataURItoBlob(event);
    const file = new File([imageBlob], 'profile-picture.png', { type: 'image/png' });

    this._businessService.profileImage({
      businessProfileId: this.id,
      file: file,
    }).subscribe(res => {
      if (res.data) {
        this.state.alertUI('Updated Image', 'success');
      } else {
        this.state.alertUI('Something went wrong', 'error');
      }
    })
  }

  onHeaderChanged(event: any) {
    const imageBlob = this.dataURItoBlob(event);
    const file = new File([imageBlob], 'header-picture.png', { type: 'image/png' });
    this._businessService.headerImage({
      businessProfileId: this.id,
      file: file,
    }).subscribe(res => {
      if (res.data) {
        this.state.alertUI('Updated Image', 'success');
      } else {
        this.state.alertUI('Something went wrong', 'error');
      }
    })
  }

  deleteListing() {
    this.state.delete(this.id);
  }

  openListingStatsDialog(id: string) {
    const dialogRef = this.dialog.open(ListingStatisticsComponent, {
      panelClass: 'panel-class',
      data: { businessProfileId: id }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openFeedbackDialog(id: string) {
    const dialogRef = this.dialog.open(FeedbackComponent, {
      panelClass: 'panel-class',
      data: { businessProfileId: id }
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  _openListingHours(id: string) {
    const dialogRef = this.dialog.open(AlterBusinessHoursComponent, {
      panelClass: 'panel-class',
      data: { businessProfileId: id }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openAlterBusinessHourDialog(id: string) {
    this._openListingHours(id);
  }

  createImage(business: BusinessDetailsDto) {
    const dialogRef = this.dialog.open(DownloadQrComponent, {
      panelClass: 'panel-class',
      data: { qrCode: business.qrCode, businessName: business.profile.name }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  dataURItoBlob(dataURI: string) {
    const byteString = window.atob(dataURI.replace('data:image/png;base64,', '').replace('data:image/jpeg;base64,', '').replace('data:image/jpg;base64,', ''));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
}
