<nav class="navbar top-navbar navbar-expand" [ngClass]="{'toggle-navbar-area': menuService.isMenuToggled$ | async}">
    <div class="collapse navbar-collapse" id="navbarSupportContent">
        <div class="responsive-burger-menu d-block d-lg-none" (click)="toggleResponsiveMenu()" [ngClass]="{        
            'active':menuService.isResponsiveMenuActive$ | async
        }">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
        </div>
        <form class="nav-search-form d-none ml-auto d-md-block">

        </form>
        <ul class="navbar-nav right-nav align-items-center">
            <li class="nav-item dropdown profile-nav-item">
                <button class="nav-link dropdown-toggle" role="button" (click)="toggleClass5()">
                    <div class="menu-profile">
                        <!-- <span class="name">Hi! {{user.firstName}}</span> -->
                        <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                    </div>
                </button>
                <div class="dropdown-menu" [class.active]="classApplied5">
                    <div class="dropdown-header d-flex flex-column align-items-center">
                        <div class="figure mb-3">
                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                        </div>
                        <div class="info text-center">
                            <!-- <span class="name">{{user.fullName}}</span> -->
                            <!-- <p class="mb-3 email">{{user.emailAddress}}</p> -->
                        </div>
                    </div>
                    <div class="dropdown-body">
                        <ul class="profile-nav p-0 pt-3">
                            <!-- <li class="nav-item">
                                <a routerLink="/my-profile" class="nav-link">
                                    <i class='bx bx-user'></i> <span>Profile</span>
                                </a>
                            </li> -->
                            <!-- <li class="nav-item">
                                <a routerLink="/message" class="nav-link">
                                    <i class='bx bx-envelope'></i> <span>My Inbox</span>
                                </a>
                            </li> -->
                            <li class="nav-item">
                                <a routerLink="/my-profile" class="nav-link">
                                    <i class='bx bx-edit-alt'></i> <span>Edit Profile</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a routerLink="/my-profile" class="nav-link">
                                    <i class='bx bx-cog'></i> <span>Settings</span>
                                </a>
                            </li> -->
                        </ul>
                    </div>
                    <div class="dropdown-footer">
                        <ul class="profile-nav">
                            <li class="nav-item">
                                <button (click)="signout()" class="nav-link">
                                    <i class='bx bx-log-out'></i> <span>Logout</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>