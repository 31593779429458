<div class="breadcrumb-area">
    <h1>Dashboard</h1>

    <ol class="breadcrumb">
        <li class="item"><a routerLink="/">Dashboard</a></li>
        <li class="item">My Profile</li>
    </ol>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <div class="my-profile-box mb-30">
            <h3>Profile Details</h3>

            <form [formGroup]="form">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" formControlName="firstName">
                            <mat-error
                                *ngIf="form.get('firstName').hasError('required') && form.get('firstName').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('firstName').hasError('isProfane') && form.get('firstName').touched">
                                Please refrane from profanity.
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" formControlName="lastName">
                            <mat-error
                                *ngIf="form.get('lastName').hasError('required') && form.get('lastName').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('lastName').hasError('isProfane') && form.get('lastName').touched">
                                Please refrane from profanity.
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Phone Number</label>
                            <input type="text" class="form-control" formControlName="phoneNumber">
                            <mat-error
                                *ngIf="form.get('phoneNumber').hasError('required') && form.get('phoneNumber').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('phoneNumber').hasError('minlength') && form.get('phoneNumber').touched">
                                Phone number must have a minimum length of 10 digits.
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('phoneNumber').hasError('isProfane') && form.get('phoneNumber').touched">
                                Please refrane from profanity.
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('phoneNumber').hasError('numbersOnly') && form.get('phoneNumber').touched">
                                Phone number can only have numbers.
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <label>What Type of User Are You</label>
                        <select class="form-select" formControlName="userType">
                            <option [value]="93800">INDIVIDUAL</option>
                            <option [value]="49245">BUSINESS</option>
                        </select>
                        <mat-error *ngIf="form.get('userType').hasError('required') && form.get('userType').touched">
                            This field is required.
                        </mat-error>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" formControlName="allowEmailNotifications"
                                id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                Allow email Notifications and reminders. This includes monthly reports, alerts for
                                recommendations and feedback and other primary email notifications.
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <button type="button" (click)="updateProfile()">Update Profile</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="col-lg-6 col-md-12" *ngIf="$user | async as user">
        <div class="my-profile-box mb-30">
            <h3>Change Password</h3>

            <form [formGroup]="passwordForm" *ngIf="user.providerType !== providerGoogle; else passwordManagedByGoogle">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>Current Password</label>
                            <input type="password" class="form-control" formControlName="password">
                            <mat-error
                                *ngIf="passwordForm.get('password').hasError('required') && passwordForm.get('password').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('password').hasError('isProfane') && passwordForm.get('password').touched">
                                Please refrane from profanity.
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <label>New Password</label>
                            <input type="password" class="form-control" formControlName="newPassword">
                            <mat-error
                                *ngIf="passwordForm.get('newPassword').hasError('required') && passwordForm.get('newPassword').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('newPassword').hasError('minLength') && passwordForm.get('newPassword').touched">
                                Password must be at least 8 characters long.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('newPassword').hasError('uppercaseRequired') && passwordForm.get('newPassword').touched">
                                Password must contain at least one uppercase letter.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('newPassword').hasError('lowercaseRequired') && passwordForm.get('newPassword').touched">
                                Password must contain at least one lowercase letter.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('newPassword').hasError('digitRequired') && passwordForm.get('newPassword').touched">
                                Password must contain at least one digit.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('password').hasError('isProfane') && passwordForm.get('password').touched">
                                Please refrane from profanity.
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Confirm New Password</label>
                            <input type="password" class="form-control" formControlName="confirmPassword">
                            <mat-error
                                *ngIf="passwordForm.get('confirmPassword').hasError('required') && passwordForm.get('confirmPassword').touched">
                                This field is required.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.get('confirmPassword').hasError('isProfane') && passwordForm.get('confirmPassword').touched">
                                Please refrane from profanity.
                            </mat-error>
                            <mat-error
                                *ngIf="passwordForm.hasError('passwordMismatch') && passwordForm.get('confirmPassword').touched">
                                Passwords do not match.
                            </mat-error>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <button type="button" (click)="submitPassword()">Change Password</button>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template #passwordManagedByGoogle>
                <div class="alert alert-warning" role="alert">
                    Cannot manage password through this system as your password is managed by Google. To reset your
                    password, logout and follow the forgot password flow.
                </div>
            </ng-template>
        </div>
    </div>
</div>