import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { AuthService } from '@app/services';
import { NoAccessComponent } from '../no-access/no-access.component';

@Directive({
  selector: '[appPermissions]'
})
export class PermissionDirective implements OnInit {

  @Input() appPermissions: string;
  @Input() appPermissionsShowErrorCard: boolean = false;

  private noAccessComponentRef: ComponentRef<NoAccessComponent>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.authService.hasPermission(this.appPermissions).subscribe(hasPermissions => {
      if (hasPermissions) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
        if (this.appPermissionsShowErrorCard) {
          this.createNoAccessComponent(this.appPermissions);
        }
      }
    });
  }

  private createNoAccessComponent(missingPermission: string) {
    if (!this.noAccessComponentRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NoAccessComponent);
      this.noAccessComponentRef = this.viewContainer.createComponent(componentFactory, 0, this.injector);
      this.noAccessComponentRef.instance.permission = missingPermission;
    }
  }
}
