import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AfterViewInit, Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


import { AlertType } from '@app/components/shared/alert/alert.types';
import { AuthService } from '@app/services';
import { GlobalStateService } from '@app/global.state';
import { Observable } from 'rxjs';
import { ObserversModule } from '@angular/cdk/observers';
import { SiteSettingsDto } from '@app/models/dtos/site-settings.dto';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { fuseAnimations } from '@app/components/shared/animations';
import { vulgarLanguageValidator } from '@app/components/shared/validators/vulgarity-validator';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;
  signInForm: UntypedFormGroup;
  alert: { type: AlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  private window: Window;
  $settings: Observable<SiteSettingsDto> = this._state.siteSettings$;
  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private _state: GlobalStateService,
  ) {
    this.window = window;
    this.disableBackButton();
  }

  ngOnInit(): void {
    this._state.getSiteSettings();
    // Create the form
    this.signInForm = this._formBuilder.group({
      username: ['', [Validators.required, vulgarLanguageValidator()]],
      password: ['', [Validators.required, vulgarLanguageValidator()]],
      rememberMe: ['']
    });
    this.socialAuthService.authState.subscribe(user => {
      if (user && user.provider === "GOOGLE") {
        this.authService.signInGoogle({
          tokenId: user.idToken,
        }).subscribe(
          (res) => {
            if (res.data) {
              const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
              // Navigate to the redirect url
              this._router.navigateByUrl(redirectURL);
            } else {
              this._state.alertUI(res.message, 'error');
              this.socialAuthService.signOut();
              // Re-enable the form
              this.signInForm.enable();

              // Reset the form
              this.signInNgForm.resetForm();

              // Set the alert
              this.alert = {
                type: 'error',
                message: res.message,
              };

              // Show the alert
              this.showAlert = true;
            }
          }
        );
      }
    })
  }

  /**
  * Sign in
  */
  signIn(): void {
    // Return if the form is invalid
    if (this.signInForm.invalid) {
      this._state.alertUI('form is invalid', 'warning');
      this.signInForm.markAllAsTouched();
      return;
    }
    // Disable the form
    this.signInForm.disable();
    this._authService.signIn(this.signInForm.value)
      .subscribe(
        () => {
          const redirectURL = this._activatedRoute.snapshot.queryParamMap.get('redirectURL') || '/signed-in-redirect';
          this._router.navigateByUrl(redirectURL);
        },
        (response) => {
          this._state.alertUI('Wrong username or password', 'error');
          this.signInForm.enable();
          this.signInNgForm.resetForm();
        }
      );
  }

  private disableBackButton(): void {
    this.window.history.pushState(null, null, null);
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.window.history.pushState(null, null, null);
      };
    });
  }
}
