<mat-dialog-content style="min-height: 450px;">
    <h3 class="mt-8 mb-4">Create Support Ticket</h3>
    <div class="container">
        <div class="row">

            <div class="col-md-12">
                <mat-hint>Describe your issue in full. and we will get back to you</mat-hint>
            </div>
            <div class="col-md-12">
                <form [formGroup]="form">
                    <quill-editor [formControlName]="'issue'" [bounds]="'self'" [modules]="quillModules">
                    </quill-editor>
                    <mat-error *ngIf="form.get('issue').hasError('isProfane') && form.get('issue').touched">
                        Please refrane from profanity.
                    </mat-error>
                </form>
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button class="px-8" [color]="'warn'" mat-dialog-close>
        Close
    </button>
    <button mat-button class="px-8" (click)="submit()" [color]="'primary'">
        Submit
    </button>
</mat-dialog-actions>