import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RecommendationHistoryDto } from "@app/models";
import { RecommendationService } from "@app/services";
import { Observable } from "rxjs";
import { ListingStatisticsData } from "./listing-statistics.data";
import { ListingStatisticsStateService } from "./listing-statistics.state";

@Component({
    selector: 'listing-statistics',
    templateUrl: './listing-statistics.component.html',
  })
export class ListingStatisticsComponent implements OnInit {

    displayedColumns: string[] = ['scoreGiven', 'maxScoreAchievable', 'scoreFactorUsed', 'dateComputed','signature'];
    recommendations$: Observable<RecommendationHistoryDto[]> = this.state.recommendations$;    
    totalScore$: Observable<number> = this.state.totalScore$;   

    constructor(
        public dialogRef: MatDialogRef<ListingStatisticsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ListingStatisticsData,
        private state: ListingStatisticsStateService) {}
    
    ngOnInit(): void {
        this._fetchData();
    }
    private _fetchData() {
        this.state.load(this.data.businessProfileId);
    }


}