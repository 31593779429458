import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BarIndicatorComponent } from './bar-indicator/bar-indicator.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SnapScrollerComponent } from './snap-scroller.component';
import { SnapScrollerSlideComponent } from './snap-scroller-slide/snap-scroller-slide.component';

@NgModule({
  declarations: [
    SnapScrollerComponent,
    SnapScrollerSlideComponent,
    BarIndicatorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule],
  exports: [
    SnapScrollerComponent,
    SnapScrollerSlideComponent,
    BarIndicatorComponent
],
})
export class ScrollerModule { }
