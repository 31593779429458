<div class="breadcrumb-area">
    <button class="btn btn-danger me-2 ms-2" (click)="createSupportTicket()">
        Create New Support Ticket
    </button>
    <h1>My Support Tickets</h1>
    <ol class="breadcrumb">
        <li class="item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="item">Support Tickets</li>
    </ol>
</div>

<section class="listing-area mb-30">
    <div class="mat-elevation-z8">
        <ng-container *ngIf="supportDataSource && supportDataSource.data.length > 0; else notFound">
            <mat-table [dataSource]="supportDataSource">

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <span class="me-2 fw-bold">#{{element.ticketNumber}}</span>
                        <span class="badge badge-success" *ngIf="element.supportTicketStatus === 2">Closed /
                            Resolved</span>
                        <span class="badge badge-primary" *ngIf="element.supportTicketStatus === 1">Waiting for Customer
                            Response</span>
                        <span class="badge badge-danger" *ngIf="element.supportTicketStatus === 0">Open</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="issue">
                    <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div [innerHTML]="element.issueDescription"></div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="creationDate">
                    <mat-header-cell *matHeaderCellDef> Date </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.creationDate | date: 'short'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="flex flex-wrap justify-center">
                        <button mat-raised-button class="px-4 m-2 w-full sm:w-auto" (click)="view(element.id)"
                            [color]="'primary'">
                            <span class="ml-2 mr-1">View</span>
                        </button>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </ng-container>

        <ng-template #notFound>
            <div class="text-center text-gray-600 py-6">
                No tickets found.
            </div>
        </ng-template>

        <mat-paginator #supportPaginator="matPaginator" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
        </mat-paginator>
    </div>
</section>