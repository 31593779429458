import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-no-access',
    template: `
    <div class="mt-5 mb-5 alert alert-danger" role="alert">
      <div class="d-flex">
        <div class="py-1">
        </div>
        <div class="mt-5 mb-5 card border-danger" style="width: 100%">
            <div class="card-body text-danger text-center">
                <h5 class="card-title">This is a premium feature.</h5>
                <p class="card-text">Upgrade to continue.</p>
                <p class="card-text text-muted">If you are already on the premium plan and this is blocked, please try logging out and logging in again.</p>
                <a routerLink="/pricing" class="btn btn-success">Upgrade</a>
            </div>
        </div>
      </div>
    </div>
  `
})
export class NoAccessComponent {
    @Input() permission: string;
}
