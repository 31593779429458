import { AfterViewInit, Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
	selector: 'vivid-snap-scroller-slide',
	templateUrl: './snap-scroller-slide.component.html',
	styleUrls: ['./snap-scroller-slide.component.scss'],
})
export class SnapScrollerSlideComponent implements AfterViewInit {
	@Input() showOverflow = false;
	@Input() centerContent = true;
	@Input() singleItem = false;

	@ViewChild(TemplateRef)
	private _slide!: TemplateRef<any>;

	get slide(): TemplateRef<any> {
		return this._slide;
	}

	set slide(value: TemplateRef<any>) {
		this._slide = value;
	}

	constructor(private el: ElementRef) {}

	ngAfterViewInit(): void {
		this.el.nativeElement.style.minWidth = this.singleItem ? 'inherit' : undefined;
	}
}
