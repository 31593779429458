import { Component, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateAuthorInfoImageRequest } from '@app/models';
import Swal from 'sweetalert2';
import { AuthorInfoStateService } from '../../author-information.state';
import { ImagePickerConf } from '@app/components/shared/image-picker/models/index.models';

@Component({
  selector: 'app-update-author-information-image',
  templateUrl: './update-author-information-image.component.html',
  styleUrls: ['./update-author-information-image.component.scss']
})
export class UpdateAuthorInformationImageComponent {

  form: UntypedFormGroup;
  imagePickerConf: ImagePickerConf = {
		borderRadius: '8px',
		language: 'en',
		width: '300px',
		objectFit: 'contain',
		aspectRatio: 4 / 3,
		compressInitial: null,
	  };
  constructor(private _formBuilder: UntypedFormBuilder, 
    private _state: AuthorInfoStateService,
    private _dialogRef: MatDialogRef<UpdateAuthorInformationImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {authorInformationId: string }) {
    
  }


  ngOnInit(): void {
    this._buildForm();
  }

  _buildForm(){
    this.form = this._formBuilder.group({
      profile		      		: ['', []],
      profilesrc		      	: [null, []],
    });
  }

  submit(){
    if(this.form.invalid){
      Swal.fire({
				title: 'You have an error',
				text: 'Check all your entries and make sure everything is valid.',
				icon: 'error',
			  });
    }else{
      this._state.updateImage(this._buildRequest(), (response)=>{
        Swal.fire({
          title: 'Updated Image',
          text: response.messsage,
          icon: 'success',
          }).then( (res: any) => {
            this._dialogRef.close(true);
          });
      }, (err)=>{
        Swal.fire({
          title: 'Something went wrong',
          text: err,
          icon: 'error',
          });
      })
    }
  }

  onProfileChanged(event: any){
				
		this.form.controls.profilesrc.setValue(event);
		this.form.controls.profilesrc.updateValueAndValidity();    
		
		const imageBlob = this.dataURItoBlob(event);
		const file = new File([imageBlob], 'profile-picture.png', { type: 'image/png' });
		
		this.form.controls.profile.setValue(file);
		this.form.controls.profile.updateValueAndValidity();

	}

  private _buildRequest(): UpdateAuthorInfoImageRequest {
    return <UpdateAuthorInfoImageRequest>{
      authorInformationId: this.data.authorInformationId,
      file: this.form.controls.profile.value,
    }
  }



  dataURItoBlob(dataURI: string) {
		const byteString = window.atob(dataURI.replace('data:image/png;base64,','').replace('data:image/jpeg;base64,','').replace('data:image/jpg;base64,',''));
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
		  int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/png' });    
		return blob;
	}
  
}
