import { ConfirmationResponse } from '@app/models';
import { CreateSupportCommentRequest } from '@app/models/requests/create-support-comment.request';
import { CreateSupportTicketRequest } from '@app/models/requests/create-support-ticket.request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';
import { SearchSupportTicketRequest } from '@app/models/requests/search-support-ticket.request';
import { SupportResponse } from '@app/models/responses/support.response';
import { SupportsResponse } from '@app/models/responses/supports.response';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) { }

  supportURL = Keys.API_URL + 'api/SupportTicket';

  getSupportTickets(request: SearchSupportTicketRequest): Observable<SupportsResponse> {
    return this.http.post<SupportsResponse>(this.supportURL + '/Tickets', request);
  }

  getSupportTicket(id: string): Observable<SupportResponse> {
    return this.http.get<SupportResponse>(this.supportURL + '/Ticket/' + id);
  }

  closeTicket(id: string): Observable<ConfirmationResponse> {
    return this.http.get<ConfirmationResponse>(this.supportURL + '/Close/' + id);
  }

  createTicket(request: CreateSupportTicketRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(this.supportURL + '/Ticket', request);
  }

  createSupportComment(request: CreateSupportCommentRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(this.supportURL + '/Comment', request);
  }
}