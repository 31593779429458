import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AlertType } from '@app/components/shared/alert/alert.types';
import { AuthService } from '@app/services';
import { RootStateService } from '../root.state';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { fuseAnimations } from '@app/components/shared/animations';

@Component({
  selector: 'app-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ConfirmPasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('signInNgForm') signInNgForm: NgForm;
  signInForm: UntypedFormGroup;
  alert: { type: AlertType; message: string } = {
    type: 'success',
    message: ''
  };
  showAlert: boolean = false;
  email: string;
  token: string;
  /**
   * Constructor
   */
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _rootState: RootStateService,
  ) { }

  ngOnInit(): void {
    // Create the form
    this.signInForm = this._formBuilder.group({
      newPassword: [, [Validators.required]],
      connewPassword: [, [Validators.required]],
    });
  }

  ngAfterViewInit(): void {
    this._activatedRoute.queryParams.subscribe(params => {
      this.email = params['email'];
      this.token = params['token'];
    })
    this._authService.check().subscribe(res => {
      if (res) {
        Swal.fire({
          title: 'Hmmm',
          text: 'You are already logged in. Click Ok to continue to your dashboard',
          icon: 'info',
        }).then(val => {
          setTimeout(() => {
            const redirectUrl = '/dashboard';
            // Navigate to the redirect url
            this._router.navigateByUrl(redirectUrl);
          }, 500);
        })

      }
    })
  }

  /**
  * Sign in
  */
  submit(): void {
    // Return if the form is invalid
    if (this.signInForm.controls.newPassword.value != this.signInForm.controls.connewPassword.value) {
      this._rootState.alertUI('Passwords do not match', 'warning');
      this.signInForm.markAllAsTouched();
      return;
    }
    if (this.signInForm.invalid) {
      this._rootState.alertUI('Form is invalid', 'warning');
      this.signInForm.markAllAsTouched();
      return;
    }
    if (!this.token && !this.email) {
      this._rootState.alertUI('Invalid URL', 'warning');
      return;
    }
    // Disable the form
    this.signInForm.disable();
    this._authService.confirmForgotPassword(this.token, this.email, this.signInForm.controls.newPassword.value)
      .subscribe(
        (res) => {
          if (res.data) {
            this._rootState.alertUI(res.data.messsage, 'success');
            this.signInForm.enable();
            this._router.navigateByUrl('/login');
          } else {
            this._rootState.alertUI(res.message, 'error');

            // Re-enable the form
            this.signInForm.enable();

            // Reset the form
            this.signInNgForm.resetForm();

            // Set the alert
            this.alert = {
              type: 'error',
              message: 'Invalid Token. Try resetting again'
            };

            // Show the alert
            this.showAlert = true;
          }
        }
      );
  }


}
