
<h2 style="text-align:center">YeboKenny Pricing Plans</h2>

<div class="container" *ngIf="user$ | async as user">
    <div class="row" *ngIf="plans$ |async as plans">
        <div class="columns" *ngFor="let plan of plans">
                <ul class="price">
                    <li class="header">{{plan.name}}</li>
                    <li class="grey">R {{plan.price}} / {{getPeriodType(plan.periodType)}}</li>
                    <li class="grey-description">{{plan.description}}</li>
                    <li *ngFor="let feature of plan.features">{{feature.friendlyName}}</li>
                    <li class="grey"  *ngIf="(user.planId === plan.id); else default"><button (click)="activated()" class="btn btn-outline-success">Activated</button></li>
                    <ng-template  #default>
                        <li class="grey"><button (click)="subscribe(plan.id)" class="btn btn-outline-info">Get Started</button></li>
                    </ng-template>                    
                </ul>
          </div>
    </div>
</div>