import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlterBusinessHoursData } from './alter-business-hours.data';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AlterMultipleBusinessHourRequest, BusinessHour, DayOfWeek } from '@app/models';
import { BusinessService, LoadingService } from '@app/services';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { ApplicationPermissions } from '@app/models/constants/permissions';

@Component({
  selector: 'app-alter-business-hours',
  templateUrl: './alter-business-hours.component.html',
  styleUrls: ['./alter-business-hours.component.scss'],
})
export class AlterBusinessHoursComponent implements OnInit {
  appPermissions = ApplicationPermissions;
  form: UntypedFormGroup;
  timeStart = { hour: 8, minute: 30 };
  timeEnd = { hour: 17, minute: 0 };
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<AlterBusinessHoursComponent>,
    private _businessService: BusinessService,
    private _loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA) public data: AlterBusinessHoursData,) {
  }

  ngOnInit(): void {
    this._buildForm();
  }

  _buildForm() {
    this.form = this._formBuilder.group({
      mondayStart: [this.timeStart, Validators.required],
      mondayEnd: [this.timeEnd, Validators.required],
      mondayClosed: [false, Validators.required],
      tuesdayStart: [this.timeStart, Validators.required],
      tuesdayEnd: [this.timeEnd, Validators.required],
      tuesdayClosed: [false, Validators.required],
      wednesdayStart: [this.timeStart, Validators.required],
      wednesdayEnd: [this.timeEnd, Validators.required],
      wednesdayClosed: [false, Validators.required],
      thursdayStart: [this.timeStart, Validators.required],
      thursdayEnd: [this.timeEnd, Validators.required],
      thursdayClosed: [false, Validators.required],
      fridayStart: [this.timeStart, Validators.required],
      fridayEnd: [this.timeEnd, Validators.required],
      fridayClosed: [false, Validators.required],
      saturdayStart: [this.timeStart, Validators.required],
      saturdayEnd: [this.timeEnd, Validators.required],
      saturdayClosed: [false, Validators.required],
      sundayStart: [this.timeStart, Validators.required],
      sundayEnd: [this.timeEnd, Validators.required],
      sundayClosed: [false, Validators.required],
    });

    this._businessService.getBusinessHours(this.data.businessProfileId).pipe(map(d => d.data)).subscribe(result => {
      result.forEach(element => {
        switch (element.dayOfWeek) {
          case DayOfWeek.MONDAY:
            this._setInitial(this.form.controls.mondayStart, this.form.controls.mondayEnd, this.form.controls.mondayClosed, element);
            break;
          case DayOfWeek.TUESDAY:
            this._setInitial(this.form.controls.tuesdayStart, this.form.controls.tuesdayEnd, this.form.controls.tuesdayClosed, element);
            break;
          case DayOfWeek.WEDNESDAY:
            this._setInitial(this.form.controls.wednesdayStart, this.form.controls.wednesdayEnd, this.form.controls.wednesdayClosed, element);
            break;
          case DayOfWeek.THURSDAY:
            this._setInitial(this.form.controls.thursdayStart, this.form.controls.thursdayEnd, this.form.controls.thursdayClosed, element);
            break;
          case DayOfWeek.FRIDAY:
            this._setInitial(this.form.controls.fridayStart, this.form.controls.fridayEnd, this.form.controls.fridayClosed, element);
            break;
          case DayOfWeek.SATURDAY:
            this._setInitial(this.form.controls.saturdayStart, this.form.controls.saturdayEnd, this.form.controls.saturdayClosed, element);
            break;
          case DayOfWeek.SUNDAY:
            this._setInitial(this.form.controls.sundayStart, this.form.controls.sundayEnd, this.form.controls.sundayClosed, element);
            break;
          default:
            break;
        }
      });
      this.form.updateValueAndValidity();
    })
  }
  private _setInitial(start: AbstractControl<any, any>, end: AbstractControl<any, any>, closed: AbstractControl<any, any>, model: BusinessHour) {
    start.setValue(this.parseTime(model.openingTime));
    end.setValue(this.parseTime(model.closingTime));
    closed.setValue(model.isClosed);
  }

  parseTime(timeString: string): { hour: number, minute: number } {
    const [hourString, minuteString] = timeString.split(':');
    const hour = parseInt(hourString);
    const minute = parseInt(minuteString);
    return { hour, minute };
  }


  submit() {
    this._loadingService.setLoading(true);
    this._businessService.alterMultipleBusinessHours(this._buildRequest())
      .subscribe(result => {
        this._loadingService.setLoading(false);
        if (result.data) {
          Swal.fire({
            title: 'Success',
            text: result.message,
            icon: 'success',
          }).then(res => {
            this.dialogRef.close();
          });
        } else {
          Swal.fire({
            title: 'Oh no',
            text: result.message,
            icon: 'error',
          });
        }
      });
  }


  private _buildRequest(): AlterMultipleBusinessHourRequest {
    const id = this.data.businessProfileId;

    var request = <AlterMultipleBusinessHourRequest>{
      timeSlots: [
        {
          dayOfWeek: DayOfWeek.MONDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.mondayStart),
          closingTime: this._buildDate(this.form.controls.mondayEnd),
          isClosed: this.form.controls.mondayClosed.value,
        },
        {
          dayOfWeek: DayOfWeek.TUESDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.tuesdayStart),
          closingTime: this._buildDate(this.form.controls.tuesdayEnd),
          isClosed: this.form.controls.tuesdayClosed.value,
        },
        {
          dayOfWeek: DayOfWeek.WEDNESDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.wednesdayStart),
          closingTime: this._buildDate(this.form.controls.wednesdayEnd),
          isClosed: this.form.controls.wednesdayClosed.value,
        },
        {
          dayOfWeek: DayOfWeek.THURSDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.thursdayStart),
          closingTime: this._buildDate(this.form.controls.thursdayEnd),
          isClosed: this.form.controls.thursdayClosed.value,
        },
        {
          dayOfWeek: DayOfWeek.FRIDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.fridayStart),
          closingTime: this._buildDate(this.form.controls.fridayEnd),
          isClosed: this.form.controls.fridayClosed.value,
        },
        {
          dayOfWeek: DayOfWeek.SATURDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.saturdayStart),
          closingTime: this._buildDate(this.form.controls.saturdayEnd),
          isClosed: this.form.controls.saturdayClosed.value,
        }, {
          dayOfWeek: DayOfWeek.SUNDAY,
          businessProfileId: id,
          openingTime: this._buildDate(this.form.controls.sundayStart),
          closingTime: this._buildDate(this.form.controls.sundayEnd),
          isClosed: this.form.controls.sundayClosed.value,
        },
      ]
    };
    return request;
  }

  private _buildDate(control: AbstractControl<any, any>): string {
    try {
      const currentDate = DateTime.local();
      const hour = control.value.hour as number;
      const minute = control.value.minute as number;
      const dateInDesiredTimezone = currentDate.set({
        hour,
        minute,
        second: 0,
        millisecond: 0,
      });
      const jsDate = dateInDesiredTimezone.toJSDate();
      jsDate.setHours(jsDate.getHours() + 2);
      return jsDate.toISOString();
    }
    catch (e: any) {
      const currentDate = DateTime.local();
      const jsDate = currentDate.toJSDate();
      return jsDate.toISOString();
    }
  }
}
