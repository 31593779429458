import { BusinessDetailsDto, CategoryDto } from "@app/models";
import { StateService } from "@app/services";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SupportService } from "@app/services/support.service";
import { SearchSupportTicketRequest } from "@app/models/requests/search-support-ticket.request";
import { SupportTicketDto } from "@app/models/dtos/support-ticket.dto";
import { CreateSupportTicketRequest } from "@app/models/requests/create-support-ticket.request";
import { CreateSupportCommentRequest } from "@app/models/requests/create-support-comment.request";

interface SupportState {
    tickets: SupportTicketDto[],
    ticket: SupportTicketDto,
}


const initialState: SupportState = {
    tickets: [],
    ticket: undefined,
}

@Injectable({
    providedIn: 'root',
})
export class SupportStateService extends StateService<SupportState>{
    tickets$: Observable<SupportTicketDto[]> = this.select((state) => {
        return state.tickets;
    });
    ticket$: Observable<SupportTicketDto> = this.select((state) => {
        return state.ticket;
    });

    constructor(
        private _supportService: SupportService,
    ) {
        super(initialState);
    }

    supportTickets(callback: (tickets: SupportTicketDto[]) => void) {
        this._supportService.getSupportTickets(<SearchSupportTicketRequest>{
            isSupport: false,
            ticketsWithNoAssignee: false,
        }).subscribe(res => {
            this.setState({
                tickets: res.data,
            });
            callback(res.data);
        });
    }

    supportTicket(id: string) {
        this._supportService.getSupportTicket(id).subscribe(res => {
            this.setState({
                ticket: res.data,
            });
        });
    }

    closeTicket(id: string, callback: Function) {
        this._supportService.closeTicket(id).subscribe(res => {
            if (res.data) {
                this.alertUI(res.data.messsage, 'success');
                callback();
            } else {
                this.alertUI(res.message, 'error');
            }
        });
    }

    createTicket(request: CreateSupportTicketRequest, callback: () => void) {
        this._supportService.createTicket(request).subscribe(res => {
            if (res.data) {
                this.alertUI(res.data.messsage, 'success');
                callback();
            } else {
                this.alertUI(res.message, 'error');
            }
        });
    }

    createComment(request: CreateSupportCommentRequest, callback: () => void) {
        this._supportService.createSupportComment(request).subscribe(res => {
            if (res.data) {
                this.alertUI(res.data.messsage, 'success');
                callback();
            } else {
                this.alertUI(res.message, 'error');
            }
        });
    }
}