import { AuthorInfoDto, ConfirmationDto, CreateAuthorInfoRequest, UpdateAuthorInfoImageRequest, UpdateAuthorInfoRequest } from "@app/models";

import { AuthorInfoService } from "@app/services/author-info.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "@app/services";

interface AuthorInfoState {
    authorInfo: AuthorInfoDto,
}


const initialState: AuthorInfoState = {
    authorInfo: undefined,
}

@Injectable({
    providedIn: 'root',
  })
export class AuthorInfoStateService extends StateService<AuthorInfoState>{
      
    authorInfo$: Observable<AuthorInfoDto> = this.select((state)=>{
        return state.authorInfo;
    });

    constructor(private authorInfoService: AuthorInfoService){
        super(initialState);        
    }


    
  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------
  
    
  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load(){
    this.authorInfoService.get().subscribe((response) =>{
        this.setState({authorInfo: response.data});      
    })    
  }

  create(request: CreateAuthorInfoRequest, success: (response: ConfirmationDto) => void, 
    failure: (response: string) => void){
    this.authorInfoService.create(request).subscribe(response =>{
      if(response.data){
        success(response.data);
      }else{
        failure(response.message);
      }
    })
  }

  updateImage(request: UpdateAuthorInfoImageRequest, success: (response: ConfirmationDto) => void, 
    failure: (response: string) => void){
    this.authorInfoService.updateImage(request).subscribe(response => {
      if(response.data) {
        success(response.data);
      }else{
        failure(response.message);
      }
    })
  }

  updateInfo(request: UpdateAuthorInfoRequest, success: (response: ConfirmationDto) => void, 
    failure: (response: string) => void){
    this.authorInfoService.updateInfo(request).subscribe(response => {
      if(response.data) {
        success(response.data);
      }else{
        failure(response.message);
      }
    })
  }
  
}