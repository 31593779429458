import { Component, OnInit, ViewChild } from '@angular/core';

import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SupportStateService } from './support.state';
import { SupportTicketDto } from '@app/models/dtos/support-ticket.dto';

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
	styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

	displayedColumns: string[] = ['status', 'issue', 'creationDate', 'actions'];
	@ViewChild('patientPaginator') supportPaginator: MatPaginator;
	supportDataSource: MatTableDataSource<SupportTicketDto>;

	constructor(
		private _state: SupportStateService,
		private _router: Router,
		private _dialog: MatDialog) { }

	ngOnInit(): void {
		this.refresh();
	}

	private refresh() {
		this._state.supportTickets((tickets) => {
			this.supportDataSource = new MatTableDataSource<SupportTicketDto>(tickets);
			this.supportDataSource.paginator = this.supportPaginator;
			this.supportDataSource.data = tickets;
		});
	}

	createSupportTicket() {
		const ref = this._dialog.open(CreateTicketComponent, {
			disableClose: true,
			panelClass: ['full-width-modal'],
		});
		ref.afterClosed().subscribe(result => {
			this.refresh();
		});
	}

	view(id: string) {
		this._router.navigateByUrl('/support/' + id);
	}
}
