import { AbstractControl, ValidatorFn } from '@angular/forms';

export function urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null; // If the control is empty, consider it valid (or you can change this behavior)
        }

        // Regular expression to match URLs
        const urlPattern = /^(http(s)?:\/\/)?(www\.)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?$/;

        if (!urlPattern.test(control.value)) {
            return { 'invalidUrl': { value: control.value } }; // Return an error object if the URL is invalid
        }

        return null; // URL is valid
    };
}
