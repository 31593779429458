import { BusinessDetailsResponse, ChangePasswordRequest, ConfirmationResponse, MeResponse, UpdateUserRequest, UserDto } from '@app/models';
import { Observable, ReplaySubject, map, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: ReplaySubject<UserDto> = new ReplaySubject<UserDto>(1);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: UserDto) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<UserDto> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the current logged in user data
   */
  get(): Observable<any> {
    return this._httpClient.get<MeResponse>(Keys.API_URL + 'api/Authentication/Me').pipe(
      tap((user) => {
        this._user.next(user.data);
      })
    );
  }

  details(): Observable<BusinessDetailsResponse> {
    return this._httpClient.get<BusinessDetailsResponse>(Keys.API_URL + 'api/Business/Me');
  }

  updateUser(request: UpdateUserRequest): Observable<ConfirmationResponse> {
    return this._httpClient.post<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/UpdateUser', request);
  }

  changePassword(request: ChangePasswordRequest): Observable<ConfirmationResponse> {
    return this._httpClient.post<ConfirmationResponse>(Keys.API_URL + 'api/Authentication/ChangePassword', request);
  }
}
