<form class="m-3 p-3" [formGroup]="form">
    <div class="container" *appPermissions="appPermissions.ADD_BUSINESS_HOURS;showErrorCard:true">
        <!-- MONDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Monday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">
                    <div class="text-center">Opening</div>
                    <div class="row"><ngb-timepicker class="custom-form-control"
                            [formControlName]="'mondayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="text-center">Closing</div>
                <div class="row"><ngb-timepicker [formControlName]="'mondayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="mondayClosed"
                        [formControlName]="'mondayClosed'">
                    <label class="form-check-label" for="mondayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- TUESDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Tuesday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'tuesdayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'tuesdayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="tuesdayClosed"
                        [formControlName]="'tuesdayClosed'">
                    <label class="form-check-label" for="tuesdayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- WEDNESDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Wednesday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'wednesdayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'wednesdayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="wednesdayClosed"
                        [formControlName]="'wednesdayClosed'">
                    <label class="form-check-label" for="wednesdayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- THURSDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Thursday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'thursdayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'thursdayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="thursdayClosed"
                        [formControlName]="'thursdayClosed'">
                    <label class="form-check-label" for="thursdayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- FRIDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Friday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'fridayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'fridayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="fridayClosed"
                        [formControlName]="'fridayClosed'">
                    <label class="form-check-label" for="fridayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- SATURDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Saturday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'saturdayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'saturdayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="saturdayClosed"
                        [formControlName]="'saturdayClosed'">
                    <label class="form-check-label" for="saturdayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
        <!-- SUNDAY -->
        <div class="row d-flex align-items-center justify-content-between">
            <div class="col-md-3">
                <div class="fs-6 fw-bold text-muted">Sunday:</div>
            </div>
            <div class="col-md-3">
                <div class="row">

                    <div class="row"><ngb-timepicker [formControlName]="'sundayStart'"></ngb-timepicker></div>
                </div>
            </div>
            <div class="col-md-3">

                <div class="row"><ngb-timepicker [formControlName]="'sundayEnd'"></ngb-timepicker></div>
            </div>
            <div class="col-md-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="sundayClosed"
                        [formControlName]="'sundayClosed'">
                    <label class="form-check-label" for="sundayClosed">
                        Closed
                    </label>
                </div>
            </div>
        </div>
        <hr>
    </div>
    <div class="row" *appPermissions="appPermissions.ADD_BUSINESS_HOURS;">
        <div class="btn btn-primary" (click)="submit()">Submit</div>
    </div>
</form>