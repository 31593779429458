<div #parent
	class="bar-indicator"
	(click)="translateOnClick($event)"
	[ngStyle]="{ width: parentWidth + 'px' }"
	[ngClass]="{ 'bar-indicator--sunshine': variant === 'sunshine', 'vivid-bar-indicator--raven': variant === 'raven' }">
	<div #child
		class="bar-indicator-scroller"
		[ngStyle]="{ width: childWidth + 'px', transition: enableTransition ? 'all 300ms ease-in-out' : 'none' }"
		(pointerdown)="startDrag($event)"
		(pointerup)="endDrag($event)"
		(pointermove)="dragging($event)"
		(pointercancel)="cancelDrag($event)"
	></div>
</div>
