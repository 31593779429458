import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordMatchValidator(): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const newPassword = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;

    if (newPassword !== confirmPassword) {
      return { passwordMismatch: true };
    }

    return null;
  };
}

export function passwordValidator(minLength: number = 8): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
      return null; // If the control value is empty, consider it valid
    }

    // Check if the password meets the minimum length requirement
    if (value.length < minLength) {
      return { minLength: true };
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(value)) {
      return { uppercaseRequired: true };
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(value)) {
      return { lowercaseRequired: true };
    }

    // Check if the password contains at least one digit
    if (!/[0-9]/.test(value)) {
      return { digitRequired: true };
    }

    return null; // Validation passed
  };
}
