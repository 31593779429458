import { BusinessDetailsDto, FeatureDto, RecommendationHistoryDto } from '@app/models';
import { Component, OnInit } from '@angular/core';

import { ApplicationPermissions } from '@app/models/constants/permissions';
import { FeatureService } from '@app/services/feature.service';
import { LoadingService } from '@app/services';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { RecommendationDetailComponent } from './components/recommendation-detail/recommendation-detail.component';
import { RecommendationStateService } from './recommendation.state';
import { RootStateService } from '../root.state';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss']
})
export class RecommendationComponent implements OnInit {
  appPermissions = ApplicationPermissions;
  businesses$: Observable<BusinessDetailsDto[]> = this._state.businesses$;
  recommendations$: Observable<RecommendationHistoryDto[]> = this._state.recommendations$;

  constructor(
    private _dialog: MatDialog,
    private _router: Router,
    private _rootState: RootStateService,
    private _featureService: FeatureService,
    private _loadingService: LoadingService,
    private _state: RecommendationStateService,
  ) { }

  ngOnInit(): void {
    this._state.businesses();
  }

  loadRecommendations(id: string) {
    this._state.recommendations(id);
  }

  openDialog(recommendationId: string) {
    this._openDialog(recommendationId);
  }

  _openDialog(recommendationId: string) {
    const dialogRef = this._dialog.open(RecommendationDetailComponent, {
      data: { recommendationId: recommendationId },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
