import { GenerateLinkResponse, SubscribeResponse } from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {

  constructor(private http: HttpClient) { }

  subscribe(planId: string): Observable<SubscribeResponse> {
    return this.http.get<SubscribeResponse>(Keys.API_URL + 'api/Subscription/' + planId);
  }

  generateLink(subscriptionCode: string): Observable<GenerateLinkResponse> {
    return this.http.get<GenerateLinkResponse>(Keys.API_URL + 'api/Subscription/GenerateUpdateLink/' + subscriptionCode);
  }
}