import { BusinessDetailsDto, DashboardDto, UserDto } from '@app/models';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';

import { DashboardStateService } from './dashboard.state';
import { RootStateService } from '../root.state';
import { Router } from '@angular/router';
import { UserService } from '@app/services';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  
  user$: Observable<UserDto> = this._rootState.user$;
  analytics$: Observable<DashboardDto> = this._state.analytics$;
  businesses$: Observable<BusinessDetailsDto[]>;
  constructor(
    private _userService: UserService,
    private _rootState: RootStateService,
    private _router: Router,
    private _state: DashboardStateService) { }

  ngOnInit(): void {
    this._rootState.load();
    this._state.load();
    this.businesses$ = this._userService.details()
			.pipe(map(response => response.data));
  }

  viewListing(businessId: String){
    this._router.navigateByUrl('listing-details/'+ businessId);
  }
}
