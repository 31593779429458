import {
    AuthorInfoResponse,
    BusinessResponse,
    ConfirmationDto,
    ConfirmationResponse,
    CreateAuthorInfoRequest,
    UpdateAuthorInfoImageRequest,
    UpdateAuthorInfoRequest
} from '@app/models';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/models/constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthorInfoService {

    constructor(private http: HttpClient) { }

    get(): Observable<AuthorInfoResponse> {
        return this.http.get<AuthorInfoResponse>(Keys.API_URL + 'api/AuthorInfo');
    }

    create(request: CreateAuthorInfoRequest): Observable<ConfirmationResponse> {
        var formData = new FormData();
        formData.append('displayName', request.displayName);
        formData.append('jobTitle', request.jobTitle);
        formData.append('hobbies', request.hobbies);
        formData.append('description', request.description);
        formData.append('file', request.file);
        return this.http.post<ConfirmationResponse>(Keys.API_URL + 'api/AuthorInfo', formData);
    }

    updateInfo(request: UpdateAuthorInfoRequest): Observable<ConfirmationResponse> {
        var formData = new FormData();
        formData.append('displayName', request.displayName);
        formData.append('jobTitle', request.jobTitle);
        formData.append('hobbies', request.hobbies);
        formData.append('description', request.description);
        formData.append('authorInformationId', request.authorInformationId);
        return this.http.patch<ConfirmationResponse>(Keys.API_URL + 'api/AuthorInfo/Info', formData);
    }

    updateImage(request: UpdateAuthorInfoImageRequest): Observable<ConfirmationResponse> {
        var formData = new FormData();
        formData.append('authorInformationId', request.authorInformationId);
        formData.append('file', request.file);
        return this.http.patch<ConfirmationResponse>(Keys.API_URL + 'api/AuthorInfo/Image', formData);
    }
}