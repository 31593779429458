<app-home-header></app-home-header>
<section class="login-area d-flex align-items-center justify-content-center mx-auto pt-4">
    <div class="login-form-container bg-white rounded py-2 px-3">
        <div class="logo text-center mb-4">
            <img src="assets/img/logo.svg" alt="Yebokenny Logo" class="logo-img">
        </div>
        <h2 class="text-center mb-2">Welcome to Yebokenny</h2>
        <p class="text-center mb-4">Welcome! Please enter your details.</p>
        <form [formGroup]="registerForm" #registerNgForm="ngForm" class="login-form">

            <div class="form-group mb-3">
                <label>First name</label>
                    <input placeholder="Enter Your First Name" class="form-control input" id="firstName"
                    matInput [formControlName]="'firstName'">
                <mat-error
                    *ngIf="registerForm.get('firstName').hasError('required') && registerForm.get('firstName').touched">
                    This field is required.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('firstName').hasError('isProfane') && registerForm.get('firstName').touched">
                    Please refrane from profanity.
                </mat-error>
            </div>
            <div class="form-group mb-3">
                <label>Last name</label>
                <input placeholder="Enter Your Last Name" class="form-control input" id="lastName"
                    matInput [formControlName]="'lastName'">
                <mat-error
                    *ngIf="registerForm.get('lastName').hasError('required') && registerForm.get('lastName').touched">
                    This field is required.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('lastName').hasError('isProfane') && registerForm.get('lastName').touched">
                    Please refrane from profanity.
                </mat-error>
            </div>
            <div class="form-group mb-3">
                <label>Email</label>
                <input type="email" placeholder="Enter Your Email" class="form-control input" matInput
                    [formControlName]="'email'">
                <mat-error
                    *ngIf="registerForm.get('email').hasError('required') && registerForm.get('email').touched">
                    This field is required.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('email').hasError('email') && registerForm.get('email').touched">
                    Please enter a valid email.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('email').hasError('isProfane') && registerForm.get('email').touched">
                    Please refrane from profanity.
                </mat-error>
            </div>
            <div class="form-group mb-3">
                <label>Phone Number</label>
                <input type="tel" placeholder="Enter Your Phone Number" class="form-control input"
                    matInput [formControlName]="'phoneNumber'">
                <mat-error
                    *ngIf="registerForm.get('phoneNumber').hasError('required') && registerForm.get('phoneNumber').touched">
                    This field is required.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('phoneNumber').hasError('minlength') && registerForm.get('phoneNumber').touched">
                    Phone number must have a minimum length of 10 digits.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('phoneNumber').hasError('isProfane') && registerForm.get('phoneNumber').touched">
                    Please refrane from profanity.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('phoneNumber').hasError('numbersOnly') && registerForm.get('phoneNumber').touched">
                    Phone number can only have numbers.
                </mat-error>
            </div>

            <div class="form-group mb-3">
                <label>Password</label>
                <input type="password" placeholder="Enter Your Password" class="form-control input"
                    matInput [formControlName]="'password'">
                <mat-error
                    *ngIf="registerForm.get('password').hasError('required') && registerForm.get('password').touched">
                    This field is required.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('password').hasError('minLength') && registerForm.get('password').touched">
                    Password must be at least 8 characters long.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('password').hasError('uppercaseRequired') && registerForm.get('password').touched">
                    Password must contain at least one uppercase letter.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('password').hasError('lowercaseRequired') && registerForm.get('password').touched">
                    Password must contain at least one lowercase letter.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('password').hasError('digitRequired') && registerForm.get('password').touched">
                    Password must contain at least one digit.
                </mat-error>
                <mat-error
                    *ngIf="registerForm.get('password').hasError('isProfane') && registerForm.get('password').touched">
                    Please refrane from profanity.
                </mat-error>
            </div>
            <div class="row">
                <label class="">Are you an individual or business</label>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <label>
                        <input type="radio" name="entityType" class="card-input-element"
                            [formControlName]="'entityType'" value="individual" required />
                        <div class="card card-input align-items-center">
                            <img src="assets/img/famous.png" class="card-img-top mt-2"
                                style="max-height:40px; object-fit: contain;" alt="Individual">
                            <div class="card-title">Individual</div>
                        </div>

                    </label>
                    <mat-error
                        *ngIf="registerForm.get('entityType').hasError('required') && registerForm.get('entityType').touched">
                        This field is required.
                    </mat-error>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <label>
                        <input type="radio" name="entityType" class="card-input-element"
                            value="business" [formControlName]="'entityType'" />

                        <div class="card card-input align-items-center">
                            <img src="assets/img/team.png" class="card-img-top mt-2"
                                style="max-height:40px; object-fit: contain;" alt="Business">
                            <div class="card-title">Business</div>
                        </div>
                    </label>
                </div>
            </div>

            <button 
                type="button" 
                class="btn btn-primary w-100 mb-3" 
                style="background:#008B39; outline:none!important;height:50px;border:none"
                (click)="submit()"
            >
                Sign up
            </button>
            
        </form>

        <p class="text-center mt-2">Already have an account? <a routerLink="/login" style="text-decoration: none; color: #008B39;">Login</a></p>
    </div>
</section>
<style>
    .login-area{
        min-height: 100vh;
        background-color: #F9F9F9;
    }

    @media (min-width: 992px) {
        .login-form-container {
            width: 35%;
        }
        }

        @media (max-width: 991px) and (min-width: 768px) {
        .login-form-container {
            width: 70%;
        }
        }

        @media (max-width: 767px) {
        .login-form-container{
            width: 90%;
        }
        }
    input.input{
        border: 1px solid #D0D5DD!important;
        background-color: white!important;
        box-shadow:none!important;
        outline: none!important; 
        height: 50px;
        width: 100%;
        border-radius: 8px;
        padding-inline: 10px;
    }
</style>